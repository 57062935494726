import { PropsWithChildren, ReactNode } from 'react'
import { Divider, Popover, PopoverProps, Row } from 'antd'

interface APopoverProps extends PopoverProps {
  renderFooter?: ReactNode
  renderHeaderExtra?: ReactNode
}

export function APopover(props: PropsWithChildren<APopoverProps>) {
  const { title, content, children, renderFooter, renderHeaderExtra, ...popoverProps } = props

  const isRenderHeader = title || renderHeaderExtra

  return (
    <Popover
      {...popoverProps}
      title={
        isRenderHeader && (
          <Row justify={'space-between'}>
            {title}
            {renderHeaderExtra}
          </Row>
        )
      }
      content={
        <>
          {content}
          {renderFooter && (
            <>
              <Divider />
              {renderFooter}
            </>
          )}
        </>
      }
    >
      {children}
    </Popover>
  )
}
