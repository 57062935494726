import { useMemo } from 'react'

import { HolderSetupSchemaBodyStep1 } from './HolderSetupSchemaBodyStep-1'
import { HolderSetupSchemaBodyStep2 } from './HolderSetupSchemaBodyStep-2'

import { HolderSetupStep } from './holder-setup.shared'
import { HolderSetupSchemaBodyWrapper } from './holder-setup.styled'
import { FormStore, HolderSetupSchemaService } from './holder-setup-schema.service'

const initialValues = new FormStore()
export function HolderSetupSchemaBody() {
  const { holderSetupForm, holderSetupStep } = HolderSetupSchemaService.useInject()

  return useMemo(() => {
    return (
      <HolderSetupSchemaBodyWrapper form={holderSetupForm} layout={'vertical'} initialValues={initialValues}>
        {holderSetupStep === HolderSetupStep.ONE && <HolderSetupSchemaBodyStep1 />}
        {holderSetupStep === HolderSetupStep.TWO && <HolderSetupSchemaBodyStep2 />}
      </HolderSetupSchemaBodyWrapper>
    )
  }, [holderSetupForm, holderSetupStep])
}
