import { AModal } from '@/components'
import { useMemo } from 'react'
import {
  // services
  AiRuleApiResult,
  AiRuleSchemaService,
  AiRuleSchemaSubmitMode,

  // components
  AiRuleSchemaBody,
  AiRuleSchemaExtra,
} from '@/app/Modules/AiRule'

/**
 * @description Ai 规则模型
 */
export function AiRuleSchema({ onUpdateLocalCb }: { onUpdateLocalCb: (record: AiRuleApiResult) => void }) {
  const {
    aiRuleSchemaState,
    aiRuleSchemaLoading,
    aiRuleSchemaVisible,

    handleOk,
    handleCloseAfter,
    setAiRuleSchemaVisible,
  } = AiRuleSchemaService.useInject()

  const renderMap = useMemo(() => {
    switch (aiRuleSchemaState.submitMode) {
      case AiRuleSchemaSubmitMode.CREATE:
        return { renderTitle: '自定义规则', isRenderFooterExtra: true }

      case AiRuleSchemaSubmitMode.UPDATE:
      case AiRuleSchemaSubmitMode.UPDATE_LOCAL:
        return { renderTitle: '修改规则', isRenderFooterExtra: false }

      default:
        return { renderTitle: 'Unknown Submit Mode', isRenderFooterExtra: false }
    }
  }, [aiRuleSchemaState.submitMode])

  return useMemo(() => {
    return (
      <AModal
        centered
        destroyOnClose
        width={600}
        title={renderMap.renderTitle}
        visible={aiRuleSchemaVisible}
        confirmLoading={aiRuleSchemaLoading}
        onOk={() => handleOk(onUpdateLocalCb)}
        onCancel={() => setAiRuleSchemaVisible(false)}
        afterClose={handleCloseAfter}
        renderFooterExtra={renderMap.isRenderFooterExtra && <AiRuleSchemaExtra />}
      >
        <AiRuleSchemaBody />
      </AModal>
    )
  }, [
    aiRuleSchemaLoading,
    aiRuleSchemaVisible,
    handleCloseAfter,
    handleOk,
    onUpdateLocalCb,
    renderMap.isRenderFooterExtra,
    renderMap.renderTitle,
    setAiRuleSchemaVisible,
  ])
}
