import { PropsWithChildren, ReactNode } from 'react'
import { Button, ModalProps, Row, Space } from 'antd'

import { AModalWrapper } from './styled'

interface AModalProps extends ModalProps {
  renderHeaderExtra?: ReactNode
  renderFooterExtra?: ReactNode
}

export function AModal(props: PropsWithChildren<AModalProps>) {
  const { title, children, confirmLoading, onCancel, onOk, renderHeaderExtra, renderFooterExtra, ...modalProps } = props

  return (
    <AModalWrapper
      {...modalProps}
      closable={false}
      onCancel={onCancel}
      title={
        <Row justify={renderHeaderExtra ? 'space-between' : 'start'}>
          <span className={'am-title'}>{title}</span>
          {renderHeaderExtra}
        </Row>
      }
      footer={
        <Row align={'middle'} justify={renderFooterExtra ? 'space-between' : 'end'}>
          {renderFooterExtra}
          <Space>
            <Button onClick={onCancel}>取消</Button>
            <Button type="primary" loading={confirmLoading} onClick={onOk}>
              确定
            </Button>
          </Space>
        </Row>
      }
    >
      {children}
    </AModalWrapper>
  )
}
