import { useMemo } from 'react'
import { mergeClassNames } from '@/utils'
import { OPERATE_OPTIONS } from '@/constants'
import { Row, Col, message } from 'antd'

import {
  // types
  type ListItemProps,
  type AiRuleItemProps,

  // services
  toYuan,
  aiRuleConfigs,
  AiRuleListService,
  AiRuleIndexService,

  // components
  ListItem,
  AiRuleListItemExtra,
  AiRuleListItemWrapper,
} from '@/app/Modules/AiRule'

/**
 * @description Ai 规则列表项
 */
export function AiRuleListItem({ item, value, onChange }: AiRuleItemProps) {
  const { aiRuleIndex } = AiRuleIndexService.useInject()
  const { aiRulePayload } = AiRuleListService.useInject()

  // 创建元配置
  const createMeta = useMemo((): Omit<ListItemProps, 'item'> => {
    switch (aiRulePayload.type) {
      // 关闭类型
      case 2: {
        const operate = OPERATE_OPTIONS.find((eachItem) => eachItem.value === Number(item.execution))

        return {
          ruleIndexEnums: aiRuleIndex.switchEnum,
          renderContentFooter: (
            <Row>
              <Col span={8}>执行：</Col>
              <Col span={16}>{operate?.label ?? '-'}</Col>
            </Row>
          ),
        }
      }

      // 预算类型
      case 3: {
        let renderBudget
        const { budgetMin, budgetMax } = item

        // 介于
        if (budgetMin != null && budgetMax != null) {
          renderBudget = `${budgetMin / 100} - ${budgetMax / 100} 元`
        }
        // 最小值 - 大于
        else if (budgetMin != null) {
          renderBudget = `大于 ${budgetMin / 100} 元`
        }
        // 最大值 - 小于
        else if (budgetMax != null) {
          renderBudget = `小于 ${budgetMax / 100} 元`
        }
        // 都是 null
        else {
          renderBudget = '-'
        }

        return {
          ruleIndexEnums: aiRuleIndex.budgetEnum,
          renderContentHeader: (
            <Row>
              <Col span={8}>日预算：</Col>
              <Col span={16}>{renderBudget}</Col>
            </Row>
          ),
          renderContentFooter: (
            <Row>
              <Col span={8}>执行：</Col>
              <Col span={16}>
                预算提升 {item.executionType === 1 ? toYuan(Number(item.execution)) : item.execution}
                {item.executionType === 1 ? '元' : '%'}
              </Col>
            </Row>
          ),
        }
      }
      default:
        return {
          ruleIndexEnums: [],
        }
    }
  }, [aiRuleIndex.budgetEnum, aiRuleIndex.switchEnum, aiRulePayload.type, item])

  return useMemo(() => {
    // 当前规则模板是否已选中 - 由于这些规则分属于不同的数据库表，它们的 id 是有可能相同的，因此需要追加判断它们的 type
    const isSelected = value.find((eachItem) => eachItem.id === item.id && eachItem.type === item.type)

    const handleCheck = () => {
      // 没选中之前判断是否超出选择限制
      const [, maxLimit] = aiRuleConfigs.ruleSelectedLimitRange
      if (!isSelected && value.length >= maxLimit) return message.info('规则已达到最大选择限制 ~')

      // 若已选中，则过滤（取消选中），否则压栈，追加选中
      const selectedList = isSelected ? value.filter((eachItem) => eachItem.id !== item.id) : [item, ...value]

      onChange?.(selectedList)
    }

    // 卡片选中时的样式
    const selectedClassNames = mergeClassNames({ selected: Boolean(isSelected) })

    return (
      <AiRuleListItemWrapper className={selectedClassNames} onClick={handleCheck}>
        <ListItem
          item={item}
          ruleIndexEnums={createMeta.ruleIndexEnums}
          renderItemExtra={item.templateType === 2 && <AiRuleListItemExtra item={item} />} // 当模板类型为 DIY 时才渲染操作组
          renderContentHeader={createMeta.renderContentHeader}
          renderContentFooter={createMeta.renderContentFooter}
        />
      </AiRuleListItemWrapper>
    )
  }, [createMeta.renderContentFooter, createMeta.renderContentHeader, createMeta.ruleIndexEnums, item, onChange, value])
}
