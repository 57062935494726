import { isDayjs } from 'dayjs'
import { CSSProperties } from 'styled-components'
import { REG_EXP_EN_CHAR } from '@/constants'

import { DATE_FORMATS } from '.'

interface Options {
  /**
   * @description 计算模式 = char - 英文字符优先 | character 中文字符优先
   */
  mode?: 'char' | 'character'

  /**
   * @description 正则表达式匹配
   */
  pattern?: RegExp
}

/**
 * @description 重新计算字符串的长度
 * @param {*} str 字符串
 * @param {*} customRegExp 可自定义正则
 * @returns
 */
export const recalculateStrLength = (str = '', options?: Options) => {
  // 默认应用 [中文字符优先] 模式 + 内置 [英文字符] 正则
  const { mode = 'character', pattern = REG_EXP_EN_CHAR } = options ?? {}

  // 原长度
  const originLength = str.length

  // 英文字符（字母/数字/标点/空格等）个数
  const chars = str.match(pattern) ?? []

  // 汉字个数
  const characterLength = originLength - chars.length

  switch (mode) {
    // 1 个字符算 1 个长度，1 个汉字算 2 个长度
    case 'char':
      return chars.length + characterLength * 2

    // 2 个字符算 1 个长度，1 个汉字算 1 个长度
    case 'character':
      return Math.ceil(chars.length / 2) + characterLength

    // 默认返回原长度
    default:
      return originLength
  }
}

/**
 * @description 安全解析 JSON
 * @param {*} str JSON 字符串
 * @returns
 */
export const safeParseJSON = (str?: string, defaultValue: RecordType<SafeAny> = {}) => {
  let parsedResult = defaultValue

  if (!str?.trim().length) return parsedResult

  try {
    parsedResult = JSON.parse(str)
  } catch (error: SafeAny) {
    console.error(`${error.message}: ${str}`)
  }

  return parsedResult
}

/**
 * @description object params to query string
 * @param params params
 * @returns
 */
export const toQuery = (params: RecordType<SafeAny>) => {
  return Object.keys(params)
    .filter((key) => !['', null, undefined].includes(params[key])) // 过滤：''/null/undefined
    .map((key) => {
      let value = params[key]

      // 格式化日期 - 不需要拼接时间（后端已处理）
      if (isDayjs(value)) value = value.format(DATE_FORMATS.DATE)

      return `${key}=${encodeURIComponent(value)}`
    })
    .join('&')
}

/**
 * @description query string to object params
 * @param url url
 * @returns
 */
export const unQuery = (url = window.location.href) => {
  const [, queryString] = url.split('?')
  const result: RecordType<string> = {}

  if (!queryString) return result

  const mappingArray = queryString.split('&')
  mappingArray.forEach((item) => {
    const [field, value] = item.split('=')
    result[field] = value
  })

  return result
}

/**
 * @description 生成一个随机 ID
 * @returns
 */
export const generateRandomId = () => {
  const array = new Uint32Array(2)
  window.crypto.getRandomValues(array)
  return String(array[0]) + String(array[1])
}

/**
 * @description 合并 className - 若没有 className 则绑定 undefined，最终 dom 属性上会没有出现 class 绑定
 * @param classNames className 对象
 */
export function mergeClassNames(
  ...classNames: (string | number | undefined | RecordType<boolean | number | string | undefined>)[]
) {
  const result: (string | number)[] = []

  for (let i = 0; i < classNames.length; i++) {
    const item = classNames[i]

    if (!item) continue

    if (typeof item === 'string' || typeof item === 'number') {
      result.push(item)
    } else {
      const keys = Object.keys(item)
      const truthyKeys = keys.filter((key) => item[key])
      result.push(...truthyKeys)
    }
  }

  if (result.length) {
    return result.join(' ')
  }
}

/**
 * @description 拆分 16 进制颜色为 RGB
 * @param hex 16 进制颜色
 * @returns
 */
export function splitHex(hex: string) {
  const [R, G, B] = [hex.slice(1, 3), hex.slice(3, 5), hex.slice(5, 7)].map((item) => `0x${item}`)

  return { R, G, B }
}

/**
 * @description 16 进制颜色转 RGB
 * @param hex 16 进制颜色
 * @returns
 */
export function hexToRgb(hex: string) {
  const { R, G, B } = splitHex(hex)

  return `rgb(${parseInt(R)}, ${parseInt(G)}, ${parseInt(B)})`
}

/**
 * @description 16 进制颜色转 RGBA
 * @param hex 16 进制颜色
 * @returns
 */
export function hexToRgba(hex: string, opacity: Required<CSSProperties>['opacity']) {
  const { R, G, B } = splitHex(hex)

  return `rgba(${parseInt(R)}, ${parseInt(G)}, ${parseInt(B)}, ${opacity})`
}
