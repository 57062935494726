import { useMemo } from 'react'
import { Strategy, StrategyApiResult } from '@/app/Modules'

import { HolderSetupSchemaService } from '../holder-setup-schema.service'

interface SelectStrategyProps {
  value?: StrategyApiResult[]
  onChange?: (value: StrategyApiResult[]) => void
}

export function SelectStrategy({ value, onChange }: SelectStrategyProps) {
  const { holderSetupForm } = HolderSetupSchemaService.useInject()

  // @todo: 优化状态存储
  const selectedAppId = holderSetupForm.getFieldValue('appId')
  localStorage.setItem('SELECTED_APP_ID', selectedAppId)

  return useMemo(() => {
    return <Strategy value={value} onChange={onChange} />
  }, [onChange, value])
}
