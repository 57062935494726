import { CloseOutlined } from '@ant-design/icons'
import { PropsWithChildren, ReactNode } from 'react'
import { Button, Col, Divider, DrawerProps, Row, Space } from 'antd'

import { ADrawerBodyWrapper, ADrawerWrapper } from './styled'

interface ADrawerProps extends Omit<DrawerProps, 'title'> {
  onOk?: () => void // 确认按钮回调

  showFooter?: boolean // 是否展示底部
  confirmLoading?: boolean // 确认按钮 loading

  okButtonText?: ReactNode
  cancelButtonText?: ReactNode

  renderTitle?: ReactNode // 顶部标题
  renderHeaderExtra?: ReactNode // 顶部额外区域
  renderFooterExtra?: ReactNode // 底部额外区域 - showFooter 为 true 时有效
}

export function ADrawer(props: PropsWithChildren<ADrawerProps>) {
  const {
    onOk,
    onClose,

    footer,
    showFooter = true,
    confirmLoading,

    okButtonText,
    cancelButtonText,

    renderTitle,
    renderFooterExtra,
    renderHeaderExtra,

    ...baseProps
  } = props

  const renderHeader = () => {
    return (
      <Row align={'middle'} justify={'space-between'} className={'ai-header'}>
        <Col>
          <Row align={'middle'} justify={'space-between'}>
            <CloseOutlined className={'ai-close-icon'} onClick={(e: SafeAny) => onClose?.(e)} />
            <Divider type="vertical" className={'ai-divider'} />
            <span className={'ai-title'}>{renderTitle}</span>
          </Row>
        </Col>
        <Col flex={1}>{renderHeaderExtra}</Col>
      </Row>
    )
  }

  const renderFooter = () => {
    if (showFooter) {
      if (footer) {
        return footer
      } else {
        return (
          <Row align={'middle'} justify={renderFooterExtra ? 'space-between' : 'end'}>
            {renderFooterExtra}
            <Space>
              <Button onClick={(e: SafeAny) => onClose?.(e)}>{cancelButtonText ?? '取消'}</Button>
              <Button type={'primary'} loading={confirmLoading} onClick={onOk}>
                {okButtonText ?? '确定'}
              </Button>
            </Space>
          </Row>
        )
      }
    } else {
      return null
    }
  }

  return (
    <ADrawerWrapper
      {...baseProps}
      title={renderHeader()}
      footer={<div className={'ai-footer-wrap'}>{renderFooter()}</div>}
      closable={false}
      onClose={onClose}
    >
      <ADrawerBodyWrapper>{props.children}</ADrawerBodyWrapper>
    </ADrawerWrapper>
  )
}
