import styled from 'styled-components'
import { Drawer } from 'antd'

export const ADrawerWrapper = styled(Drawer)`
  .ant-drawer-header {
    .ai-header {
      height: 32px;

      .ai-close-icon {
        cursor: pointer;
        opacity: 0.75;
        font-size: var(--ai-font-size-large);

        &:hover {
          opacity: 1;
        }
      }

      .ai-title {
        color: var(--ai-color-title);
        font-weight: var(--ai-font-weight-bold);
      }

      .ai-divider {
        top: 0;
        height: 1.5em;
      }
    }
  }

  .ant-drawer-body {
    padding-bottom: 0;
    background-color: var(--ai-bgc-compare);
  }

  .ant-drawer-footer {
    padding: 0 12px;
    border-top: none;
    background-color: var(--ai-bgc-compare);

    .ai-footer-wrap {
      padding: 12px 24px;
      position: relative;
      background-color: var(--ai-base-color);

      &::before {
        top: 0;
        width: 1128px;
        height: 1px;
        content: '';
        position: absolute;
        background-color: var(--ai-color-border);
      }
    }

    /* @todo: 统一设置 large button 的样式 | 次要按钮 */
    button {
      width: 90px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`

export const ADrawerBodyWrapper = styled.div`
  height: 100%;
  padding: 24px 24px 0 24px;
  overflow-y: auto;
  overflow-y: overlay;
  background-color: var(--ai-base-color);
  border-top-left-radius: var(--ai-base-border-radius);
  border-top-right-radius: var(--ai-base-border-radius);
`
