import { Form } from 'antd'
import { AModal } from '@/components'
import { useMemo } from 'react'

import {
  // services
  AiRuleListService,
  AiRuleCopyService,
  AiRuleCopyFormStore,

  // components
  FormRuleName,
} from '@/app/Modules/AiRule'

/**
 * @description Ai 规则 - 复制
 */
export function AiRuleCopy() {
  const { aiRuleRequest } = AiRuleListService.useInject()
  const { aiRuleCopyForm, aiRuleCopyVisible, aiRuleCopyLoading, setAiRuleCopyVisible, handleCopy } =
    AiRuleCopyService.useInject()

  return useMemo(() => {
    return (
      <AModal
        centered
        title={'复制规则模板'}
        visible={aiRuleCopyVisible}
        afterClose={() => aiRuleCopyForm.resetFields()}
        confirmLoading={aiRuleCopyLoading}
        onOk={() => handleCopy(aiRuleRequest)}
        onCancel={() => setAiRuleCopyVisible(false)}
      >
        <Form form={aiRuleCopyForm} layout={'vertical'} initialValues={new AiRuleCopyFormStore()}>
          <FormRuleName />
        </Form>
      </AModal>
    )
  }, [aiRuleCopyForm, aiRuleCopyLoading, aiRuleCopyVisible, aiRuleRequest, handleCopy, setAiRuleCopyVisible])
}
