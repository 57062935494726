import { cloneDeep } from 'lodash-es'
import { safeParseJSON } from '@/utils'
import {
  MultiplesTypeValues,
  OperatorOptionsValues,
  BUDGET_CONDITION_OPTIONS,
  BudgetConditionOptionsValues,
} from '@/constants'

import { AiRuleApiResult } from './ai-rule-list.service'
import { BudgetRangeValueProp } from './components'
import { createErrorInstance, createNowDateTime } from '../shared'
import {
  toCent,
  toYuan,
  aiRuleConfigs,
  AiRuleApiCommonSchema,
  CreateConditionFields,
  OperatorOptionsValuesWithoutRange,
} from './ai-rule.shared'

export type BudgetConditionFields = CreateConditionFields<BudgetConditionOptionsValues> // 含 Min or Max 的条件字段
export type BudgetConditionObject = Partial<Record<BudgetConditionFields, number>> // 条件字段对象

const badv = aiRuleConfigs.ruleBudgetAdjustmentDefaultValue

/**
 * @api /io/ai-rule/template/add.do
 * @description Ai 规则 Api 参数模型 - 提升预算规则基本字段 | 还需要在静态方法 toSchema 中组合散落的条件字段
 */
export class AiRuleApiBudgetSchema extends AiRuleApiCommonSchema {
  budgetMax?: number // 日预算最大值
  budgetMin?: number // 日预算最小值
  multiples!: number // 预算增加数 - 当 multiplesType = 1 时 10000 即 100 元 (单位: 分) | 当 multiplesType = 2 时 100 即 100%
  multiplesType!: MultiplesTypeValues // 预算调整值类型
}

/**
 * @description Ai 规则条件实体类 - 提升预算规则
 */
export class AiRuleBudgetCondition {
  value: number // 字段值
  field: BudgetConditionOptionsValues // 条件字段
  operator: OperatorOptionsValuesWithoutRange // 操作符

  constructor() {
    this.value = 0
    this.field = 'activeNumMedium'
    this.operator = 'Max'
  }
}

/**
 * @description Ai 规则表单域模型 - 提升预算规则表单域
 */
export class AiRuleBudgetFormStore extends AiRuleApiBudgetSchema {
  conditionList: AiRuleBudgetCondition[] // 条件数组
  multipleNaturalValue: number // 自然数值
  multiplePercentValue: number // 百分比值
  budgetRangeValueProp: BudgetRangeValueProp // 日预算范围组件 value prop

  constructor() {
    super()

    const { date, time } = createNowDateTime()

    this.ruleName = `预算提升_${date}_${time}`
    this.conditionList = [new AiRuleBudgetCondition()]
    this.multiplesType = 1
    this.multipleNaturalValue = badv.naturalValue
    this.multiplePercentValue = badv.percentValue
    this.budgetRangeValueProp = { min: 0, max: 0, operator: 'Range' }
  }

  /**
   * @description 表单域对象转换到接口所需要的数据结构
   * @param values 表单域对象
   */
  static toSchema(values: AiRuleBudgetFormStore): AiRuleApiBudgetSchema & BudgetConditionObject {
    const cloned = cloneDeep(values)

    // 数据转换
    const normalizedCondition = this.normalCondition(cloned.conditionList)
    const normalizedMultiples = this.normalMultiples(cloned.multiplesType, {
      naturalValue: cloned.multipleNaturalValue,
      percentValue: cloned.multiplePercentValue,
    })
    const normalizedBudgetRange = this.normalBudgetRange(cloned.budgetRangeValueProp)

    return {
      id: cloned.id,
      ruleName: cloned.ruleName,
      budgetMin: normalizedBudgetRange.min,
      budgetMax: normalizedBudgetRange.max,
      multiples: normalizedMultiples,
      multiplesType: cloned.multiplesType,

      ...normalizedCondition,
    }
  }

  /**
   * @description 响应实体转换到表单域对象
   * @param instance 响应实体
   */
  static toValues(instance: AiRuleApiResult): Partial<AiRuleBudgetFormStore> {
    const { id, ruleName, budgetMin, budgetMax, execution, executionType, customRuleJson } = instance

    const normalizedOperator = this.normalOperator({ budgetMin, budgetMax })
    const normalizedConditionList = this.normalConditionList(customRuleJson)

    return {
      id,
      ruleName,
      conditionList: normalizedConditionList,
      multiplesType: executionType,
      multipleNaturalValue: executionType === 1 ? toYuan(Number(execution)) : 0,
      multiplePercentValue: executionType === 2 ? Number(execution) : 0,
      budgetRangeValueProp: { min: toYuan(budgetMin), max: toYuan(budgetMax), operator: normalizedOperator },
    }
  }

  /**
   * @description 根据日预算范围确定操作符
   * @param value 日预算范围最小最大值
   * @returns 操作符
   */
  static normalOperator(value: { budgetMin?: number; budgetMax?: number }): OperatorOptionsValues {
    const { budgetMin, budgetMax } = value

    // 两者皆存在
    if (budgetMin != null && budgetMax != null) return 'Range'

    // 只存在最小值
    if (budgetMin) return 'Min'

    // 只存在最大值
    if (budgetMax) return 'Max'

    // 两者皆不存在 - 默认值
    return 'Range'
  }

  /**
   * @description 将条件数组转换成对象，其中 [键名 = 条件名 + 操作符] [键值 = 值]
   * @param value 条件数组
   */
  static normalCondition(value: AiRuleBudgetCondition[] = []) {
    const condition: BudgetConditionObject = {}

    value.forEach((item) => {
      const conditionOption = BUDGET_CONDITION_OPTIONS.find((eachItem) => eachItem.value === item.field)

      condition[`${item.field}${item.operator}`] = conditionOption?.unit === '元' ? toCent(item.value) : item.value
    })

    return condition
  }

  /**
   * @todo 待优化
   * @description 将条件 JSON 对象转换成数组 - 逆向转换
   * @param value 条件对象
   */
  static normalConditionList(value = '') {
    const parsedCondition = safeParseJSON(value) as BudgetConditionObject
    const normalized = Object.keys(parsedCondition)
      .map((key) => {
        // 排除日预算
        if (key.includes('budget')) return

        if (key.endsWith('Max')) {
          const [field] = key.split('Max')

          const conditionOption = BUDGET_CONDITION_OPTIONS.find((eachItem) => eachItem.value === field)
          const value = parsedCondition[key as BudgetConditionFields]

          return {
            field,
            value: conditionOption?.unit === '元' ? toYuan(value) : value,
            operator: 'Max',
          }
        }

        if (key.endsWith('Min')) {
          const [field] = key.split('Min')

          const conditionOption = BUDGET_CONDITION_OPTIONS.find((eachItem) => eachItem.value === field)
          const value = parsedCondition[key as BudgetConditionFields]

          return {
            field,
            value: conditionOption?.unit === '元' ? toYuan(value) : value,
            operator: 'Min',
          }
        }
      })
      .filter((item) => Boolean(item)) // 过滤 undefined

    return normalized as AiRuleBudgetCondition[]
  }

  /**
   * @description 根据 multiplesType 返回对应的 multiples 值
   * @param option multiplesType
   * @param value 自然数值 or 百分比值
   * @returns
   */
  static normalMultiples(option: MultiplesTypeValues, value: { naturalValue: number; percentValue: number }): number {
    const { naturalValue, percentValue } = value

    switch (option) {
      // 自然数值
      case 1:
        return toCent(naturalValue)

      // 百分比值
      case 2:
        return percentValue

      default:
        throw createErrorInstance('multiplesType', option)
    }
  }

  /**
   * @description 处理日预算范围，保证传值正确（后端不做大于小于区分，因此前端需要根据用户选择的 operator 判断要么两个都传，要么只传其中一个）
   * @param value 日预算范围组件的 value prop
   */
  static normalBudgetRange(value: BudgetRangeValueProp): Partial<BudgetRangeValueProp> {
    const { min, max, operator } = value

    switch (operator) {
      // 大于操作符 - 只取最小值
      case 'Min':
        return { min: toCent(min) }

      // 小于操作符 - 只取最大值
      case 'Max':
        return { max: toCent(max) }

      // 介于操作符 - 两者皆取
      case 'Range':
        return { min: toCent(min), max: toCent(max) }

      default:
        throw createErrorInstance('operator', operator)
    }
  }
}
