import { ROUTE_PATHS } from '@/routes'
import { Link, useLocation } from 'react-router-dom'

import { LayoutSiderMenuWrapper } from './layout.styled'

export function LayoutSiderMenu() {
  const { pathname } = useLocation()

  return (
    <LayoutSiderMenuWrapper
      mode="inline"
      selectedKeys={[pathname]}
      items={[
        { key: ROUTE_PATHS.HOLDER_ADV, label: <Link to={ROUTE_PATHS.HOLDER_ADV}>托管广告</Link> },
        { key: ROUTE_PATHS.HOLDER_STRATEGY, label: <Link to={ROUTE_PATHS.HOLDER_STRATEGY}>托管策略</Link> },
      ]}
    />
  )
}
