import styled from 'styled-components'
import { Row } from 'antd'

import { baseHeight } from '../../ai-rule.styled'

export const ToolbarWrapper = styled(Row)`
  height: ${baseHeight};
  padding: 0 24px;
  border-radius: var(--ai-base-border-radius);
  background-color: var(--ai-bgc-fill);
`
