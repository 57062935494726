import { Col } from 'antd'
import { ReactNode } from 'react'

import { ToolbarWrapper } from './styled'

interface ToolbarProps {
  title?: ReactNode
  extra?: ReactNode
  className?: string
}

/**
 * @description 通用工具栏
 */
export function Toolbar({ title, extra, className }: ToolbarProps) {
  return (
    <ToolbarWrapper align={'middle'} justify={'space-between'} className={className}>
      <Col>{title}</Col>
      <Col>{extra}</Col>
    </ToolbarWrapper>
  )
}
