import { Button } from 'antd'
import { useMemo } from 'react'

import { HolderSetupSchemaService } from './holder-setup-schema.service'

export function HolderSetupSchemaExtraStep1() {
  const { handleNextStep } = HolderSetupSchemaService.useInject()

  return useMemo(() => {
    return (
      <Button type={'primary'} onClick={handleNextStep}>
        下一步
      </Button>
    )
  }, [handleNextStep])
}
