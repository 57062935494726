import { Modal, Select } from 'antd'
import { HolderService } from '@/app/Holder'
import { type ReactNode, useMemo } from 'react'

interface SelectMediumAccountProps {
  value?: number[]
  placeholder?: ReactNode
  onChange?: (value: number[] | undefined, options: OptionType[]) => void
}

/**
 * @description 投放账户选择器（ma = mediumAccount）
 */
export function SelectMediumAccount({ value, onChange, placeholder }: SelectMediumAccountProps) {
  const { holderMediumAccountLoading, holderMediumAccountOptions } = HolderService.useInject()

  return useMemo(() => {
    return (
      <Select
        showArrow
        allowClear
        mode={'multiple'}
        value={value}
        style={{ width: '450px' }}
        loading={holderMediumAccountLoading}
        options={holderMediumAccountOptions}
        maxTagCount={1}
        placeholder={placeholder}
        maxTagTextLength={20}
        optionFilterProp={'label'}
        getPopupContainer={(node) => node.parentElement ?? document.body}
        onChange={(newValue, options) => {
          const oldValueLength = value?.length ?? 0
          const newValueLength = newValue.length

          if (oldValueLength > newValueLength) {
            Modal.confirm({
              title: '确定要删除投放账户吗？这将会重置已选择的广告（组）数据。',
              centered: true,
              onOk: () => onChange?.(newValue, options as OptionType[]),
            })
          } else {
            onChange?.(newValue, options as OptionType[])
          }
        }}
      />
    )
  }, [holderMediumAccountLoading, holderMediumAccountOptions, onChange, placeholder, value])
}
