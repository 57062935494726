import { Space } from 'antd'

import {
  // types
  type AiRuleIOProps,
  type AiRuleApiResult,
  type AiRuleListSelectedToolbarProps,

  // services
  AiRuleCopyService,
  AiRuleListService,
  AiRuleIndexService,
  AiRuleSchemaService,

  // eventScopes
  AiRuleListEventScope,
  AiRuleListSelectedEventScope,

  // components
  AiRuleCopy,
  AiRuleSchema,
  AiRuleList,
  AiRuleListToolbar,
  AiRuleListSelected,
  AiRuleListSelectedToolbar,
} from '@/app/Modules/AiRule'

const defaultValue: AiRuleApiResult[] = []

/**
 * @description Ai 规则模块内置模板组件（Template Component）- 聚合 Ai 规则所有相关的组件
 */
export function AiRule({
  value = defaultValue,
  onChange,

  dayTimes,
  onDayTimesChange,
}: AiRuleIOProps & AiRuleListSelectedToolbarProps) {
  return (
    <AiRuleIndexService.Provider>
      <AiRuleListEventScope.EventScope>
        <AiRuleListSelectedEventScope.EventScope>
          <AiRuleListService.Provider>
            <AiRuleSchemaService.Provider>
              <Space size={32} className={'w-100'} direction={'vertical'}>
                <Space size={12} className={'w-100'} direction={'vertical'}>
                  <AiRuleListToolbar />

                  <AiRuleCopyService.Provider>
                    <AiRuleCopy />
                    <AiRuleList value={value} onChange={onChange} />
                  </AiRuleCopyService.Provider>
                </Space>

                <Space size={12} className={'w-100'} direction={'vertical'}>
                  <AiRuleListSelectedToolbar value={value} dayTimes={dayTimes} onDayTimesChange={onDayTimesChange} />

                  <AiRuleListSelected value={value} onChange={onChange} />
                </Space>
              </Space>

              <AiRuleSchema
                onUpdateLocalCb={(record) => {
                  const index = value.findIndex((item) => item.id === record.id)

                  if (index === -1) return

                  value[index] = record

                  onChange?.([...value])
                }}
              />
            </AiRuleSchemaService.Provider>
          </AiRuleListService.Provider>
        </AiRuleListSelectedEventScope.EventScope>
      </AiRuleListEventScope.EventScope>
    </AiRuleIndexService.Provider>
  )
}
