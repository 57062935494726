import { useBind } from '@/hooks'
import { cloneDeep } from 'lodash-es'
import { mergeClassNames } from '@/utils'
import { AFreeList, AIcon } from '@/components'
import { useEffect, useMemo, useState } from 'react'
import { Checkbox, Input, Pagination, Row, Typography } from 'antd'

import { MaWithAd } from '../holder-setup.shared'
import { useAdListService } from '../services'
import { HolderSetupSchemaService } from '../holder-setup-schema.service'
import { AFreeListWrapperAd, AFreeListWrapperMa, AFreeListWrapperRs } from './components.styled'

interface SelectAdProps {
  value?: MaWithAd[]
  onChange?: (value: MaWithAd[]) => void
}

const defaultValue: MaWithAd[] = []
export function SelectAd({ value = defaultValue, onChange }: SelectAdProps) {
  const { holderSetupForm } = HolderSetupSchemaService.useInject()
  const { adTotal, adLoading, adPayload, adRecords, handleAdPayloadChange } = useAdListService()
  const {
    adLoading: adGroupLoading,
    adPayload: adGroupPayload,
    adRecords: adGroupRecords,
    handleAdPayloadChange: handleAdGroupPayloadChange,
  } = useAdListService()

  // 已选择的应用 ID | 当前激活的投放账户 ID | 已展开的投放账户 | [投放账户 ID -> 广告组实体数组] 映射
  const appIdRef = useBind(holderSetupForm.getFieldValue('appId'))
  const [active, setActive] = useState<number>()
  const [expanded, setExpanded] = useState<number[]>([])
  const [activeAdGroup, setActiveAdGroup] = useState<number>()

  // 请求广告数据
  useEffect(() => {
    if (!active || !activeAdGroup) return

    handleAdPayloadChange({
      type: 3,
      appId: appIdRef.current,
      adGroupId: activeAdGroup.toString(),
      mediumAccountIds: active.toString(),
    })
  }, [active, activeAdGroup, appIdRef, handleAdPayloadChange])

  useEffect(() => {
    const [head] = adGroupRecords
    setActiveAdGroup(head?.value)
  }, [adGroupRecords])

  // 切换投放账户需重新请求广告组数据
  useEffect(() => {
    if (!active) return

    // 请求广告组数据
    handleAdGroupPayloadChange({ type: 2, appId: appIdRef.current, mediumAccountIds: active.toString() })
  }, [active, handleAdPayloadChange, appIdRef, handleAdGroupPayloadChange])

  // 初始化及数据变化后执行
  useEffect(() => {
    setActive((prev) => {
      // 若已激活的投放账户依旧存在，则不需要改变，否则默认激活第一个投放账户（注意：有可能是空数组）
      const isActive = value.find((item) => item.mediumAccountId === prev)

      return isActive ? prev : value[0]?.mediumAccountId
    })

    // 默认全展开
    setExpanded(value.map((item) => item.mediumAccountId))
  }, [value])

  return useMemo(() => {
    const activeMa = value.find((item) => item.mediumAccountId === active)
    const activeMaSelectedIdList = activeMa?.selectedList.map((item) => item.value) ?? []
    const activeMaSelectedIdListLength = activeMaSelectedIdList.length

    // 是否半选
    const isHalfSelection = adRecords.some((item) => activeMaSelectedIdList.includes(item.value))

    // 是否全选
    const isFullSelection =
      Boolean(adRecords.length) &&
      Boolean(activeMaSelectedIdListLength) &&
      adRecords.every((item) => activeMaSelectedIdList.includes(item.value))

    return (
      <Row>
        <Row className={'w-100'}>
          {/* 投放账户 */}
          <AFreeListWrapperMa>
            <AFreeList
              title={'投放账户'}
              rowKey={'mediumAccountId'}
              dataSource={value}
              emptyDescription={'请先选择投放账户'}
              renderItem={(item) => {
                const itemClassNames = mergeClassNames('item', { active: item.mediumAccountId === active })
                const iconDotClassNames = mergeClassNames('item-icon-dot', { hidden: item.selectedList.length })

                return (
                  <div
                    title={item.mediumAccountName}
                    className={itemClassNames}
                    onClick={() => setActive(item.mediumAccountId)}
                  >
                    <AIcon type={'icon-dot'} className={iconDotClassNames} />
                    <span>{item.mediumAccountName}</span>
                  </div>
                )
              }}
            />
          </AFreeListWrapperMa>

          {/* 广告组 */}
          <AFreeListWrapperAd hiddenRadius>
            <AFreeList
              title={'广告组'}
              rowKey={'value'}
              loading={adGroupLoading}
              dataSource={adGroupRecords}
              extra={
                <Input
                  allowClear
                  style={{ width: '180px' }}
                  value={adGroupPayload.searchName}
                  suffix={<AIcon type={'icon-search'} className={'icon-search'} />}
                  placeholder={'广告组名称'}
                  onChange={(e) => handleAdGroupPayloadChange({ searchName: e.target.value })}
                />
              }
              renderItem={(item) => {
                const itemClassNames = mergeClassNames('item', { ['item-selected']: activeAdGroup === item.value })

                return (
                  <div className={itemClassNames} onClick={() => setActiveAdGroup(item.value)}>
                    <span title={item.label}>{item.label}</span>
                  </div>
                )
              }}
            />
          </AFreeListWrapperAd>

          <AFreeListWrapperAd>
            <AFreeList
              rowKey={'value'}
              loading={adLoading}
              dataSource={adRecords}
              title={
                <Checkbox
                  checked={isFullSelection}
                  indeterminate={isHalfSelection && !isFullSelection}
                  onChange={(e) => {
                    // 全选当前页，反选清空
                    const checked = e.target.checked
                    const checkedResult = checked ? cloneDeep(adRecords) : []

                    activeMa!.selectedList = checkedResult
                    onChange?.([...value])
                  }}
                >
                  广告
                </Checkbox>
              }
              extra={
                <Input
                  allowClear
                  style={{ width: '180px' }}
                  value={adPayload.searchName}
                  suffix={<AIcon type={'icon-search'} className={'icon-search'} />}
                  placeholder={'广告名称'}
                  onChange={(e) => handleAdPayloadChange({ searchName: e.target.value })}
                />
              }
              renderItem={(item) => {
                const itemClassNames = mergeClassNames('item', {
                  ['item-selected']: activeMaSelectedIdList.includes(item.value),
                })

                return (
                  <div className={itemClassNames}>
                    <Checkbox
                      checked={activeMaSelectedIdList.includes(item.value)}
                      onChange={(e) => {
                        const checked = e.target.checked
                        const target = activeMa!.selectedList
                        const checkedResult = checked
                          ? [...target, item]
                          : target.filter((eachItem) => eachItem.value !== item.value)

                        activeMa!.selectedList = checkedResult
                        onChange?.([...value])
                      }}
                    >
                      <span title={item.label}>{item.label}</span>
                    </Checkbox>
                  </div>
                )
              }}
            />
          </AFreeListWrapperAd>

          {/* 选择结果 */}
          <AFreeListWrapperRs>
            <AFreeList
              bordered
              title={'已选择'}
              rowKey={'mediumAccountId'}
              dataSource={value.filter((item) => item.selectedList.length)}
              extra={
                <Typography.Link
                  className={'extra-clear'}
                  onClick={() => {
                    value.forEach((item) => (item.selectedList = []))
                    onChange?.([...value])
                  }}
                >
                  清空
                </Typography.Link>
              }
              renderItem={(ma) => {
                const isExpanded = expanded.includes(ma.mediumAccountId)

                return (
                  <>
                    <div className={'item-header'}>
                      <AIcon
                        type={'icon-remove'}
                        className={'item-icon-remove'}
                        onClick={() => {
                          // 重置当前投放账户的广告组容器
                          ma.selectedList = []
                          onChange?.([...value])
                        }}
                      />
                      <span title={ma.mediumAccountName} className={'item-title'}>
                        {ma.mediumAccountName}
                      </span>
                      <AIcon
                        type={`icon-${isExpanded ? 'down' : 'up'}`}
                        className={'item-icon-trigger'}
                        onClick={() => {
                          // 展开则移除，否则追加
                          const expandedResult = isExpanded
                            ? expanded.filter((eachItem) => eachItem !== ma.mediumAccountId)
                            : [...expanded, ma.mediumAccountId]

                          setExpanded(expandedResult)
                        }}
                      />
                    </div>

                    <div style={{ display: isExpanded ? 'block' : 'none' }}>
                      {ma.selectedList.map((item) => {
                        return (
                          <div key={item.value} className={'ad-group-item'}>
                            <AIcon
                              type={'icon-remove'}
                              className={'item-icon-remove'}
                              onClick={() => {
                                const filtered = ma.selectedList.filter((eachItem) => eachItem.value !== item.value)
                                ma.selectedList = filtered

                                onChange?.([...value])
                              }}
                            />
                            <span title={item.label} className={'item-label'}>
                              {item.label}
                            </span>
                          </div>
                        )
                      })}
                    </div>
                  </>
                )
              }}
            />
          </AFreeListWrapperRs>
        </Row>

        {/* 分页 */}
        <Row style={{ marginTop: '20px' }} justify={'end'} className={'w-100'}>
          <Pagination
            showQuickJumper
            showSizeChanger
            total={adTotal}
            current={adPayload.pageId}
            pageSize={adPayload.pageSize}
            onChange={(pageId, pageSize) => handleAdPayloadChange({ pageId, pageSize })}
            showTotal={(total) => `共 ${total} 条数据`}
            pageSizeOptions={[10, 20, 50, 100, 200]}
          />
        </Row>
      </Row>
    )
  }, [
    value,
    adRecords,
    adGroupLoading,
    adGroupRecords,
    adGroupPayload.searchName,
    adLoading,
    adPayload.searchName,
    adPayload.pageId,
    adPayload.pageSize,
    adTotal,
    active,
    handleAdGroupPayloadChange,
    activeAdGroup,
    onChange,
    handleAdPayloadChange,
    expanded,
  ])
}
