import { Empty, ConfigProvider, message } from 'antd'

import ALayout from '@/app/Layout'
import emptyImage from '@/images/empty.png'

// 汉化
import dayjs from 'dayjs'
import zh_CN from 'antd/es/locale/zh_CN'
import 'dayjs/locale/zh-cn'
dayjs.locale('zh-CN')

// 消息配置
message.config({ maxCount: 3 })

export default function App() {
  return (
    <ConfigProvider form={{ colon: false }} locale={zh_CN} renderEmpty={() => <Empty image={emptyImage} />}>
      <ALayout />
    </ConfigProvider>
  )
}
