import { AEmpty } from '@/components'
import { Col, Row, Spin } from 'antd'
import { mergeClassNames } from '@/utils'
import type { CSSProperties } from 'styled-components'
import type { Key, ReactNode } from 'react'

import { AFreeListWrapper } from './styled'

export interface AFreeListProps<T = SafeAny> {
  // dom
  title?: ReactNode
  subTitle?: ReactNode
  extra?: ReactNode
  subExtra?: ReactNode
  footer?: ReactNode
  emptyDescription?: ReactNode

  // data
  rowKey: keyof T
  dataSource: T[]
  renderItem: (item: T) => ReactNode

  // styles
  style?: CSSProperties
  bordered?: boolean
  className?: string

  // other
  loading?: boolean
  showHeader?: boolean
}

export function AFreeList<T>(props: AFreeListProps<T>) {
  const {
    title,
    extra,
    /* subTitle, subExtra, footer, */
    emptyDescription,

    rowKey,
    dataSource,
    renderItem,

    style,
    bordered,
    className,

    loading = false,
    showHeader = true,
  } = props

  const renderHeader = () => (
    <Row align={'middle'} justify={'space-between'} className={'afl-header'}>
      <Col className={'afl-header-title'}>{title}</Col>
      <Col className={'afl-header-extra'}>{extra}</Col>
    </Row>
  )

  const mergedClasses = mergeClassNames('afl', className)

  return (
    <AFreeListWrapper style={style} bordered={bordered} className={mergedClasses}>
      {showHeader && (title || extra) && renderHeader()}

      <Spin spinning={loading}>
        <div className={'afl-content'}>
          {dataSource.length ? (
            dataSource.map((item) => {
              const key = item[rowKey] as unknown as Key

              return (
                <div key={key} className={'afl-content-item'}>
                  {renderItem(item)}
                </div>
              )
            })
          ) : (
            <AEmpty className={'afl-content-empty'} description={emptyDescription ?? '暂无数据'} />
          )}
        </div>
      </Spin>
    </AFreeListWrapper>
  )
}
