import { fetcher } from '@/utils'
import { Form, message } from 'antd'
import { createService } from '@/hooks'
import { ChangedFieldValues } from '@/constants'
import { useCallback, useMemo, useState } from 'react'

import { AiRuleApiResult } from './ai-rule-list.service'
import { createNowDateTime } from '../shared'

/**
 * @description Ai 规则复制模型表单域
 */
export class AiRuleCopyFormStore {
  type: ChangedFieldValues // 规则类型
  ruleId: number // 规则 ID
  ruleName: string // 规则名称

  constructor() {
    const { date, time } = createNowDateTime()

    this.type = 2
    this.ruleId = 0
    this.ruleName = `复制规则_${date}_${time}`
  }

  /**
   * @description 复制指定规则模板
   * @param values 表单域对象
   * @returns
   */
  static submit(values: AiRuleCopyFormStore) {
    return fetcher(`/io/ai-rule/template/copy.do`, { payload: values })
  }
}

/**
 * @description Ai 规则复制服务
 */
export function useAiRuleCopyService() {
  const [aiRuleCopyForm] = Form.useForm<AiRuleCopyFormStore>()
  const [aiRuleCopyVisible, setAiRuleCopyVisible] = useState(false)
  const [aiRuleCopyLoading, setAiRuleCopyLoading] = useState(false)

  // 复制
  const handleCopy = useCallback(
    async (successCb: () => void) => {
      // 校验失败不予通过
      try {
        await aiRuleCopyForm.validateFields()
      } catch (error) {
        return
      }

      // 取值提交
      try {
        setAiRuleCopyLoading(true)

        const values = aiRuleCopyForm.getFieldsValue(true)
        const { succeeded } = await AiRuleCopyFormStore.submit(values)

        if (succeeded) {
          successCb()
          message.success('复制成功 ~')
          setAiRuleCopyVisible(false)
        }
      } catch (error) {
        console.error(error)
      } finally {
        setAiRuleCopyLoading(false)
      }
    },
    [aiRuleCopyForm],
  )

  // 复制之前
  const handleCopyBefore = useCallback(
    (record: AiRuleApiResult) => {
      const { id, type, ruleName } = record

      setAiRuleCopyVisible(true)
      aiRuleCopyForm.setFieldsValue({ type, ruleId: id, ruleName: `${ruleName}_copy` })

      // 设置完成延迟（立即）校验
      setTimeout(() => {
        aiRuleCopyForm.validateFields()
      })
    },
    [aiRuleCopyForm],
  )

  return useMemo(() => {
    return {
      aiRuleCopyForm,
      aiRuleCopyVisible,
      aiRuleCopyLoading,

      handleCopy,
      handleCopyBefore,
      setAiRuleCopyVisible,
    }
  }, [aiRuleCopyForm, aiRuleCopyLoading, aiRuleCopyVisible, handleCopy, handleCopyBefore])
}

export const AiRuleCopyService = createService(useAiRuleCopyService)
