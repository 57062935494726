import { nowMoment, DATE_FORMATS } from '@/utils'

/**
 * @description [Input/Output] Props ===> 输入/输出
 */
export interface ModuleIOProps<T> {
  value?: T[]
  onChange?: (value: T[]) => void
}

export function createNowDateTime() {
  const now = nowMoment()
  const time = now.format(DATE_FORMATS.TIME)
  const date = now.format(DATE_FORMATS.DATE).replace(/-/g, '')

  return { date, time }
}

export function createErrorInstance(option: string, value: SafeAny) {
  return new Error(`常量（枚举） [${option}] 传值错误，不存在值: ${value}，请检查常量（枚举）值设置 ~`)
}
