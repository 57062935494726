/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useBind } from '@/hooks'
import { useEffect, useState } from 'react'
import { SCHEDULE_COLUMNS, SCHEDULE_SHEET } from './constants'
import { ATimePeriodPickerWrapper } from './styled'
import { calculateSelected, CALCULATE_SCHEDULE_TIME } from './utils'

interface ATimePeriodPicker {
  value?: string[]
  onChange?: (value: string[]) => void
}

/**
 * @todo: 待优化
 * @description 时段选择器
 */
export function ATimePeriodPicker({ value, onChange }: ATimePeriodPicker) {
  const valueRef = useBind(value)

  // 选中的格子
  const [selectedKeys, setSelectedKeys] = useState<string[]>([])
  // 临时选中的格子
  const [tempSelectedKeys, setTempSelectedKeys] = useState([])
  // 拖选多个时，存储开始位置
  const [startKey, setStartKey] = useState({})
  // 拖选多个时，存储结束位置
  const [endKey, setEndKey] = useState({})

  // 状态初始化
  useEffect(() => {
    if (!valueRef.current?.length) return
    setSelectedKeys(valueRef.current)
  }, [valueRef])

  // 全局绑定 mouseup
  useEffect(() => {
    let isUnmount = false
    if (!isUnmount) {
      window.addEventListener('mouseup', handleMouseUp)
    }
    return () => {
      isUnmount = true
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [startKey, endKey])

  // 鼠标弹起
  const handleMouseUp = () => {
    if (Object.keys(startKey).length > 0 && Object.keys(endKey).length > 0) {
      const resValue = calculateSelected(startKey, endKey) || []
      // 判断选中的格是否已经存在于selectedKeys中，如果有任意一个格已存在，则取消选中；否则选中
      let resultSelectedArray = []
      const isExistSeleced =
        resValue.filter((res) => {
          return selectedKeys.filter((sel) => sel.trKey === res.trKey && sel.tdKey === res.tdKey).length > 0
        }).length > 0
      if (isExistSeleced) {
        resultSelectedArray = selectedKeys.filter((sel) => {
          return resValue.filter((res) => res.trKey === sel.trKey && res.tdKey === sel.tdKey).length === 0
        })
      } else {
        resultSelectedArray = [...selectedKeys, ...resValue]
      }
      setStartKey({})
      setEndKey({})
      setTempSelectedKeys([])
      setSelectedKeys(resultSelectedArray)
      onChange && onChange(resultSelectedArray)
    }
  }

  return (
    <ATimePeriodPickerWrapper>
      <table border={1}>
        <thead>
          {SCHEDULE_COLUMNS.map((trObj) => (
            <tr key={trObj.key}>
              {trObj.thArray.map((thObj) => {
                const thProps = {
                  ...(thObj.rowSpan ? { rowSpan: thObj.rowSpan } : {}),
                  ...(thObj.colSpan ? { colSpan: thObj.colSpan } : {}),
                }
                return (
                  <th key={thObj.key} {...thProps}>
                    {thObj.name || thObj.key}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {SCHEDULE_SHEET.map((dataObj) => {
            return (
              <tr key={dataObj.key}>
                <td>{dataObj.name}</td>
                {dataObj.rowArray.map((rowObj) => {
                  const isSelected =
                    selectedKeys.filter((item) => item.trKey === dataObj.key && item.tdKey === rowObj.key).length > 0
                  const isTempSelected =
                    tempSelectedKeys.filter((item) => item.trKey === dataObj.key && item.tdKey === rowObj.key).length >
                    0

                  return (
                    <td
                      key={rowObj.key}
                      className={`sheetItem ${isSelected && 'selected'} ${isTempSelected && 'actioning'}`}
                      title={`${dataObj.name} ${rowObj.name}`}
                      onMouseDown={() => {
                        const thisKeys = { trKey: dataObj.key, tdKey: rowObj.key }
                        setStartKey(thisKeys)
                        setEndKey(thisKeys)
                        setTempSelectedKeys(calculateSelected(thisKeys, thisKeys))
                      }}
                      onMouseEnter={() => {
                        if (startKey && Object.keys(startKey).length > 0) {
                          setEndKey({ trKey: dataObj.key, tdKey: rowObj.key })
                          setTempSelectedKeys(calculateSelected(startKey, { trKey: dataObj.key, tdKey: rowObj.key }))
                        }
                      }}
                    />
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>

      {Boolean(CALCULATE_SCHEDULE_TIME(selectedKeys).length) && (
        <div className={'sheetResult'}>
          <div className={'resultTitle'}>已选择时间段</div>
          {CALCULATE_SCHEDULE_TIME(selectedKeys).map((item) => {
            return (
              <div className={'resultItem'} key={item.key}>
                <span>{item.weekName}</span>
                <span>{item.timeName}</span>
              </div>
            )
          })}
        </div>
      )}
    </ATimePeriodPickerWrapper>
  )
}
