import { AIcon } from '@/components'
import { useBind } from '@/hooks'
import { useCallback, useMemo } from 'react'
import { Row, Space, Tooltip, Typography, message } from 'antd'

import {
  // services
  AiRuleApiResult,
  AiRuleCopyService,
  AiRuleSchemaService,
  AiRuleSchemaSubmitMode,

  // eventScopes
  AiRuleListEventScope,
} from '@/app/Modules/AiRule'

/**
 * @description Ai 规则列表项操作组
 */
export function AiRuleListItemExtra({ item }: { item: AiRuleApiResult }) {
  const itemRef = useBind(item)
  const aiRuleListDispatch = AiRuleListEventScope.useDispatch()

  const { handleCopyBefore } = AiRuleCopyService.useInject()
  const { handleUpdateBefore } = AiRuleSchemaService.useInject()

  const handleDelete = useCallback(async () => {
    try {
      const { id, type } = itemRef.current
      const { succeeded } = await AiRuleApiResult.delete(id, type)

      if (succeeded) {
        message.success('删除成功~')

        // 派发自定义事件 - 刷新数据
        aiRuleListDispatch()
      }
    } catch (error) {
      console.error(error)
    }
  }, [aiRuleListDispatch, itemRef])

  return useMemo(() => {
    return (
      <Row justify={'end'}>
        <Space onClick={(e) => e.stopPropagation()}>
          <Tooltip title={'编辑'}>
            <Typography.Link onClick={() => handleUpdateBefore(itemRef.current, AiRuleSchemaSubmitMode.UPDATE)}>
              <AIcon type={'icon-edit'} />
            </Typography.Link>
          </Tooltip>

          <Tooltip title={'复制'}>
            <Typography.Link onClick={() => handleCopyBefore(itemRef.current)}>
              <AIcon type={'icon-copy'} />
            </Typography.Link>
          </Tooltip>

          <Tooltip title={'删除'}>
            <Typography.Link onClick={() => handleDelete()}>
              <AIcon type={'icon-delete'} />
            </Typography.Link>
          </Tooltip>
        </Space>
      </Row>
    )
  }, [handleCopyBefore, handleDelete, handleUpdateBefore, itemRef])
}
