import styled from 'styled-components'
import { Typography } from 'antd'

// 协变
interface HolderRenderAccountRecord {
  advertiserId?: string // 广告主 ID
  mediumAccountName?: string // 投放账户名称
}

const defaultText = '-'

const TypographyParagraphWrapper = styled(Typography.Paragraph)`
  margin-bottom: 0 !important;
`

export function HolderRenderAccount(record: HolderRenderAccountRecord) {
  return (
    <>
      <TypographyParagraphWrapper ellipsis title={record.mediumAccountName ?? defaultText}>
        {record.mediumAccountName ?? defaultText}
      </TypographyParagraphWrapper>

      <TypographyParagraphWrapper ellipsis title={record.advertiserId ?? defaultText}>
        ID: {record.advertiserId ?? defaultText}
      </TypographyParagraphWrapper>
    </>
  )
}
