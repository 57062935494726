import bgiNav from '@/images/bgi-nav.png'
import { css } from 'styled-components'

// 文本溢出
export const ATextEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

// 导航标签页通用样式
export const ANavTabsStyle = css`
  padding: 0 24px !important;

  .ant-tabs-nav {
    color: var(--ai-color-nav);
    height: 72px;
    padding: 0 16px;
    font-weight: var(--ai-font-weight-bold);
    background-size: cover;
    background-image: url(${bgiNav});
    background-repeat: no-repeat;
    background-position: center right;

    .ant-tabs-tab {
      width: 120px;
      justify-content: center;

      .anticon {
        margin-right: 0;
      }
    }

    .ant-tabs-extra-content {
      padding-right: 32px;
    }
  }
`

// 标签加粗
export const AFormItemLabelStyle = css`
  .ant-form-item-label {
    label {
      font-weight: var(--ai-font-weight-bold);
    }
  }
`

// 定宽的单选按钮选项
export const ARadioButtonStyle = css`
  .ant-radio-button-wrapper {
    width: 180px;
    text-align: center;
    font-weight: var(--ai-font-weight-medium);

    &:first-child {
      border-radius: var(--ai-base-border-radius) 0 0 var(--ai-base-border-radius);
    }

    &:last-child {
      border-radius: 0 var(--ai-base-border-radius) var(--ai-base-border-radius) 0;
    }
  }
`
