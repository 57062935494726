import { useMemo } from 'react'
import { AInputNumber } from '@/components'
import { Form, Radio, Space } from 'antd'
import { BUDGET_CONDITION_OPTIONS } from '@/constants'

import {
  // types
  type BudgetRangeValueProp,

  // services
  aiRuleConfigs,
  AiRuleSchemaService,
  AiRuleBudgetCondition,
  AiRuleBudgetFormStore,

  // components
  FormRuleName,
  FormBudgetRange,
  FormBudgetNatural,
  FormConditionList,
} from '@/app/Modules/AiRule'

const badv = aiRuleConfigs.ruleBudgetAdjustmentDefaultValue

/**
 * @description Ai 规则模型 - 主体内容 - 提升预算
 */
export function AiRuleSchemaBodyBudget() {
  const { aiRuleSchemaBudgetForm } = AiRuleSchemaService.useInject()

  // 监听条件数组的变化，用于获取条件的单位
  const conditionList = Form.useWatch('conditionList', aiRuleSchemaBudgetForm)
  const conditionUnitIndex = useMemo(() => {
    if (!conditionList) return []

    // 条件对应的单位索引映射，无单位则渲染为空字符串
    return conditionList.map((item) => {
      const conditionOption = BUDGET_CONDITION_OPTIONS.find((eachItem) => eachItem.value === item.field)
      return conditionOption?.unit ?? ''
    })
  }, [conditionList])

  return useMemo(() => {
    return (
      <Form {...aiRuleConfigs.formProps} form={aiRuleSchemaBudgetForm} initialValues={new AiRuleBudgetFormStore()}>
        <Form.Item
          name={'budgetRangeValueProp'}
          label={'日预算范围'}
          rules={[
            {
              validator: (_, value: BudgetRangeValueProp) => {
                const { min, max, operator } = value

                const isRange = operator === 'Range'

                // 非范围操作符，跳过校验
                if (!isRange) return Promise.resolve()

                const diff = max - min
                if (diff === 0) {
                  return Promise.reject('日预算范围不能相等，请重新设置')
                } else if (diff < 0) {
                  return Promise.reject('最小值不能超过最大值，请重新设置')
                } else {
                  return Promise.resolve()
                }
              },
            },
          ]}
        >
          <FormBudgetRange />
        </Form.Item>

        <FormConditionList
          conditionEntity={AiRuleBudgetCondition}
          conditionOptions={BUDGET_CONDITION_OPTIONS}
          conditionUnitIndex={conditionUnitIndex}
        />

        <Form.Item name={'multiplesType'} label={'执行操作'}>
          <Radio.Group
            options={[
              {
                value: 1,
                label: (
                  <Space className={'multiple-value-item'}>
                    <span>预算提升</span>
                    <Form.Item noStyle name={'multipleNaturalValue'}>
                      <FormBudgetNatural />
                    </Form.Item>
                    <span>元</span>
                  </Space>
                ),
              },
              {
                value: 2,
                label: (
                  <Space className={'multiple-value-item'}>
                    <span>预算提升</span>
                    <Form.Item noStyle name={'multiplePercentValue'}>
                      <AInputNumber min={badv.percentValue} style={{ width: '80px' }} precision={0} />
                    </Form.Item>
                    <span>%</span>
                  </Space>
                ),
              },
            ]}
          />
        </Form.Item>

        <FormRuleName />
      </Form>
    )
  }, [aiRuleSchemaBudgetForm, conditionUnitIndex])
}
