import { Alert, Divider, Space } from 'antd'
import { PropsWithChildren, ReactNode } from 'react'

import { AHoverBarWrapper } from './styled'

export interface AHoverBarProps {
  visible?: boolean
  message?: ReactNode
  actions?: ReactNode[]
  contentHeight?: string | number
  onClose?: () => void
}

export function AHoverBar(props: PropsWithChildren<AHoverBarProps>) {
  const { visible, message, actions = [], contentHeight, children, onClose } = props

  const renderContent = () => {
    if (visible) {
      return (
        <Alert
          closable
          message={
            <Space>
              {message}
              <Divider type="vertical" className="hover-bar-divider" />
              <Space split={<Divider type="vertical" className="hover-bar-divider" />}>
                {actions.map((item) => item)}
              </Space>
            </Space>
          }
          afterClose={onClose}
        />
      )
    } else {
      return <>{children}</>
    }
  }

  return <AHoverBarWrapper contentHeight={contentHeight}>{renderContent()}</AHoverBarWrapper>
}
