import { FormProps } from 'antd'
import { OperatorOptionsValues } from '@/constants'

import { ModuleIOProps } from '../shared'
import { AiRuleApiResult } from './ai-rule-list.service'

export type AiRuleConfigs = {
  formProps: FormProps // 表单固定配置
  ruleConditionLimit: readonly [number, number] // 规则条件限制数量
  ruleNameLengthRange: readonly [number, number] // 规则名称长度范围
  ruleSelectedLimitRange: readonly [number, number] // 规则选择限制范围
  ruleTriggerFrequencyRange: readonly [number, number] // 规则触发频率上下限
  // 预算调整默认值
  ruleBudgetAdjustmentDefaultValue: {
    naturalValue: number // 自然数值
    percentValue: number // 百分比值
  }
}
export type AiRuleIOProps = ModuleIOProps<AiRuleApiResult> // Ai 规则模块输入输出接口
export type AiRuleIOPropsROMerged = Required<Pick<AiRuleIOProps, 'value'>> & Pick<AiRuleIOProps, 'onChange'> // value 必选 & onChange 可选 = RO = required & optional
export type AiRuleItemProps = AiRuleIOPropsROMerged & { item: AiRuleApiResult } // Ai 规则列表项通用接口
export type OperatorOptionsValuesWithoutRange = Exclude<OperatorOptionsValues, 'Range'> // 排除 [介于] 操作符
export type CreateConditionFields<T extends string> = `${T}${OperatorOptionsValuesWithoutRange}` // 组合 [条件字段 + 操作符] - 只支持 [小于/大于]

/**
 * @description Ai 规则统一配置
 */
export const aiRuleConfigs: AiRuleConfigs = {
  formProps: {
    labelCol: { span: 4 },
    labelAlign: 'left',
    wrapperCol: { offset: 1, span: 19 },
  },
  ruleConditionLimit: [1, 5], // 最少 1 个，最多 5 个
  ruleNameLengthRange: [0, 20], // 最短 0，最长 20
  ruleSelectedLimitRange: [0, 20], // 最少 0 个，最多 20 个
  ruleTriggerFrequencyRange: [1, Infinity], // 最少 1 次，最多 20 次
  ruleBudgetAdjustmentDefaultValue: { naturalValue: 100, percentValue: 105 }, // 默认值即最小值
} as const

/**
 * @description Tab key
 */
export enum AiRuleSchemaTabKey {
  SWITCH = 'SWITCH',
  BUDGET = 'BUDGET',
}

/**
 * @description 提交模式
 */
export enum AiRuleSchemaSubmitMode {
  CREATE = 'CREATE', // 创建规则
  UPDATE = 'UPDATE', // 编辑规则
  UPDATE_LOCAL = 'UPDATE_LOCAL', // 编辑规则 - 编辑本地已选择的规则
}

/**
 * @description 数据结构 - 公共字段
 */
export class AiRuleApiCommonSchema {
  id!: number // 规则 ID
  ruleName!: string // 规则名称
}

/**
 * @description 元转分 - 传给后端需要转换成分
 * @param value 元
 * @returns
 */
export function toCent(value?: number) {
  return (value ?? 0) * 100
}

/**
 * @description 分转元 - 前端显示需要转换成元
 * @param value 分
 * @returns
 */
export function toYuan(value?: number) {
  return (value ?? 0) / 100
}
