import { useMemo } from 'react'
import { Modal, Select } from 'antd'
import { HolderService } from '@/app/Holder'

interface SelectAppProps {
  value?: number
  onChange?: (value?: number) => void
}

/**
 * @description 应用选择器
 */
export function SelectApp({ value, onChange }: SelectAppProps) {
  const { holderAppLoading, holderAppOptions } = HolderService.useInject()

  return useMemo(() => {
    return (
      <Select
        allowClear
        showSearch
        value={value}
        style={{ width: '200px' }}
        loading={holderAppLoading}
        options={holderAppOptions}
        optionFilterProp={'label'}
        onChange={(newValue) => {
          if (value !== undefined && newValue !== value) {
            Modal.confirm({
              title: '确定要切换应用吗？这将会重置已选择的投放账户/广告（组）数据。',
              centered: true,
              onOk: () => onChange?.(newValue),
            })
          } else {
            onChange?.(newValue)
          }
        }}
        placeholder={'请选择应用'}
        getPopupContainer={(node) => node.parentElement ?? document.body}
      />
    )
  }, [holderAppLoading, holderAppOptions, onChange, value])
}
