import { AiRule } from '@/app/Modules'
import { useMemo } from 'react'
import {
  // services
  StrategySchemaService,
} from '@/app/Modules'

/**
 * @description 策略模型 - 主体内容
 */
export function StrategySchemaBody() {
  const { strategySchema, setStrategySchema } = StrategySchemaService.useInject()

  return useMemo(() => {
    return (
      <AiRule
        value={strategySchema.selectedRules}
        onChange={(value) => setStrategySchema({ selectedRules: value })}
        dayTimes={{
          switch: strategySchema.switchDayTimes,
          budget: strategySchema.budgetDayTimes,
          autoExecution: strategySchema.autoExecutionControl,
        }}
        onDayTimesChange={(dayTimes) => {
          setStrategySchema({
            switchDayTimes: dayTimes.switch,
            budgetDayTimes: dayTimes.budget,
            autoExecutionControl: dayTimes.autoExecution,
          })
        }}
      />
    )
  }, [
    setStrategySchema,
    strategySchema.autoExecutionControl,
    strategySchema.budgetDayTimes,
    strategySchema.selectedRules,
    strategySchema.switchDayTimes,
  ])
}
