import { fetcher } from '@/utils'
import { createService, useCb } from '@/hooks'
import { useEffect, useMemo, useState } from 'react'

export class UserInfo {
  mobile?: null
  payType?: number
  groupId?: number
  industry?: number
  companyId?: number
  accountId!: number
  effectDate?: string
  accountRole?: number
  companyName?: string
  accountName!: string
  invalidDate?: string
  orderStatus?: number
  accountAlias?: string
  switchStatus?: number
  departmentId?: number
  productVersion?: number
  authProductIds?: string
  menuPermission?: string
  parentAccountId?: number
  wechatAuthAppId?: string
  gdtAuthClientId?: string
  gdtOAuthCallback?: string
  toutiaoAuthScope?: string
  toutiaoAuthAppId?: string
  kuaishouAuthScope?: string
  kuaishouAuthAppId?: string
  switchFromAccount?: null
  departmentAuthority?: number
  toutiaoOAuthCallback?: string
  kuaishouOAuthCallback?: string
  responsibleAccountType?: number

  static API = '/io/sa/get-login-user-info.do'

  static API_LOGOUT = '/pt/logout.do'
}

function useAppService() {
  const [loading, setLoading] = useState(true) // 初始值必须设置为 true
  const [userInfo, setUserInfo] = useState(() => new UserInfo())

  const request = useCb(async () => {
    try {
      const { content } = await fetcher(UserInfo.API)

      setUserInfo(content ?? {})
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  })

  const handleLogout = useCb(async (accountName: string) => {
    try {
      await fetcher(UserInfo.API_LOGOUT, { payload: { accountName } })
    } catch (error) {
      console.error(error)
    }
  })

  useEffect(() => {
    request()
  }, [request])

  return useMemo(() => {
    return {
      loading,
      userInfo,

      handleLogout,
    }
  }, [loading, userInfo, handleLogout])
}

export const AppService = createService(useAppService)
