import { AIcon } from '@/components'
import { Tooltip, TooltipProps } from 'antd'

export function AQuestion(props: TooltipProps) {
  const { overlayInnerStyle, ...baseProps } = props

  return (
    <Tooltip {...baseProps} overlayInnerStyle={{ textAlign: 'justify', ...overlayInnerStyle }}>
      <AIcon type={'icon-question'} style={{ color: 'var(--ai-color-icon)', cursor: 'help' }} />
    </Tooltip>
  )
}
