import { Space } from 'antd'
import {
  // types
  type StrategyIOProps,

  // services
  StrategyListService,
  StrategySchemaService,
  StrategyListEventScope,

  // components
  StrategyList,
  StrategyListToolbar,
  StrategyListSelected,
  StrategySchema,
} from '@/app/Modules'

/**
 * @description 策略模块内置模板组件（Template Component）- 聚合策略所有相关的组件
 */
export function Strategy(ioProps: StrategyIOProps) {
  return (
    <StrategyListService.Provider>
      <StrategyListEventScope.EventScope>
        <Space size={12} className={'w-100'} direction={'vertical'}>
          <StrategySchemaService.Provider>
            <StrategyListToolbar />
            <StrategySchema />
          </StrategySchemaService.Provider>

          <StrategyList {...ioProps} />
        </Space>
      </StrategyListEventScope.EventScope>

      <StrategyListSelected {...ioProps} />
    </StrategyListService.Provider>
  )
}
