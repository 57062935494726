import styled from 'styled-components'
import { ATextEllipsis } from '@/components'

export const ListItemWrapper = styled.div`
  height: 200px;
  display: flex;
  padding: 24px 24px 12px 24px !important;
  flex-direction: column;

  .item-header {
    ${ATextEllipsis}

    &-title {
      color: var(--ai-color-label);
      margin-left: 8px;
      font-weight: var(--ai-font-weight-bold);
    }
  }

  .item-content {
    flex: 1;
    color: var(--ai-color-label);
    margin: 16px 0;

    &-condition {
      color: var(--ai-color-label);
      text-align: justify;
      margin-bottom: 0;
    }
  }
`
