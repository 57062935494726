import { AInputNumber } from '@/components'
import { Divider, Space, Switch } from 'antd'

import {
  // types
  type AiRuleIOPropsROMerged,

  // services
  aiRuleConfigs,

  // components
  Toolbar,
  TitleWrapper,
  ExtraWrapper,
} from '@/app/Modules/AiRule'

interface DayTimesType {
  budget: number
  switch: number
  autoExecution: boolean
}

export interface AiRuleListSelectedToolbarProps extends AiRuleIOPropsROMerged {
  dayTimes: DayTimesType
  onDayTimesChange: (dayTimes: DayTimesType) => void
}

const [min, max] = aiRuleConfigs.ruleTriggerFrequencyRange

/**
 * @description Ai 规则列表 - 已选择 - 工具栏
 */
export function AiRuleListSelectedToolbar({
  value,

  dayTimes,
  onDayTimesChange,
}: AiRuleListSelectedToolbarProps) {
  return (
    <Toolbar
      title={
        <TitleWrapper size={12}>
          <span className={'text'}>已选规则</span>
          <span className={'range'}>{value.length}/20</span>
        </TitleWrapper>
      }
      extra={
        <ExtraWrapper size={16}>
          <Space>
            <span>自动执行</span>
            <Switch
              checked={dayTimes.autoExecution}
              onChange={(value) => onDayTimesChange({ ...dayTimes, autoExecution: value })}
            />
          </Space>

          <Divider type={'vertical'} />

          <Space>
            <span>预算调整上限</span>
            <AInputNumber
              min={min}
              max={max}
              size={'small'}
              value={dayTimes.budget}
              onChange={(value) => onDayTimesChange({ ...dayTimes, budget: value })}
            />
            <span>次/天</span>
          </Space>

          <Space>
            <span>启停广告上限</span>
            <AInputNumber
              min={min}
              max={max}
              size={'small'}
              value={dayTimes.switch}
              onChange={(value) => onDayTimesChange({ ...dayTimes, switch: value })}
            />
            <span>次/天</span>
          </Space>
        </ExtraWrapper>
      }
    />
  )
}
