import { cloneDeep } from 'lodash-es'
import { safeParseJSON } from '@/utils'
import {
  SceneOptionsValues,
  OperateOptionsValues,
  CostTypeOptionsValues,
  SCENE_CASCADE_OPTIONS,
  SWITCH_CONDITION_OPTIONS,
  SceneCascadeOptionsValues,
  RollingDurationOptionsValues,
  SwitchConditionOptionsValues,
  DeliveryDurationOptionsValues,
} from '@/constants'

import { AiRuleApiResult } from './ai-rule-list.service'
import { CostRangeValueProp } from './components'
import { createErrorInstance, createNowDateTime } from '../shared'
import {
  toCent,
  toYuan,
  AiRuleApiCommonSchema,
  CreateConditionFields,
  OperatorOptionsValuesWithoutRange,
} from './ai-rule.shared'

export type SceneCascadeOptions = OptionType<SceneCascadeOptionsValues, string>[] | undefined
export type SwitchConditionFields = CreateConditionFields<SwitchConditionOptionsValues> // 含 Min or Max 的条件字段
export type SwitchConditionObject = Partial<Record<SwitchConditionFields, number>> // 条件字段对象

// 级联值对象
export type SwitchSceneCascadeValueObject = {
  costType?: CostTypeOptionsValues
  rollingDuration?: RollingDurationOptionsValues
  deliveryDuration?: DeliveryDurationOptionsValues
}

/**
 * @description 数据结构 - 关闭广告规则
 */
export class AiRuleApiSwitchSchema extends AiRuleApiCommonSchema {
  scene!: SceneOptionsValues // 投放阶段（场景）
  operate!: OperateOptionsValues // 开关操作
  costType?: CostTypeOptionsValues // 广告消耗类型
  rollingDuration?: RollingDurationOptionsValues // 曝光时长
  deliveryDuration?: DeliveryDurationOptionsValues // 投放时长
}

/**
 * @description 关闭广告规则条件实体
 */
export class AiRuleSwitchCondition {
  value: number // 字段值
  field: SwitchConditionOptionsValues // 条件字段
  operator: OperatorOptionsValuesWithoutRange // 操作符

  constructor() {
    this.value = 0
    this.field = 'totalActiveNumMedium'
    this.operator = 'Max'
  }
}

/**
 * @description 关闭广告规则表单域
 */
export class AiRuleSwitchFormStore extends AiRuleApiSwitchSchema {
  conditionList: AiRuleSwitchCondition[] // 条件数组
  costRangeValueProp: CostRangeValueProp // 历史消耗范围组件 value prop
  sceneCascadeOptions?: SceneCascadeOptions // 投放阶段（场景）级联选项
  sceneCascadeOptionsValue?: SceneCascadeOptionsValues // 投放阶段（场景）级联选项值

  constructor() {
    super()

    const defaultScene = 1
    const defaultSceneCascadeOptions = SCENE_CASCADE_OPTIONS[defaultScene] ?? []
    const { date, time } = createNowDateTime()

    this.scene = defaultScene
    this.operate = 2
    this.ruleName = `关闭广告_${date}_${time}`
    this.conditionList = [new AiRuleSwitchCondition()]
    this.costRangeValueProp = { min: 0, max: 0 }
    this.sceneCascadeOptions = defaultSceneCascadeOptions
    this.sceneCascadeOptionsValue = defaultSceneCascadeOptions.at(0)?.value
  }

  /**
   * @description 表单域对象转换到接口所需要的数据结构
   * @param values 表单域对象
   */
  static toSchema(values: AiRuleSwitchFormStore): AiRuleApiSwitchSchema & SwitchConditionObject {
    const cloned = cloneDeep(values)

    // 数据转化
    const normalizedCostRange = this.normalCostRange(cloned.scene, cloned.costRangeValueProp)
    const normalizedConditionObject = this.normalConditionToObject(cloned.conditionList)
    const normalizedSceneCascadeOptionsValue = this.normalSceneCascadeOptionsValue(
      cloned.scene,
      cloned.sceneCascadeOptionsValue,
    )

    return {
      // 基本字段
      id: cloned.id,
      scene: cloned.scene,
      operate: cloned.operate,
      ruleName: cloned.ruleName,

      // 级联字段
      costType: normalizedSceneCascadeOptionsValue.costType,
      totalCostMin: normalizedCostRange.min,
      totalCostMax: normalizedCostRange.max,
      rollingDuration: normalizedSceneCascadeOptionsValue.rollingDuration,
      deliveryDuration: normalizedSceneCascadeOptionsValue.deliveryDuration,

      // 条件字段
      ...normalizedConditionObject,
    }
  }

  /**
   * @description 规则实体转换到表单域对象
   * @param record 规则实体
   */
  static toValues(record: AiRuleApiResult): AiRuleSwitchFormStore {
    const parsedCondition = safeParseJSON(record.customRuleJson) as AiRuleApiSwitchSchema & SwitchConditionObject

    const normalizedConditionArray = this.normalConditionToArray(record.sceneSwitch!, parsedCondition)
    const normalizedCostRangeReverse = this.normalCostRangeReverse(record.sceneSwitch!, {
      min: parsedCondition.totalCostMin,
      max: parsedCondition.totalCostMax,
    })
    const normalizedSceneCascadeOptionsWithValue = this.normalSceneCascadeOptionsWithValueReverse(record.sceneSwitch!, {
      costType: record.costType,
      rollingDuration: parsedCondition.rollingDuration,
      deliveryDuration: parsedCondition.deliveryDuration,
    })

    return {
      id: record.id,
      scene: record.sceneSwitch!,
      operate: Number(record.execution) as OperateOptionsValues,
      ruleName: record.ruleName!,
      conditionList: normalizedConditionArray,
      costRangeValueProp: normalizedCostRangeReverse,
      sceneCascadeOptions: normalizedSceneCascadeOptionsWithValue.options,
      sceneCascadeOptionsValue: normalizedSceneCascadeOptionsWithValue.value,
    }
  }

  /**
   * @description 处理历史消耗范围，当 [投放阶段（场景）] 选择 [计划观察期效果欠佳] 时，该字段有效
   * @param option 投放阶段（场景）
   * @param value 历史消耗范围组件的 value prop
   */
  static normalCostRange(option: SceneOptionsValues, value: CostRangeValueProp): Partial<CostRangeValueProp> {
    switch (option) {
      case 1:
      case 2:
      case 4:
        return {}

      case 3:
        return { min: toCent(value.min), max: toCent(value.max) }

      default:
        throw createErrorInstance('SceneOptionsValues', option)
    }
  }

  static normalCostRangeReverse(option: SceneOptionsValues, value: Partial<CostRangeValueProp>): CostRangeValueProp {
    switch (option) {
      case 1:
      case 2:
      case 4:
        return { min: 0, max: 0 }

      case 3:
        return { min: toYuan(value.min), max: toYuan(value.max) }

      default:
        throw createErrorInstance('SceneOptionsValues', option)
    }
  }

  /**
   * @description 将条件 JSON 对象转换成数组
   * @param value 条件对象
   */
  static normalConditionToArray(option: SceneOptionsValues, value: SwitchConditionObject) {
    const normalized = Object.keys(value)
      .map((key) => {
        // 如果是计划观察期，条件里排除总消耗
        if (option === 3 && key.includes('totalCost')) return

        if (key.endsWith('Max')) {
          const [field] = key.split('Max')

          const conditionOption = SWITCH_CONDITION_OPTIONS.find((eachItem) => eachItem.value === field)
          const conditionValue = value[key as SwitchConditionFields]

          return {
            field,
            value: conditionOption?.unit === '元' ? toYuan(conditionValue) : conditionValue,
            operator: 'Max',
          }
        }

        if (key.endsWith('Min')) {
          const [field] = key.split('Min')

          const conditionOption = SWITCH_CONDITION_OPTIONS.find((eachItem) => eachItem.value === field)
          const conditionValue = value[key as SwitchConditionFields]

          return {
            field,
            value: conditionOption?.unit === '元' ? toYuan(conditionValue) : conditionValue,
            operator: 'Min',
          }
        }
      })
      .filter((item) => Boolean(item)) // 过滤 undefined

    return normalized as AiRuleSwitchCondition[]
  }

  /**
   * @description 将条件数组转换成对象，其中 [键名 = 条件名 + 操作符] [键值 = 值]
   * @param value 条件数组
   */
  static normalConditionToObject(value: AiRuleSwitchCondition[] = []) {
    const condition: SwitchConditionObject = {}

    value.forEach((item) => {
      const conditionOption = SWITCH_CONDITION_OPTIONS.find((eachItem) => eachItem.value === item.field)

      condition[`${item.field}${item.operator}`] = conditionOption?.unit === '元' ? toCent(item.value) : item.value
    })

    return condition
  }

  /**
   * @description 根据不同的 [投放阶段（场景）] 获取对应的级联值
   * @param option 投放场景
   * @param value 级联值
   * @returns
   */
  static normalSceneCascadeOptionsValue(
    option: SceneOptionsValues,
    value?: SceneCascadeOptionsValues,
  ): SwitchSceneCascadeValueObject {
    switch (option) {
      // 冷启动期跑量能力欠佳 - 设置投放时长
      case 1:
        return { deliveryDuration: value as DeliveryDurationOptionsValues }

      // 冷启动期跑量效果欠佳 - 设置曝光时长
      case 2:
        return { rollingDuration: value as RollingDurationOptionsValues }

      // 计划观察期效果欠佳 - 设置消耗类型广告
      case 3:
        return { costType: value as CostTypeOptionsValues }

      // 计划衰退期效果欠佳 - 无级联值
      case 4:
        return {}

      default:
        throw createErrorInstance('SceneOptionsValues', option)
    }
  }

  static normalSceneCascadeOptionsWithValueReverse(
    option: SceneOptionsValues,
    value: SwitchSceneCascadeValueObject,
  ): { value: SceneCascadeOptionsValues; options: SceneCascadeOptions } {
    switch (option) {
      // 冷启动期跑量能力欠佳 - 设置投放时长
      case 1:
        return {
          value: value.deliveryDuration ?? 24,
          options: SCENE_CASCADE_OPTIONS[1]!,
        }

      // 冷启动期跑量效果欠佳 - 设置曝光时长
      case 2:
        return {
          value: value.rollingDuration ?? 24,
          options: SCENE_CASCADE_OPTIONS[2]!,
        }

      // 计划观察期效果欠佳 - 设置消耗类型广告
      case 3:
        return {
          value: value.costType ?? 1,
          options: SCENE_CASCADE_OPTIONS[3]!,
        }

      // 计划衰退期效果欠佳 - 无级联值
      case 4:
        return {
          value: 1,
          options: [],
        }

      default:
        throw createErrorInstance('SceneOptionsValues', option)
    }
  }
}
