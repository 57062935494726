import { List, Space } from 'antd'
import styled, { css } from 'styled-components'

const sharedTitleStyle = css`
  font-size: var(--ai-font-size-normal);
  font-weight: var(--ai-font-weight-bold);
`

export const baseHeight = '48px'

export const TabsWrapper = styled(Space)`
  ${sharedTitleStyle}

  .item {
    height: ${baseHeight};
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease-in-out;
    line-height: ${baseHeight};

    &::after {
      width: 100%;
      height: 2px;
      content: '';
      display: block;
      position: absolute;
      transform: scale(0);
      transition: all 0.3s ease-in-out;
      background-color: var(--ai-color-primary);
    }

    &:hover,
    &.active {
      color: var(--ai-color-label);

      &::after {
        transform: scale(1);
      }
    }
  }
`

export const TitleWrapper = styled(Space)`
  color: var(--ai-color-label);

  .text {
    ${sharedTitleStyle}
  }

  .range {
    font-weight: var(--ai-font-weight-medium);
  }

  .question {
    color: var(--ai-color-icon);
    cursor: help;
  }
`

export const ExtraWrapper = styled(Space)`
  color: var(--ai-color-label);
  font-size: var(--ai-font-size-small);
  font-weight: var(--ai-font-weight-medium);

  .ant-input-number {
    width: 60px;
  }
`

export const AiRuleListItemWrapper = styled(List.Item)`
  cursor: pointer;
  border: 1px solid var(--ai-color-border) !important;
  transition: all 0.3s ease-in-out;
  border-radius: var(--ai-base-border-radius);

  &:hover,
  &.selected {
    border-color: var(--ai-color-primary) !important;
  }
`

export const AiRuleListSelectedItemWrapper = styled(List.Item)`
  border: 1px solid var(--ai-color-border) !important;
  position: relative;
  border-radius: var(--ai-base-border-radius);

  &:hover {
    .icon-remove {
      opacity: 0.75;
    }
  }

  .icon-remove {
    top: -5px;
    right: -5px;
    color: var(--ai-color-compare);
    cursor: pointer;
    opacity: 0;
    position: absolute;
    transition: all 0.3s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }

  &.custom {
    height: 200px;
    cursor: pointer;
    padding: 24px !important;
    transition: all 0.3s ease-in-out;

    &:hover {
      border-color: var(--ai-color-primary) !important;

      .custom-item-text {
        color: var(--ai-color-primary);
      }
    }

    .custom-item {
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      font-size: var(--ai-font-size-normal);

      &-icon {
        color: var(--ai-color-primary);
        margin-right: 4px;
      }

      &-text {
        color: var(--ai-color-label);
        transition: all 0.3s ease-in-out;
        font-weight: var(--ai-font-weight-bold);
      }
    }
  }
`

export const AiRuleSchemaBodyWrapper = styled.div`
  .ant-tabs {
    height: 520px;

    &-nav-wrap {
      justify-content: center;

      .ant-tabs-tab {
        width: 200px;
        justify-content: center;

        &-active {
          font-weight: var(--ai-font-weight-bold);
        }
      }
    }
  }

  .ant-form-item-label {
    label {
      font-size: var(--ai-font-size) !important;
      font-weight: var(--ai-font-weight-medium);
    }
  }

  .multiple-value {
    &-item {
      color: var(--ai-color-label);
    }

    .ant-radio-group {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .ant-radio-wrapper {
        margin-right: 0;

        &:last-child {
          span.ant-radio + * {
            padding-right: 0;
          }
        }
      }
    }
  }

  .operate-value {
    .ant-form-item-label {
      label {
        height: auto;
      }
    }
  }
`
