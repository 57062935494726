/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCb } from '@/hooks'
import { ADrawer, AIcon, APlayground } from '@/components'
import { useMemo, useState } from 'react'
import { AppService } from '@/app/app.service'
import { DownOutlined } from '@ant-design/icons'
import { APP_SIDE_MAIN_HOME } from '@/constants'
import { Button, Dropdown, Menu, Row, Space } from 'antd'

import { HeaderExtraMenuKey } from './layout.shared'
import { LayoutBackHomeWrapper } from './layout.styled'

export function LayoutHeaderExtra() {
  const { userInfo } = AppService.useInject()

  const [visible, setVisible] = useState(false)

  const handleMenuClick = useCb((key: HeaderExtraMenuKey) => {
    switch (key) {
      case HeaderExtraMenuKey.LOGOUT:
        return console.log('logout')

      default:
        return
    }
  })

  return useMemo(() => {
    return (
      <Row align={'middle'} justify="space-between">
        <LayoutBackHomeWrapper
          type="text"
          icon={<AIcon type={'icon-back-home'} />}
          onClick={() => window.open(APP_SIDE_MAIN_HOME)}
        >
          返回首页
        </LayoutBackHomeWrapper>

        <Space size={40}>
          {/* <Button type={'primary'} onClick={() => setVisible(true)}>
            AFlattenTree
          </Button> */}
          <ADrawer width={1200} visible={visible} onClose={() => setVisible(false)}>
            <APlayground />
          </ADrawer>
          <Space>
            <span title={`${userInfo.accountAlias}(${userInfo.accountName})`}>
              {userInfo.accountAlias}({userInfo.accountName})
            </span>
            {/* <DownOutlined /> */}
          </Space>

          {/* <Dropdown
            arrow
            placement="bottom"
            overlay={
              <Menu
                items={[{ key: HeaderExtraMenuKey.LOGOUT, label: '退出系统', danger: true }]}
                onClick={(e) => handleMenuClick(e.key as HeaderExtraMenuKey)}
              />
            }
          >
            <Button type="primary">
              <span>新建广告</span>
              <DownOutlined />
            </Button>
          </Dropdown> */}
        </Space>
      </Row>
    )
  }, [visible, userInfo.accountAlias, userInfo.accountName])
}
