import { fetcher } from '@/utils'
import { useState, useCallback, useEffect, useMemo } from 'react'
import { createService, createEventScope, useMergeState } from '@/hooks'
import {
  ChangedFieldValues,
  TemplateTypeValues,
  SceneOptionsValues,
  MultiplesTypeValues,
  CostTypeOptionsValues,
} from '@/constants'

/**
 * @api /io/ai-rule/template/query.do
 * @description Ai 规则请求参数
 */
export class AiRuleApiParams {
  type: ChangedFieldValues = 3 // 操作规则类型
  pageId = 1
  pageSize = 8
  searchName?: string
}

/**
 * @api /io/ai-rule/template/query.do
 * @description Ai 规则响应结果
 */
export class AiRuleApiResult {
  id!: number // ID
  type!: ChangedFieldValues // 规则类型
  ruleName?: string // 规则名称
  templateType?: TemplateTypeValues // 模板类型
  customRuleJson?: string // 条件 JSON

  // 当 type 为 2 时，该值赋给 operate | 当 type 为 3 时，值赋给 multiples
  execution?: string // 执行操作的值 - 需要转换成 Number

  // type = 2
  costType?: CostTypeOptionsValues // 消耗广告类型
  sceneSwitch?: SceneOptionsValues // 投放阶段（场景）

  // type = 3
  budgetMin?: number // 日预算最小值 (单位：分)
  budgetMax?: number // 日预算最大值 (单位：分)
  executionType?: MultiplesTypeValues // 值赋给 multiplesType

  /**
   * @description 主键
   */
  static PRIMARY_KEY: keyof AiRuleApiResult = 'id'

  /**
   * @description 请求 Ai 规则数据
   * @param payload 请求参数
   */
  static request(payload: AiRuleApiParams) {
    return fetcher(`/io/ai-rule/template/query.do`, { payload })
  }

  /**
   * @description 删除指定 Ai 规则
   * @param id 规则 ID
   * @param type 规则类型
   */
  static delete(id: number, type: ChangedFieldValues) {
    return fetcher(`/io/ai-rule/template/delete.do`, { method: 'post', payload: { id, type } })
  }
}

/**
 * @description Ai 规则列表服务
 */
export function useAiRuleListService() {
  const [aiRuleList, setAiRuleList] = useState<AiRuleApiResult[]>([])
  const [aiRuleTotal, setAiRuleTotal] = useState(0)
  const [aiRuleLoading, setAiRuleLoading] = useState(false)

  const [aiRulePayload, aiRulePayloadRef, handleAiRulePayloadChange] = useMergeState(AiRuleApiParams)

  const aiRuleRequest = useCallback(async () => {
    try {
      setAiRuleLoading(true)

      const { content } = await AiRuleApiResult.request(aiRulePayloadRef.current)

      setAiRuleList(content?.content ?? [])
      setAiRuleTotal(content?.totalRecord ?? 0)
    } catch (error) {
      console.error(error)
    } finally {
      setAiRuleLoading(false)
    }
  }, [aiRulePayloadRef])

  useEffect(() => {
    aiRuleRequest()
  }, [aiRulePayload, aiRuleRequest])

  AiRuleListEventScope.useListener(() => aiRuleRequest())

  return useMemo(() => {
    return {
      aiRuleList,
      aiRuleTotal,
      aiRuleLoading,
      aiRulePayload,
      aiRuleRequest,

      handleAiRulePayloadChange,
    }
  }, [aiRuleList, aiRuleLoading, aiRulePayload, aiRuleRequest, aiRuleTotal, handleAiRulePayloadChange])
}

export const AiRuleListService = createService(useAiRuleListService)
export const AiRuleListEventScope = createEventScope()
export const AiRuleListSelectedEventScope = createEventScope<AiRuleApiResult>()
