import styled from 'styled-components'
import { Space } from 'antd'

export const FormConditionListWrapper = styled(Space)`
  width: 100%;

  .item {
    position: relative;
    margin-left: 5%;

    &-and {
      top: 50%;
      left: -20px;
      position: absolute;
      transform: translateY(-50%);
    }
  }
`
