import { Row } from 'antd'
import { useMemo } from 'react'

import { HolderSetupStep } from './holder-setup.shared'
import { HolderSetupSchemaService } from './holder-setup-schema.service'
import { HolderSetupSchemaExtraStep1 } from './HolderSetupSchemaExtraStep-1'
import { HolderSetupSchemaExtraStep2 } from './HolderSetupSchemaExtraStep-2'

export function HolderSetupSchemaExtra() {
  const { holderSetupStep } = HolderSetupSchemaService.useInject()

  return useMemo(() => {
    return (
      <Row justify={'end'}>
        {holderSetupStep === HolderSetupStep.ONE && <HolderSetupSchemaExtraStep1 />}
        {holderSetupStep === HolderSetupStep.TWO && <HolderSetupSchemaExtraStep2 />}
      </Row>
    )
  }, [holderSetupStep])
}
