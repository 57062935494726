import styled from 'styled-components'
import { Button, Form, Row } from 'antd'
import { ARadioButtonStyle, AFormItemLabelStyle } from '@/components'

export const HolderSetupButtonWrapper = styled(Button)`
  padding: 4px 24px !important;
  font-size: var(--ai-font-size-special) !important;
  font-weight: var(--ai-font-weight-bold);
`

export const HolderSetupHeaderExtraWrapper = styled(Row)`
  .step-number {
    width: 26px;
    color: var(--ai-color-nav);
    height: 26px;
    border: 1px solid var(--ai-color-compare);
    text-align: center;
    transition: all 0.3s ease-in-out;
    font-weight: var(--ai-font-weight-bold);
    line-height: 26px;
    border-radius: 50%;
  }

  .step-text {
    color: var(--ai-color-nav);
    font-size: var(--ai-font-size-base);
    transition: all 0.3s ease-in-out;
    font-weight: var(--ai-font-weight-medium);
  }

  .active {
    &.finish {
      opacity: 0.5;
    }

    .step-number {
      color: var(--ai-base-color);
      border: unset;
      background-color: var(--ai-color-primary);
    }

    .step-text {
      color: var(--ai-color-primary);
    }
  }
`

export const HolderSetupSchemaBodyWrapper = styled(Form)`
  ${ARadioButtonStyle}
  ${AFormItemLabelStyle}

  .assist-scope {
    width: 540px;

    &-ma {
      font-size: var(--ai-font-size-normal);
      font-weight: var(--ai-font-weight-medium);
    }
  }
`
