import { useCb } from '@/hooks'
import { fetcher } from '@/utils'
import { useMemo, useState } from 'react'
import { DefaultOptionType } from 'antd/es/select'

export class HolderMediumAccountParams {
  appId?: number // 应用 ID
  mediumId?: number // 媒体 ID

  static API = '/io/ma/select-medium-account.do'

  constructor(initialParams?: Partial<HolderMediumAccountParams>) {
    this.appId = initialParams?.appId

    // 目前仅支持的媒体：今日头条 - 2
    this.mediumId = initialParams?.mediumId ?? 2
  }
}

export class HolderMediumAccountResult {
  companyId?: number
  departmentId?: number

  status?: number // 投放账户状态
  mediumId?: number // 媒体 ID
  mediumName?: string // 媒体名称
  accountRole?: number // 投放账户角色

  mediumAccount?: string
  mediumAccountId?: number // 投放账户 ID
  mediumAccountName?: string // 投放账户名称
  mediumAccountAlias?: string // 投放账户别名
  mediumAccountCompany?: string
}

export function useHolderMediumAccountService() {
  const [holderMediumAccountLoading, setHolderMediumAccountLoading] = useState(false)
  const [holderMediumAccountOptions, setHolderMediumAccountOptions] = useState<DefaultOptionType[]>([])

  const holderMediumAccountRequest = useCb(async (appId?: number) => {
    // 若未选择应用，则重置选项状态并跳过请求
    if (appId == null) return setHolderMediumAccountOptions([])

    try {
      setHolderMediumAccountLoading(true)

      const { content } = await fetcher(HolderMediumAccountParams.API, {
        payload: new HolderMediumAccountParams({ appId }),
      })

      // 组装
      const options = (content as HolderMediumAccountResult[]).map((item) => ({
        value: item.mediumAccountId,
        label: item.mediumAccountName,
      }))

      // 去重
      setHolderMediumAccountOptions(options)
    } catch (error) {
      console.error(error)
    } finally {
      setHolderMediumAccountLoading(false)
    }
  })

  return useMemo(() => {
    return {
      holderMediumAccountLoading,
      holderMediumAccountOptions,

      holderMediumAccountRequest,
    }
  }, [holderMediumAccountLoading, holderMediumAccountOptions, holderMediumAccountRequest])
}
