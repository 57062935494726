import { List } from 'antd'
import { useMemo } from 'react'
import { PaginationConfig } from 'antd/es/pagination'

import {
  // types
  type AiRuleIOPropsROMerged,

  // services
  AiRuleApiResult,
  AiRuleListService,

  // components
  AiRuleListItem,
} from '@/app/Modules/AiRule'

/**
 * @description Ai 规则列表
 */
export function AiRuleList({ value, onChange }: AiRuleIOPropsROMerged) {
  const { aiRuleList, aiRuleTotal, aiRuleLoading, aiRulePayload, handleAiRulePayloadChange } =
    AiRuleListService.useInject()

  // Dependency Merge
  const paginationProps = useMemo((): PaginationConfig => {
    return {
      size: 'small',
      total: aiRuleTotal,
      current: aiRulePayload.pageId,
      pageSize: aiRulePayload.pageSize,
      onChange: (pageId, pageSize) => handleAiRulePayloadChange({ pageId, pageSize }),
      showTotal: (total) => `共 ${total} 条数据`,
      showQuickJumper: true,
      showSizeChanger: true,
      pageSizeOptions: [8, 16, 24, 40, 80],
    }
  }, [aiRulePayload.pageId, aiRulePayload.pageSize, aiRuleTotal, handleAiRulePayloadChange])

  return useMemo(() => {
    return (
      <List
        grid={{ gutter: 12, column: 4 }}
        rowKey={AiRuleApiResult.PRIMARY_KEY}
        loading={aiRuleLoading}
        dataSource={aiRuleList}
        pagination={paginationProps}
        renderItem={(item) => <AiRuleListItem item={item} value={value} onChange={onChange} />}
      />
    )
  }, [aiRuleList, aiRuleLoading, onChange, paginationProps, value])
}
