import { IconFontProps } from '@ant-design/icons/lib/components/IconFont'
import { createFromIconfontCN } from '@ant-design/icons'

import { AIconType, url } from './icons'

const AIconCore = createFromIconfontCN({ scriptUrl: url })

export const AIcon = (props: IconFontProps<AIconType>) => <AIconCore {...props} />

export type { AIconType }
