import { ReactNode } from 'react'
import { Result, Space } from 'antd'
import { LoadingOutlined, SmileOutlined } from '@ant-design/icons'

import { LayoutFallBackWrapper } from './layout.styled'

interface LayoutFallBackProps {
  description: ReactNode
}

export function LayoutFallBack({ description }: LayoutFallBackProps) {
  return (
    <LayoutFallBackWrapper align={'middle'} justify={'center'}>
      <Result
        icon={<LoadingOutlined />}
        title={
          <Space>
            <SmileOutlined />
            <span>{description}</span>
          </Space>
        }
      />
    </LayoutFallBackWrapper>
  )
}
