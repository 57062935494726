import { AIcon, AEmpty } from '@/components'
import { type ReactNode } from 'react'
import { List, Space, Typography } from 'antd'

import { AResultListWrapper } from './styled'

export interface AResultListProps {
  title: ReactNode // 标题
  interval: [number, number] // 区间 - 左开右闭
  className?: string
  dataSource?: OptionType[]
  onClear?: () => void // 点击清空按钮后的回调
  onRemove?: (value: OptionType['value']) => void
}

export function AResultList({ title, interval, className, dataSource = [], onClear, onRemove }: AResultListProps) {
  const [prefix, suffix] = interval

  return (
    <AResultListWrapper className={className}>
      <Space size={24} className={'arl-header'}>
        <Space size={12} className={'arl-header-title'}>
          <span>{title}</span>
          <span>
            {prefix}/{suffix}
          </span>
        </Space>

        <Typography.Link onClick={onClear} className={'arl-header-clear'}>
          清空
        </Typography.Link>
      </Space>

      <div className={'arl-content'}>
        {dataSource?.length ? (
          <List
            grid={{ gutter: 32, column: 6 }}
            rowKey={'value'}
            dataSource={dataSource}
            renderItem={(item) => (
              <List.Item className={'arl-content-item'}>
                <div title={item.label} className={'arl-content-item-text'}>
                  {item.label}
                </div>

                <AIcon
                  type={'icon-remove'}
                  className={'arl-content-item-remove'}
                  onClick={() => onRemove?.(item.value)}
                />
              </List.Item>
            )}
          />
        ) : (
          <AEmpty className={'arl-content-empty'} />
        )}
      </div>
    </AResultListWrapper>
  )
}
