import { OPERATOR_OPTIONS } from '@/constants'
import { AIcon, AInputNumber } from '@/components'
import { Form, Space, Select, Typography } from 'antd'

import { aiRuleConfigs } from '../ai-rule.shared'
import { FormConditionListWrapper } from './component.styled'

const [min, max] = aiRuleConfigs.ruleConditionLimit

interface FormConditionListProps<T> {
  conditionEntity: new () => T // 条件实体
  conditionOptions: OptionType<string, string>[] // 条件选项
  conditionUnitIndex: string[] // 条件索引对应的单位映射
}

/**
 * @see https://ant.design/components/form-cn/#Form.List
 * @todo 限制规则条件数量
 * @description 表单条件列表组件
 */
export function FormConditionList<T>({
  conditionEntity,
  conditionOptions,
  conditionUnitIndex,
}: FormConditionListProps<T>) {
  // 条件只支持 [小于/大于] 操作符
  const options = OPERATOR_OPTIONS.filter((item) => !Reflect.has(item, 'required'))

  return (
    <Form.Item label={'满足条件'} wrapperCol={{ span: 20 }}>
      <Form.List name="conditionList">
        {(conditionList, { add, remove }) => {
          return (
            <FormConditionListWrapper size={16} direction={'vertical'}>
              {conditionList.map((condition, index) => {
                const canShowAdd = conditionList.length < max // 小于都展示
                const canShowDel = conditionList.length > min // 大于都展示
                const canShowExtra = index !== 0

                return (
                  <div key={condition.key} className={'item'}>
                    {canShowExtra && <span className="item-and">且</span>}

                    <Space>
                      <Form.Item noStyle name={[condition.name, 'field']}>
                        <Select style={{ width: '140px' }} options={conditionOptions} />
                      </Form.Item>
                      <Form.Item noStyle name={[condition.name, 'operator']}>
                        <Select style={{ width: '80px' }} options={options} />
                      </Form.Item>
                      <Form.Item noStyle name={[condition.name, 'value']}>
                        {/* 需要将单位作为依赖设置成 key，否则不能动态设置 formatter（此功能官方已实现但未合并发版） */}
                        {/* https://github.com/react-component/input-number/pull/347 */}
                        {/* https://github.com/ant-design/ant-design/issues/31431#issuecomment-1094588848 */}
                        <AInputNumber<number>
                          min={0}
                          key={conditionUnitIndex[index]}
                          style={{ width: '100px' }}
                          parser={(value) => Number(value?.replace(conditionUnitIndex[index], '')) ?? 0}
                          formatter={(value) => `${value}${conditionUnitIndex[index]}`}
                        />
                      </Form.Item>

                      {/* [添加/移除] 条件 */}
                      <Space>
                        {canShowDel && (
                          <Typography.Link title={'移除'} onClick={() => remove(index)}>
                            <AIcon type={'icon-delete'} />
                          </Typography.Link>
                        )}
                        {canShowAdd && (
                          <Typography.Link title={'添加'} onClick={() => add(new conditionEntity(), index + 1)}>
                            <AIcon type={'icon-add-bordered'} />
                          </Typography.Link>
                        )}
                      </Space>
                    </Space>
                  </div>
                )
              })}
            </FormConditionListWrapper>
          )
        }}
      </Form.List>
    </Form.Item>
  )
}
