import { useCb } from '@/hooks'
import { fetcher } from '@/utils'
import { DefaultOptionType } from 'antd/es/select'
import { useState, useEffect, useMemo } from 'react'

export class HolderAppResult {
  appId!: number // 应用 Id
  appName!: string // 应用名称
  appCode!: string // 应用代码
  osType!: 0 | 1 | 2 // 平台类型 [ 0 - 不限 | 1 - iOS | 2 - Android ]
  status?: number
  companyId?: number
  packageName?: string
  departmentId?: number

  static API = '/io/app/selector.do'
}

export function useHolderAppService() {
  const [holderAppLoading, setHolderAppLoading] = useState(false)
  const [holderAppOptions, setHolderAppOptions] = useState<DefaultOptionType[]>([])

  const holderAppRequest = useCb(async () => {
    try {
      setHolderAppLoading(true)

      const { content } = await fetcher(HolderAppResult.API)

      const result = (content ?? []) as HolderAppResult[]
      const normalized = result.map((item) => ({ value: item.appId, label: item.appName }))

      setHolderAppOptions(normalized)
    } catch (error) {
      console.error(error)
    } finally {
      setHolderAppLoading(false)
    }
  })

  useEffect(() => {
    holderAppRequest()
  }, [holderAppRequest])

  return useMemo(() => {
    return {
      holderAppLoading,
      holderAppOptions,

      holderAppRequest,
    }
  }, [holderAppLoading, holderAppOptions, holderAppRequest])
}
