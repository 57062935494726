import { AInputNumber } from '@/components'

import { aiRuleConfigs } from '../ai-rule.shared'

interface FormBudgetNaturalProps {
  value?: number
  onChange?: (value: number) => void
}

const min = aiRuleConfigs.ruleBudgetAdjustmentDefaultValue.naturalValue

/**
 * @description 劫持 onChange 事件
 */
export function FormBudgetNatural({ value, onChange }: FormBudgetNaturalProps) {
  return (
    <AInputNumber
      min={min}
      step={min}
      value={value}
      style={{ width: '80px' }}
      precision={0}
      onChange={(value) => onChange?.(value < min ? min : value)}
    />
  )
}
