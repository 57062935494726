import styled from 'styled-components'
import { Col } from 'antd'

export const AFlattenTreeWrapper = styled.div`
  border: 1px solid var(--ai-color-border);
  border-radius: var(--ai-base-border-radius);

  .aft {
    &-header {
      color: var(--ai-color-title);
      height: var(--ai-base-height);
      border-bottom: 1px solid var(--ai-color-border);
      background-color: var(--ai-bgc-fill);
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
    }

    &-footer {
      height: var(--ai-base-height);
      border-top: 1px solid var(--ai-color-border);
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
    }
  }
`

export const AFlattenTreeBoxItemWrapper = styled(Col)<{ size: number; index: number }>`
  width: ${({ size }) => 100 / size}%;
  height: 100%;
  padding: 8px 16px;
  overflow: hidden;
  line-height: calc(var(--ai-base-height) - 16px);
  white-space: nowrap;
  border-left: ${({ index }) => (index === 0 ? 'unset' : '1px solid var(--ai-color-border)')};
  text-overflow: ellipsis;
`
