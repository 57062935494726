import { useCb } from '@/hooks'
import { fetcher } from '@/utils'
import { useState, useEffect, useMemo } from 'react'

export class HolderCounterResult {
  assistAdNum = 0 // 托管广告数
  assistAdGroupNum = 0 // 托管广告组数
  assistAccountNum = 0 // 托管投放账户数

  totalNum = 0 // 策略总数
  executedNum = 0 // 已执行策略数
  notExecutedNum = 0 // 待执行策略数

  static API = '/io/ai-rule/stat-count.do'
}

export function useHolderCounterService() {
  const [holderCounter, setHolderCounter] = useState(() => new HolderCounterResult())
  const [holderCounterLoading, setHolderCounterLoading] = useState(false)

  const holderCounterRequest = useCb(async () => {
    try {
      setHolderCounterLoading(true)

      const { content } = await fetcher(HolderCounterResult.API)

      setHolderCounter(content ?? {})
    } catch (error) {
      console.error(error)
    } finally {
      setHolderCounterLoading(false)
    }
  })

  useEffect(() => {
    holderCounterRequest()
  }, [holderCounterRequest])

  return useMemo(() => {
    return {
      holderCounter,
      holderCounterLoading,

      holderCounterRequest,
    }
  }, [holderCounter, holderCounterLoading, holderCounterRequest])
}
