import { AIcon } from '@/components'
import { useMemo } from 'react'
import { useBind } from '@/hooks'
import { Row, Tooltip, Typography } from 'antd'

import {
  // services
  AiRuleApiResult,
  AiRuleSchemaService,
  AiRuleSchemaSubmitMode,
} from '@/app/Modules/AiRule'

/**
 * @description Ai 规则列表项操作组 - 已选择
 */
export function AiRuleListSelectedItemExtra({ item }: { item: AiRuleApiResult }) {
  const itemRef = useBind(item)
  const { handleUpdateBefore } = AiRuleSchemaService.useInject()

  return useMemo(() => {
    return (
      <Row justify={'end'}>
        <Tooltip title={'编辑'}>
          <Typography.Link onClick={() => handleUpdateBefore(itemRef.current, AiRuleSchemaSubmitMode.UPDATE_LOCAL)}>
            <AIcon type={'icon-edit'} />
          </Typography.Link>
        </Tooltip>
      </Row>
    )
  }, [handleUpdateBefore, itemRef])
}
