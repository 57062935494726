import { List } from 'antd'
import { useMemo } from 'react'

import {
  // types
  type AiRuleIOPropsROMerged,

  // services
  AiRuleApiResult,

  // eventScopes
  AiRuleListSelectedEventScope,

  // components
  AiRuleListSelectedItem,
} from '@/app/Modules/AiRule'

/**
 * @description Ai 规则列表 - 已选择
 */
export function AiRuleListSelected({ value, onChange }: AiRuleIOPropsROMerged) {
  // 监听自定义事件 - 将接收到的值回传给外部
  AiRuleListSelectedEventScope.useListener((val) => {
    if (!val) return

    onChange?.([val, ...value])
  })

  return useMemo(() => {
    // 在头部添加一个 [自定义规则] 卡片
    const mergedDataSource = [new AiRuleApiResult(), ...value]

    return (
      <List
        grid={{ gutter: 12, column: 4 }}
        rowKey={AiRuleApiResult.PRIMARY_KEY}
        dataSource={mergedDataSource}
        renderItem={(item) => <AiRuleListSelectedItem item={item} value={value} onChange={onChange} />}
      />
    )
  }, [onChange, value])
}
