import { APopover } from '@/components'
import { ReactNode } from 'react'
import { Space, Typography } from 'antd'

interface HolderPopoverProps<T> {
  maxRows: number
  dataSource: T[]
  renderItem: (item: T) => ReactNode
  renderItemTitle: (item: T) => string | undefined
}

export function HolderPopover<T>({ maxRows, dataSource, renderItem, renderItemTitle }: HolderPopoverProps<T>) {
  const showCell = dataSource.slice(0, maxRows)

  return (
    <APopover
      placement={'rightTop'}
      overlayInnerStyle={{ width: '280px', fontSize: 'var(--ai-font-size-small)' }}
      content={
        <Space direction={'vertical'} className={'w-100'}>
          {dataSource.map((item, index) => (
            <Typography.Text ellipsis key={index} title={renderItemTitle(item)} className={'w-100'}>
              {renderItem(item)}
            </Typography.Text>
          ))}
        </Space>
      }
    >
      {showCell.map((item, index) => (
        <Typography.Paragraph ellipsis key={index} className={'w-100'} style={{ marginBottom: 0 }}>
          {renderItem(item)}
        </Typography.Paragraph>
      ))}
    </APopover>
  )
}
