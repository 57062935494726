import { useCb } from '@/hooks'
import { useMemo } from 'react'
import { toQuery } from '@/utils'
import { ROUTE_PATHS } from '@/routes'
import { useNavigate } from 'react-router-dom'

export class HolderSearchParams {
  strategyId?: number
  trusteeshipId?: number
  mediumAccountIds?: string
}

export function createSearchParams(initialSearchParams: string) {
  return new URLSearchParams(initialSearchParams)
}

// 该方法可在任意位置调用，而不必限制在 hooks 中
export function getHolderSearchParams(name: keyof HolderSearchParams) {
  const instance = createSearchParams(location.search)
  return instance.get(name)
}

export function useHolderLocationService() {
  const navigate = useNavigate()

  // 携带参数并跳转至托管广告页面
  const handleJumpToHolderAdv = useCb((searchParams: HolderSearchParams) => {
    const instance = createSearchParams(toQuery(searchParams))

    navigate(`${ROUTE_PATHS.HOLDER_ADV}?${instance}`)
  })

  // 通过 navigate 设置的 SearchParams 也需要通过 navigate 来更新
  const updateHolderSearchParams = useCb((name: keyof HolderSearchParams, value?: string | null) => {
    navigate(`?${toQuery({ [name]: value })}`)
  })

  return useMemo(() => {
    return {
      handleJumpToHolderAdv,
      updateHolderSearchParams,
    }
  }, [handleJumpToHolderAdv, updateHolderSearchParams])
}
