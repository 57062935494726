import { fetcher } from '@/utils'
import { useCb, useMergeState } from '@/hooks'
import { useState, useEffect, useMemo } from 'react'

class AdListParams {
  pageId?: number // 页码
  pageSize?: number // 页数
  searchName?: string // 广告组/广告名称查询关键字

  type?: 1 | 2 | 3 // 查询类型 - 1 - 投放账户 | 2 广告组 | 3 广告
  appId?: number // 应用 ID
  mediumId?: number // 媒体 ID
  adGroupId?: string // 广告组 ID - 查询类型为 2 时传递
  mediumAccountIds?: string // 媒体账户 ID - 查询类型为 1 时传递

  constructor(initialParams?: Partial<AdListParams>) {
    this.pageId = initialParams?.pageId ?? 1
    this.pageSize = initialParams?.pageSize ?? 10
    this.searchName = initialParams?.searchName

    this.type = initialParams?.type
    this.appId = initialParams?.appId

    this.mediumId = initialParams?.mediumId ?? 2 // 目前仅支持今日头条

    this.adGroupId = initialParams?.adGroupId
    this.mediumAccountIds = initialParams?.mediumAccountIds
  }
}

class AdListResult {
  id!: number // [投放账户 | 广告组 | 广告] ID
  name!: string // [投放账户 | 广告组 | 广告] 名称
  type!: 1 | 2 | 3 // [1 - 投放账户 | 2 - 广告组 | 3 - 广告] 实体类型
  children?: AdListResult[]

  static API = '/io/ai-rule/v4/query-aver.do'
}

export function useAdListService() {
  const [adTotal, setAdTotal] = useState(0)
  const [adLoading, setAdLoading] = useState(false)
  const [adRecords, setAdRecords] = useState<OptionType[]>([])

  const [adPayload, adPayloadRef, handleAdPayloadChange] = useMergeState(AdListParams)

  const adRequest = useCb(async () => {
    try {
      setAdLoading(true)
      const { content } = await fetcher(AdListResult.API, { payload: adPayloadRef.current })

      const result = (content?.content ?? []) as AdListResult[]

      const normalized = result.map((item) => ({
        value: item.id,
        label: item.name,
        children: (item.children ?? []).map((eachItem) => ({ value: eachItem.id, label: eachItem.name })),
      }))

      setAdTotal(content?.totalRecord ?? 0)
      setAdRecords(normalized)
    } catch (error) {
      console.error(error)
    } finally {
      setAdLoading(false)
    }
  })

  // 请求参数变化后重新拉取数据
  useEffect(() => {
    // 跳过初始化执行
    const { appId, mediumId, mediumAccountIds } = adPayload
    if (!appId || !mediumId || !mediumAccountIds) return

    adRequest()
  }, [adPayload, adRequest])

  return useMemo(() => {
    return {
      adTotal,
      adLoading,
      adPayload,
      adRecords,
      handleAdPayloadChange,
    }
  }, [adLoading, adPayload, adRecords, adTotal, handleAdPayloadChange])
}
