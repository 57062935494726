import { AFlattenTree } from './AFlattenTree'

export function APlayground() {
  return (
    <AFlattenTree
      header={
        <span title={'test'}>
          AFlattenTreeHeaderAFlattenTreeHeaderAFlattenTreeHeaderAFlattenTreeHeaderAFlattenTreeHeaderAFlattenTreeHeaderAFlattenTreeHeaderAFlattenTreeHeaderAFlattenTreeHeaderAFlattenTreeHeaderAFlattenTreeHeaderAFlattenTreeHeader
        </span>
      }
      footer={[
        'AFlattenTreeFooter',
        'AFlattenTreeFooterAFlattenTreeFooterAFlattenTreeFooterAFlattenTreeFooterAFlattenTreeFooterAFlattenTreeFooterAFlattenTreeFooter',
        <div key={'div'}>
          AFlattenTreeFooterAFlattenTreeFooterAFlattenTreeFooterAFlattenTreeFooterAFlattenTreeFooterAFlattenTreeFooter
        </div>,
        <span key={'span'}>
          AFlattenTreeFooterAFlattenTreeFooterAFlattenTreeFooterAFlattenTreeFooterAFlattenTreeFooterAFlattenTreeFooter
        </span>,
      ]}
    />
  )
}
