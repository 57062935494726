import { Input } from 'antd'
import { AIcon } from '@/components'
import { mergeClassNames } from '@/utils'
import { useCallback, useMemo } from 'react'

import {
  // types
  type AiRuleApiParams,

  // services
  AiRuleListService,

  // components
  Toolbar,
  TabsWrapper,
} from '@/app/Modules/AiRule'

/**
 * @description Ai 规则列表 - 工具栏
 */
export function AiRuleListToolbar() {
  const { aiRulePayload, handleAiRulePayloadChange } = AiRuleListService.useInject()

  // 创建元配置
  const createMeta = useCallback(
    (type: AiRuleApiParams['type']) => {
      return {
        onClickHandler: () => handleAiRulePayloadChange({ type, pageId: 1 }),
        activeClassNames: mergeClassNames('item', { active: aiRulePayload.type === type }),
      }
    },
    [aiRulePayload.type, handleAiRulePayloadChange],
  )

  return useMemo(() => {
    const switchMeta = createMeta(2)
    const budgetMeta = createMeta(3)

    return (
      <Toolbar
        title={
          <TabsWrapper size={60}>
            <div className={budgetMeta.activeClassNames} onClick={budgetMeta.onClickHandler}>
              提升预算规则模版
            </div>
            <div className={switchMeta.activeClassNames} onClick={switchMeta.onClickHandler}>
              关闭广告规则模版
            </div>
          </TabsWrapper>
        }
        extra={
          <Input
            allowClear
            style={{ width: '280px' }}
            value={aiRulePayload.searchName}
            suffix={<AIcon type={'icon-search'} className={'icon-search'} />}
            placeholder={'请输入模板名称'}
            onChange={(e) => handleAiRulePayloadChange({ pageId: 1, searchName: e.target.value })}
          />
        }
      />
    )
  }, [aiRulePayload.searchName, createMeta, handleAiRulePayloadChange])
}
