import { useMemo } from 'react'
import { Form, Row, Col, Radio } from 'antd'
import { ARangePicker, ATimePeriodPicker } from '@/components'
import {
  SELECT_AD_TYPE,
  RUNNING_DATE_WAY,
  SelectAdTypeValues,
  TIME_PERIOD_OPTIONS,
  RunningDateWayValues,
  TimePeriodOptionsValues,
} from '@/constants'

import { HolderService } from '../holder.service'
import { HolderSetupSchemaService } from './holder-setup-schema.service'
import { MaWithAd, arrayValidator, shouldUpdateFn } from './holder-setup.shared'
import { SelectAd, SelectAdGroup, SelectApp, SelectMediumAccount } from './components'

export function HolderSetupSchemaBodyStep1() {
  const { holderSetupForm } = HolderSetupSchemaService.useInject()
  const { holderMediumAccountRequest } = HolderService.useInject()

  // watch appId change
  const selectedAppId = Form.useWatch('appId', holderSetupForm)

  return useMemo(() => {
    return (
      <>
        <Form.Item name={'appId'} label={'选择应用'} rules={[{ required: true, message: '请选择应用' }]}>
          <SelectApp
            onChange={(value) => {
              /**
               * 切换应用需要做：
               * 1. 请求新的投放账户数据
               * 2. 重置已选择的投放账户
               */
              holderMediumAccountRequest(value)
              holderSetupForm.resetFields(['mediumAccountIds', 'mergeMaWithAdList'])
            }}
          />
        </Form.Item>

        <Form.Item required label={'托管范围'}>
          <Row align={'middle'} justify={'space-between'} className={'assist-scope'}>
            <Col className={'assist-scope-ma'}>投放账户</Col>
            <Col>
              <Form.Item noStyle name={'mediumAccountIds'} rules={[{ required: true, message: '请选择投放账户' }]}>
                <SelectMediumAccount
                  placeholder={selectedAppId != null ? '请选择投放账户' : '请先选择应用'}
                  onChange={(_, options) => {
                    /**
                     * 选择投放账户需要做：
                     * 1. 为每一个新的投放账户初始化一个存放广告组的容器
                     */
                    const oldMaWithAdList = holderSetupForm.getFieldValue('mergeMaWithAdList') as MaWithAd[]

                    const newMaWithAdList = options.map((option) => {
                      const isInitialized = oldMaWithAdList.find((item) => item.mediumAccountId === option.value)

                      // 若已初始化，则复用，否则初始化一个容器
                      return (
                        isInitialized ?? {
                          selectedList: [],
                          mediumAccountId: option.value,
                          mediumAccountName: option.label,
                        }
                      )
                    })

                    holderSetupForm.setFieldsValue({ mergeMaWithAdList: newMaWithAdList })
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item name={'selectAdType'} label={'广告组/广告'}>
          <Radio.Group
            options={SELECT_AD_TYPE}
            optionType={'button'}
            onChange={() => {
              /**
               * 切换选择广告类型需要：
               * 1. 重置每一个投放账户的广告组容器
               */
              const maWithAdList = holderSetupForm.getFieldValue('mergeMaWithAdList') as MaWithAd[]
              maWithAdList.forEach((item) => (item.selectedList = []))
              holderSetupForm.setFieldsValue({ mergeMaWithAdList: maWithAdList })
            }}
          />
        </Form.Item>

        <Form.Item noStyle shouldUpdate={shouldUpdateFn('selectAdType')}>
          {() => {
            const type = holderSetupForm.getFieldValue('selectAdType') as SelectAdTypeValues

            // 根据 type 的值进行映射
            const renderMap = new Map<SelectAdTypeValues, { text: string; selector: JSX.Element }>()
            renderMap.set(2, { text: '广告组', selector: <SelectAdGroup /> })
            renderMap.set(3, { text: '广告', selector: <SelectAd /> })

            const renderItem = renderMap.get(type)

            return (
              renderItem && (
                <Form.Item
                  name={'mergeMaWithAdList'}
                  validateStatus={'success'}
                  rules={[
                    {
                      validator: (_, value: MaWithAd[]) => {
                        // 找出未选择广告（组）的投放账户
                        const notSelected = value.find((item) => !item.selectedList.length)

                        return notSelected
                          ? Promise.reject(`请至少为 [ ${notSelected.mediumAccountName} ] 选择一个${renderItem.text}`)
                          : Promise.resolve()
                      },
                    },
                  ]}
                >
                  {renderItem.selector}
                </Form.Item>
              )
            )
          }}
        </Form.Item>

        <Row>
          <Col span={8}>
            <Form.Item name={'runningDateWay'} label={'托管日期'}>
              <Radio.Group options={RUNNING_DATE_WAY} optionType={'button'} />
            </Form.Item>
          </Col>

          <Col span={16}>
            <Form.Item noStyle shouldUpdate={shouldUpdateFn('runningDateWay')}>
              {() => {
                const type = holderSetupForm.getFieldValue('runningDateWay') as RunningDateWayValues

                return (
                  type === 1 && (
                    <Form.Item
                      name={'runningDateRange'}
                      label={<>&nbsp;</>} // 需要保证对齐，空字符串不行
                      rules={[{ validator: (_, value) => arrayValidator(value, '请设置日期范围') }]}
                    >
                      <ARangePicker getPopupContainer={(node) => node.parentElement ?? document.body} />
                    </Form.Item>
                  )
                )
              }}
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name={'timePeriodOption'} label={'托管时段'}>
          <Radio.Group options={TIME_PERIOD_OPTIONS} optionType={'button'} />
        </Form.Item>

        <Form.Item noStyle shouldUpdate={shouldUpdateFn('timePeriodOption')}>
          {() => {
            const type = holderSetupForm.getFieldValue('timePeriodOption') as TimePeriodOptionsValues

            return (
              type === 'CUSTOM' && (
                <Form.Item
                  name={'timePeriod'}
                  rules={[{ validator: (_, value: SafeAny[]) => arrayValidator(value, '请至少选择一个时段') }]}
                >
                  <ATimePeriodPicker />
                </Form.Item>
              )
            )
          }}
        </Form.Item>
      </>
    )
  }, [holderMediumAccountRequest, holderSetupForm, selectedAppId])
}
