import { useMemo } from 'react'
import { AInputNumber } from '@/components'
import { Select, Space } from 'antd'
import { OperatorOptionsValues, OPERATOR_OPTIONS } from '@/constants'

export interface BudgetRangeValueProp {
  min: number // 日预算消耗最小值
  max: number // 日预算消耗最大值
  operator: OperatorOptionsValues // 操作符
}

export interface BudgetRangeProps {
  value?: BudgetRangeValueProp
  onChange?: (value: BudgetRangeValueProp) => void
}

const defaultValue: BudgetRangeValueProp = { min: 0, max: 0, operator: 'Range' }

/**
 * @description 日预算范围
 */
export function FormBudgetRange({ value = defaultValue, onChange }: BudgetRangeProps) {
  return useMemo(() => {
    return (
      <Space>
        <Select
          style={{ width: '80px' }}
          value={value.operator}
          options={OPERATOR_OPTIONS}
          onChange={(operator) => onChange?.({ ...value, operator })}
        />

        {['Min', 'Range'].includes(value.operator) && (
          <AInputNumber
            min={0}
            style={{ width: '110px' }}
            value={value.min}
            onChange={(min) => onChange?.({ ...value, min })}
          />
        )}

        {['Range'].includes(value.operator) && <span>~</span>}

        {['Max', 'Range'].includes(value.operator) && (
          <AInputNumber
            min={0}
            style={{ width: '110px' }}
            value={value.max}
            onChange={(max) => onChange?.({ ...value, max })}
          />
        )}

        <span style={{ color: 'var(--ai-color-label)' }}>元</span>
      </Space>
    )
  }, [onChange, value])
}
