import styled from 'styled-components'
import { Space } from 'antd'

export const StrategyNameWrapper = styled(Space)`
  .label {
    color: var(--ai-color-label);
    font-size: var(--ai-font-size-normal);
    font-weight: var(--ai-font-weight-bold);
  }

  .input {
    position: relative;
    background-color: var(--ai-bgc-fill);
    border-bottom-left-radius: unset !important;
    border-bottom-right-radius: unset !important;

    &::after {
      left: 0;
      width: 100%;
      bottom: 0;
      height: 2px;
      content: '';
      position: absolute;
      background-color: var(--ai-color-primary);
    }
  }

  .message {
    color: var(--ai-color-error);
    opacity: 0;
    transition: all 0.3s ease-in-out;

    &.visible {
      opacity: 1;
    }
  }
`
