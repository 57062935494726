import styled from 'styled-components'
import { Button, Layout, Menu, Row } from 'antd'

export const LayoutWrapper = styled(Layout)`
  .ant-layout-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      width: 160px;
      height: 100%;
      text-align: center;

      img {
        width: 126px;
        height: 100%;
        vertical-align: unset;
      }
    }

    .header-main {
      flex: 1; // flex-box - 占满除 Logo 宽度之外的剩余所有宽度
    }
  }

  & > .ant-layout-content {
    padding: 24px;

    /* 最小屏幕比例 1360*768 */
    min-width: 1360px;
    min-height: 768px;
    overflow-x: auto;
    overflow-y: auto;
    overflow-x: overlay;
    overflow-y: overlay;

    .ant-layout {
      border: 1px solid var(--ai-color-border);
      border-radius: var(--ai-base-border-radius);

      .ant-layout-sider {
        padding-left: 16px;
        border-right: 1px solid var(--ai-color-border);
        border-top-left-radius: var(--ai-base-border-radius);
        border-bottom-left-radius: var(--ai-base-border-radius);
      }

      .ant-layout-content {
        height: calc(100vh - 110px);
        overflow-y: auto;
        overflow-y: overlay;
        padding-bottom: 24px;
        scroll-behavior: smooth;
        background-color: var(--ai-base-color);
        border-top-right-radius: var(--ai-base-border-radius);
        border-bottom-right-radius: var(--ai-base-border-radius);
      }
    }
  }
`

export const LayoutFallBackWrapper = styled(Row)`
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
`

export const LayoutBackHomeWrapper = styled(Button)`
  background-color: var(--ai-bgc-selected);
`

export const LayoutSiderMenuWrapper = styled(Menu)`
  margin-top: 40px;
  border-right: none;

  .ant-menu-item {
    font-weight: var(--ai-font-weight-bold);
    border-top-left-radius: var(--ai-base-border-radius);
    border-bottom-left-radius: var(--ai-base-border-radius);

    &::after {
      border-width: 2px;
    }

    &-selected {
      background-color: var(--ai-bgc-selected) !important;
    }
  }
`
