import { useMemo } from 'react'
import { AIcon, ARangePicker } from '@/components'
import { Button, Input, Row, Space } from 'antd'
import {
  // services
  StrategyListService,
  StrategySchemaService,
} from '@/app/Modules'

/**
 * @description 策略列表 - 工具栏
 */
export function StrategyListToolbar() {
  const { handleCreateBefore } = StrategySchemaService.useInject()
  const { strategyPayload, handleStrategyPayloadChange } = StrategyListService.useInject()

  return useMemo(() => {
    return (
      <Row className={'w-100'} justify={'space-between'}>
        <Space>
          <Button type={'primary'} icon={<AIcon type={'icon-add'} />} onClick={handleCreateBefore}>
            新增策略
          </Button>

          <Input
            allowClear
            value={strategyPayload.searchName}
            style={{ width: '330px' }}
            suffix={<AIcon type={'icon-search'} className={'icon-search'} />}
            placeholder={'请输入策略名称'}
            onChange={(e) => handleStrategyPayloadChange({ pageId: 1, searchName: e.target.value })}
          />
        </Space>

        <ARangePicker
          value={[strategyPayload.startDate, strategyPayload.endDate]}
          // @todo: 解决日期的类型
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onChange={(values) => handleStrategyPayloadChange({ pageId: 1, startDate: values[0], endDate: values[1] })}
        />
      </Row>
    )
  }, [
    handleCreateBefore,
    handleStrategyPayloadChange,
    strategyPayload.endDate,
    strategyPayload.searchName,
    strategyPayload.startDate,
  ])
}
