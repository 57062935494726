import { FormStore } from './holder-setup-schema.service'

export interface MaWithAd {
  selectedList: OptionType[]
  mediumAccountId: number
  mediumAccountName: string
}

/* -------------------------------------------------------------------------------------- */

export enum HolderSetupStep {
  ONE = 1,
  TWO = 2,
}

export function arrayValidator<T>(value?: T[], message?: string) {
  return value?.length ? Promise.resolve() : Promise.reject(message)
}

export function shouldUpdateFn(field: keyof FormStore<number[]>) {
  return function (prev: FormStore<number[]>, curr: FormStore<number[]>) {
    return prev[field] !== curr[field]
  }
}
