import { Dayjs } from 'dayjs'
import { SpaceProps } from 'antd'
import { addDays, nowMoment } from '@/utils'
import { ChangedFieldValues } from '@/constants'
import { getHolderSearchParams } from '@/app/Holder'

export type T_IS_ENABLE /* 数据状态 */ = 0 /* 暂停 */ | 1 /* 启用 */
export type T_IS_EXECUTED /* 策略运行状态 */ = 0 /* 待执行 */ | 1 /* 已执行 */

export enum HolderOperType /* 操作类型 */ {
  PAUSE = 0, // 暂停
  ENABLE = 1, // 启用
  DELETE = -1, // 删除
}

export enum HolderOperTargetType /* 操作目标（对象）类型 */ {
  ACCOUNT = 1, // 投放账户
  AD_GROUP = 2, // 广告组
  AD = 3, // 广告
  STRATEGY = 4, // 策略
}

/* --------------------------------------------------------------------------------------------------------------------- */

export const RECORD_SCROLL = { x: 1000, y: 'calc(100vh - 405px)' } as const
export const RECORD_SPACE_PROPS: SpaceProps = { size: 14, className: 'w-100', direction: 'vertical' } as const

export class HolderOperPayload {
  id!: number
  type!: HolderOperTargetType

  static API = '/io/ai-rule/oper/enable.do'
}

export class HolderOperMultiplePayload {
  ids!: string
  type!: HolderOperTargetType
  operType!: HolderOperType

  static API = '/io/ai-rule/oper/status.do'
}

export class HolderOperExecutionPayload {
  ruleLogIds!: string

  static API = '/io/ai-rule/oper/execution.do'
}

export class BasePayload {
  searchName?: string

  pageId?: number
  pageSize?: number

  startDate: Dayjs | null
  endDate: Dayjs | null

  startLastRunningTime: Dayjs | null
  endLastRunningTime: Dayjs | null

  // 可自定义默认的参数值
  constructor(initialPayload?: Partial<BasePayload>) {
    // 默认第 1 页，每页 10 条
    this.pageId = initialPayload?.pageId ?? 1
    this.pageSize = initialPayload?.pageSize ?? 10

    // 默认近 180 天
    this.startDate = initialPayload?.startDate ?? addDays(-179)
    this.endDate = initialPayload?.endDate ?? nowMoment()

    // 最近运行时间 - 无默认值
    this.startLastRunningTime = initialPayload?.startLastRunningTime ?? null
    this.endLastRunningTime = initialPayload?.endLastRunningTime ?? null

    // 模糊查询关键字
    this.searchName = initialPayload?.searchName
  }
}

export class HolderPayload extends BasePayload {
  appId?: number // 应用 ID
  createId?: number // 创建人 ID
  isExecuted?: T_IS_EXECUTED // 策略运行状态
  changedField?: ChangedFieldValues // 操作类型
  mediumAccountIds?: string | null // 投放账户 ID 列表

  // 只有点击 [托管范围] 进行跳转时才携带以下参数（静默携带，不会展示给用户）
  adIds?: string | null // 广告 ID 列表
  adGroupIds?: string | null // 广告组 ID 列表
  strategyId?: number | null // 策略 ID
  trusteeshipId?: number | null // 托管 ID

  constructor(initialPayload?: Partial<HolderPayload>) {
    super(initialPayload)

    this.appId = initialPayload?.appId
    this.createId = initialPayload?.createId
    this.isExecuted = initialPayload?.isExecuted
    this.changedField = initialPayload?.changedField

    this.strategyId = initialPayload?.strategyId ?? (getHolderSearchParams('strategyId') as unknown as number)
    this.trusteeshipId = initialPayload?.trusteeshipId ?? (getHolderSearchParams('trusteeshipId') as unknown as number)
    this.mediumAccountIds = initialPayload?.mediumAccountIds ?? getHolderSearchParams('mediumAccountIds')
  }
}
