import { ATag } from '@/components'
import { ReactNode } from 'react'
import { safeParseJSON } from '@/utils'
import { Row, Col, Typography } from 'antd'
import { TEMPLATE_TYPE, BUDGET_CONDITION_OPTIONS, SWITCH_CONDITION_OPTIONS } from '@/constants'

import {
  // types
  type AiRuleIOProps,
  type AiRuleApiResult,

  // services
  toYuan,
  AiRuleIndexEnum,
} from '@/app/Modules/AiRule'
import { ListItemWrapper } from './styled'

export interface ListItemProps extends AiRuleIOProps {
  item: AiRuleApiResult
  ruleIndexEnums: AiRuleIndexEnum[] // 规则指标枚举值
  renderItemExtra?: ReactNode // 列表项额外内容
  renderContentHeader?: ReactNode // 列表项内容顶部
  renderContentFooter?: ReactNode // 列表项内容底部
}

const mergedConditionOptions = [...BUDGET_CONDITION_OPTIONS, ...SWITCH_CONDITION_OPTIONS]

/**
 * @description 通用列表项
 */
export function ListItem({
  item,
  ruleIndexEnums,
  renderItemExtra,
  renderContentHeader,
  renderContentFooter,
}: ListItemProps) {
  // 获取规则模板类型
  const ruleTemplateType = TEMPLATE_TYPE.find((eachItem) => eachItem.value === item.templateType)

  // 解析条件文本
  const parsedCondition = safeParseJSON(item.customRuleJson) as RecordType<number>

  const normalized = Object.keys(parsedCondition)
    .map((key) => {
      // 找到对应的枚举值描述
      const rule = ruleIndexEnums.find((item) => [item.minKey, item.maxKey].includes(key))

      let minText = ''
      let maxText = ''
      let conditionField: string

      if (key.endsWith('Min')) {
        const [field] = key.split('Min')

        minText = '>'
        conditionField = field
      }

      if (key.endsWith('Max')) {
        const [field] = key.split('Max')

        maxText = '<'
        conditionField = field
      }

      const conditionValue = parsedCondition[key]
      const conditionOption = mergedConditionOptions.find((item) => conditionField === item.value)

      // 如果单位是元则转化成元，否则直接拼接单位（无单位则拼接空字符串）
      const unit = conditionOption?.unit
      const normalizedConditionValue = unit === '元' ? toYuan(conditionValue) : conditionValue

      return rule?.desc && `${rule.desc}${minText}${maxText}${normalizedConditionValue}${unit ?? ''}`
    })
    .filter((item) => Boolean(item)) // 过滤没有对应枚举的字段
    .join('且') // 以且的关系拼接

  return (
    <ListItemWrapper>
      <div className={'item-header'}>
        <ATag color={ruleTemplateType?.color}>{ruleTemplateType?.text}</ATag>
        <span title={item.ruleName} className={'item-header-title'}>
          {item.ruleName}
        </span>
      </div>

      <div className={'item-content'}>
        {renderContentHeader}

        <Row>
          <Col span={8}>条件：</Col>
          <Col span={16}>
            <Typography.Paragraph title={normalized} ellipsis={{ rows: 2 }} className={'item-content-condition'}>
              {normalized}
            </Typography.Paragraph>
          </Col>
        </Row>

        {renderContentFooter}
      </div>

      {renderItemExtra}
    </ListItemWrapper>
  )
}
