import { ModuleIOProps } from '@/app/Modules'

import { StrategyApiResult } from './strategy-list.service'

export type StrategyIOProps = ModuleIOProps<StrategyApiResult> // 策略模块输入输出接口
export type StrategyConfigs = {
  strategyNameLengthRange: readonly [number, number]
  strategySelectLimitRange: readonly [number, number]
}

/**
 * @description 策略统一配置
 * @param strategyNameLengthRange 策略名称长度范围
 * @param strategySelectLimitRange 策略选择限制范围
 */
export const strategyConfigs = {
  strategyNameLengthRange: [0, 100],
  strategySelectLimitRange: [0, 100],
} as const

/**
 * @description 提交模式
 */
export enum StrategySchemaSubmitMode {
  CREATE = 'CREATE', // 创建策略
  UPDATE = 'UPDATE', // 更新策略
}
