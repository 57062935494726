import { Layout } from 'antd'
import { AppService } from '@/app/app.service'
import { ROUTE_PATHS } from '@/routes'
import { lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { HolderService, HolderEventScope, HolderSetupSchema, HolderSetupSchemaService } from '@/app/Holder'

import { LayoutFallBack } from './LayoutFallBack'
import { LayoutSiderMenu } from './LayoutSiderMenu'

const HolderAdv = lazy(() => import('@/app/Holder/HolderAdv'))
const HolderStrategy = lazy(() => import('@/app/Holder/HolderStrategy'))

export function LayoutContent() {
  const { loading } = AppService.useInject()

  if (loading) {
    return <LayoutFallBack description={'正在获取登录信息，请稍后 ......'} />
  } else {
    return (
      <Layout>
        <Layout.Sider width={160}>
          <LayoutSiderMenu />
        </Layout.Sider>

        <Layout.Content>
          <HolderSetupSchemaService.Provider>
            <HolderService.Provider>
              <HolderEventScope.EventScope>
                <Suspense fallback={<LayoutFallBack description={'页面正在加载中，请稍后 ......'} />}>
                  <Routes>
                    <Route path={ROUTE_PATHS.ROOT} element={<Navigate to={ROUTE_PATHS.HOLDER_ADV} />} />
                    <Route path={ROUTE_PATHS.HOLDER_ADV} element={<HolderAdv />} />
                    <Route path={ROUTE_PATHS.HOLDER_STRATEGY} element={<HolderStrategy />} />
                  </Routes>
                </Suspense>

                <HolderSetupSchema />
              </HolderEventScope.EventScope>
            </HolderService.Provider>
          </HolderSetupSchemaService.Provider>
        </Layout.Content>
      </Layout>
    )
  }
}
