import { Row } from 'antd'
import { ReactNode } from 'react'

import { AFlattenTreeBoxItemWrapper } from './styled'

export interface AFlattenTreeBoxProps {
  box: ReactNode | ReactNode[]
  className?: string
}

export function AFlattenTreeBox({ box, className }: AFlattenTreeBoxProps) {
  const normalized = Array.isArray(box) ? box : [box]

  return (
    <Row align={'middle'} justify={'space-between'} className={className}>
      {normalized.map((item, idx) => {
        return (
          <AFlattenTreeBoxItemWrapper key={idx} size={normalized.length} index={idx}>
            {item}
          </AFlattenTreeBoxItemWrapper>
        )
      })}
    </Row>
  )
}
