import { ATable } from '@/components'
import { useMemo } from 'react'
import { AutoExecutionValues } from '@/constants'
import { HolderRenderRuleContent } from '@/app/Holder'
import { message, Switch, TablePaginationConfig } from 'antd'
import {
  // types
  type StrategyIOProps,

  // services
  strategyConfigs,
  StrategyApiResult,
  StrategyListService,
  StrategyListEventScope,
} from '@/app/Modules'

const [, maxLimit] = strategyConfigs.strategySelectLimitRange
const defaultValue: StrategyApiResult[] = []

/**
 * @description 策略列表
 */
export function StrategyList({ value = defaultValue, onChange }: StrategyIOProps) {
  const {
    strategyList,
    strategyTotal,
    strategyLoading,
    strategyPayload,
    strategyRequest,
    handleStrategyPayloadChange,
  } = StrategyListService.useInject()

  StrategyListEventScope.useListener((strategy) => {
    // 刷新数据
    strategyRequest()

    if (value.length >= maxLimit) return message.info('策略已达到最大选择限制，无法为你自动选中 ~')

    // 自动选中
    strategy && onChange?.([strategy, ...value])
  })

  const paginationProps = useMemo((): TablePaginationConfig => {
    return {
      total: strategyTotal,
      current: strategyPayload.pageId,
      pageSize: strategyPayload.pageSize,
      onChange: (pageId, pageSize) => handleStrategyPayloadChange({ pageId, pageSize }),
    }
  }, [handleStrategyPayloadChange, strategyPayload.pageId, strategyPayload.pageSize, strategyTotal])

  return useMemo(() => {
    return (
      <ATable<StrategyApiResult>
        size={'small'}
        rowKey={StrategyApiResult.PRIMARY_KEY}
        scroll={{ y: 'calc(100vh - 500px)' }}
        loading={strategyLoading}
        dataSource={strategyList}
        pagination={paginationProps}
        rowSelection={{
          value,
          onChange: (value) => onChange?.(value),
          selectionRange: { limit: maxLimit, message: '策略已达到最大选择限制 ~' },
        }}
        columns={[
          {
            title: '自动执行',
            width: 140,
            align: 'center',
            dataIndex: 'autoExecution',
            render: (text, record) => (
              <Switch
                checked={Boolean(text)}
                onChange={(checked, e) => {
                  e.stopPropagation()

                  const target = strategyList.find((item) => item.strategyId === record.strategyId)
                  if (!target) return

                  // 设置一个属性，标识是否自动执行策略
                  target.autoExecution = Number(checked) as AutoExecutionValues
                  onChange?.([...value])
                }}
              />
            ),
          },
          { title: '策略名称', dataIndex: 'strategyName', width: 300, ellipsis: true },
          { title: '策略内容', dataIndex: 'ruleContent', render: HolderRenderRuleContent },
          // { title: '调整频率', dataIndex: 'daysTimes', width: 200, align: 'center' },
          { title: '创建时间', dataIndex: 'createTime', width: 200, align: 'center' },
        ]}
      />
    )
  }, [onChange, paginationProps, strategyList, strategyLoading, value])
}
