/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { timeList, SCHEDULE_SHEET } from './constants'

// 根据开始与结束格标记计算选中区域的所有格子集合
export const calculateSelected = (start, end) => {
  // 开始与结束格一致，即选中当前格
  if (start.trKey === end.trKey && start.tdKey === end.tdKey) {
    return [start]
  }
  // 根据开始与结束格所在行大小，获取选中格所有行跨度
  const trKeyArray = []
  if (start.trKey < end.trKey) {
    for (let i = start.trKey; i <= end.trKey; i++) {
      trKeyArray.push(i)
    }
  } else {
    for (let i = end.trKey; i <= start.trKey; i++) {
      trKeyArray.push(i)
    }
  }
  // 根据开始与结束格所在列大小，获取选中格所有列跨度
  const tdKeyArray = []
  if (start.tdKey < end.tdKey) {
    for (let i = start.tdKey; i <= end.tdKey; i++) {
      tdKeyArray.push(i)
    }
  } else {
    for (let i = end.tdKey; i <= start.tdKey; i++) {
      tdKeyArray.push(i)
    }
  }
  // 行与列进行排列组合，得出所有选中的格集合
  const tempSelected = []
  trKeyArray.forEach((tr) => {
    tdKeyArray.forEach((td) => {
      tempSelected.push({ trKey: tr, tdKey: td })
    })
  })

  return tempSelected
}

// 根据时段数组特殊处理成需要显示的样式，例如：星期一的时段全选，则显示为 00:00~24:00
const calculateTimeName = (data) => {
  if (data.length === timeList.length) {
    return '00:00~24:00'
  }
  const dataArray = [...data]
  const result = []
  let tmp = []
  dataArray
    .sort((pre, cur) => {
      return pre - cur
    })
    .concat(Infinity)
    .reduce((pre, cur) => {
      tmp.push(pre)
      if (cur - pre > 1) {
        result.push(tmp)
        tmp = []
      }
      return cur
    })

  const resultTxt = result.map((res) => {
    if (res.length > 1) {
      const start = ((timeList.find((item) => item.key === res[0]) || {}).name || '').split('~')
      const end = ((timeList.find((item) => item.key === res[res.length - 1]) || {}).name || '').split('~')
      const startTxt = start.length > 0 ? start[0] : ''
      const endTxt = end.length > 1 ? `~${end[1]}` : ''
      return startTxt + endTxt
    }
    return (timeList.find((item) => item.key === res[0]) || {}).name || ''
  })

  return resultTxt.join('、')
}

// 计算投放时段选择结果的显示集合 calculate scheduleTime
export const CALCULATE_SCHEDULE_TIME = (data: SafeAny[]) => {
  const resultArray = []
  if (!data || data.length <= 0) {
    return resultArray
  }

  const sorted = data.sort((a, b) => a.trKey - b.trKey)

  const weekArray = [...new Set(sorted.map((item) => item.trKey))]
  weekArray.forEach((week) => {
    const timeDataArray = sorted.filter((item) => item.trKey === week).map((item) => item.tdKey)
    resultArray.push({
      key: week,
      weekName: (SCHEDULE_SHEET.find((item) => item.key === week) || {}).name,
      timeName: calculateTimeName(timeDataArray),
    })
  })
  return resultArray
}

/**
 * 将scheduleTime转化为格子对象
 * scheduleTime：001100100100...
 * 单元格：{ trKey: 1, tdKey: 20 }...
 **/
export const scheduleTimeToArray = (scheduleTimeStr = '') => {
  if (!scheduleTimeStr) {
    return []
  }
  const scheduleTimeArray = scheduleTimeStr.split('')
  const scheduleTimeResult = []
  scheduleTimeArray.forEach((schedule, index) => {
    if (schedule === '1') {
      scheduleTimeResult.push({
        trKey: Math.floor(index / 48) + 1,
        tdKey: (index % 48) + 1,
      })
    }
  })
  return scheduleTimeResult
}
