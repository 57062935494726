import { message } from 'antd'
import { cloneDeep } from 'lodash-es'
import { useMemo, useState } from 'react'
import { createService, useCb, useMergeState } from '@/hooks'
import { AutoExecutionValues, ChangedFieldValues } from '@/constants'
import { fetcher, recalculateStrLength, safeParseJSON } from '@/utils'
import { aiRuleConfigs, AiRuleApiResult, AiRuleApiBudgetSchema, AiRuleApiSwitchSchema } from '@/app/Modules'

import { StrategyApiResult } from './strategy-list.service'
import { createErrorInstance, createNowDateTime } from '../shared'
import { strategyConfigs, StrategySchemaSubmitMode } from './strategy.shared'
import { StrategyApiCreateSchema, StrategyApiSearchRecord, StrategyApiUpdateSchema } from './strategy.api'

const [min] = aiRuleConfigs.ruleTriggerFrequencyRange
const [minLength, maxLength] = strategyConfigs.strategyNameLengthRange

/**
 * @description 策略模型防腐层 - Anti-Corruption Layer
 */
export class StrategyApiSchemaACL {
  strategyId: number // 策略 ID
  strategyName: string // 策略名称
  switchDayTimes: number // 关闭次数上限（次/天）
  budgetDayTimes: number // 提升次数上限（次/天）
  autoExecutionControl: boolean // 是否自动执行

  constructor() {
    const { date, time } = createNowDateTime()

    this.strategyId = 0
    this.strategyName = `策略_${date}_${time}`
    this.switchDayTimes = min
    this.budgetDayTimes = min
    this.autoExecutionControl = false
  }

  /**
   * @description 查询指定策略
   * @param strategyId 策略 ID
   */
  static async search(strategyId: number) {
    const { content } = await fetcher(`/io/ai-rule/rules/query.do`, { payload: { strategyId } })

    return this.toFormStore(content)
  }

  /**
   * @description 创建一个策略
   * @param values 策略表单域对象
   * @returns
   */
  static create(values: StrategyFormStore) {
    const schema = this.toCreateSchema(values)

    return fetcher(`/io/ai-rule/strategy/add.do`, { method: 'post', payload: schema })
  }

  /**
   * @description 更新一个策略
   * @param values 策略表单域对象
   * @returns
   */
  static update(values: StrategyFormStore) {
    const schema = this.toUpdateSchema(values)

    return fetcher(`/io/ai-rule/strategy/edit.do`, { method: 'post', payload: schema })
  }

  /**
   * @description 将查询结果转换到表单域
   * @param record 策略
   * @returns
   */
  static toFormStore(record: StrategyApiSearchRecord): Partial<StrategyFormStore> {
    const { rules, strategyId, strategyName, daysTimesSwitch, daysTimesBudget } = record

    return {
      strategyId,
      strategyName,
      selectedRules: rules,
      switchDayTimes: daysTimesSwitch,
      budgetDayTimes: daysTimesBudget,
      cachedRuleIdList: rules.map((item) => item.id),
    }
  }

  /**
   * @description 转换到创建策略接口所需要的数据结构
   * @param values 策略表单域对象
   * @returns
   */
  static toCreateSchema(values: StrategyFormStore) {
    const cloned = cloneDeep(values)
    const schema: StrategyApiCreateSchema = {}

    const normalizedAiRuleSwitch = this.normalAiRule<AiRuleApiSwitchSchema>(2, cloned.selectedRules)
    const normalizedAiRuleBudget = this.normalAiRule<AiRuleApiBudgetSchema>(3, cloned.selectedRules)

    schema.strategyName = cloned.strategyName
    schema.targetSwitchs = normalizedAiRuleSwitch
    schema.targetBudgets = normalizedAiRuleBudget
    schema.autoExecution = Number(cloned.autoExecutionControl) as AutoExecutionValues
    schema.daysTimesSwitch = cloned.switchDayTimes
    schema.daysTimesBudget = cloned.budgetDayTimes

    return schema
  }

  /**
   * @description 转换到更新策略接口所需要的数据结构
   * @param values 策略表单域对象
   * @returns
   */
  static toUpdateSchema(values: StrategyFormStore) {
    const cloned = cloneDeep(values)
    const schema: StrategyApiUpdateSchema = {}

    // 注意：新的规则需要移除 id - 原有的规则 ID 已被缓存
    const selectedRules = cloned.selectedRules.map((item) => {
      // 不在缓存中
      if (!cloned.cachedRuleIdList.includes(item.id)) {
        item.id = undefined as unknown as number
      }

      return item
    })

    const normalizedAiRuleSwitch = this.normalAiRule<AiRuleApiSwitchSchema>(2, selectedRules)
    const normalizedAiRuleBudget = this.normalAiRule<AiRuleApiBudgetSchema>(3, selectedRules)

    schema.strategyId = cloned.strategyId
    schema.strategyName = cloned.strategyName
    schema.targetSwitchs = normalizedAiRuleSwitch
    schema.targetBudgets = normalizedAiRuleBudget
    schema.autoExecution = Number(cloned.autoExecutionControl) as AutoExecutionValues
    schema.daysTimesSwitch = cloned.switchDayTimes
    schema.daysTimesBudget = cloned.budgetDayTimes

    return schema
  }

  /**
   * @description 提取指定类型的规则
   * @param option 规则类型
   * @param value 已选择的规则
   * @returns
   */
  static normalAiRule<T>(option: ChangedFieldValues, value: AiRuleApiResult[] = []): T[] {
    const aiRuleList = value.filter((item) => item.type === option)

    switch (option) {
      case 2: {
        const normalized = aiRuleList.map((item) => {
          return {
            ...safeParseJSON(item.customRuleJson),

            id: item.id,
            scene: item.sceneSwitch,
            operate: Number(item.execution),
            costType: item.costType,
            ruleName: item.ruleName,
          }
        })

        return normalized as unknown as T[]
      }

      case 3: {
        const normalized = aiRuleList.map((item) => {
          return {
            ...safeParseJSON(item.customRuleJson),

            id: item.id,
            ruleName: item.ruleName,
            budgetMin: item.budgetMin,
            budgetMax: item.budgetMax,
            multiples: Number(item.execution),
            multiplesType: item.executionType,
          }
        })

        return normalized as unknown as T[]
      }

      default:
        throw createErrorInstance('ChangedFieldValues', option)
    }
  }
}

/**
 * @description 策略模型表单域
 */
export class StrategyFormStore extends StrategyApiSchemaACL {
  submitMode: StrategySchemaSubmitMode // 提交模式
  selectedRules: AiRuleApiResult[] // 已选择的规则
  cachedRuleIdList: number[] // 缓存查询出来的规则 ID - 编辑策略时使用

  constructor() {
    super()

    this.submitMode = StrategySchemaSubmitMode.CREATE
    this.selectedRules = []
    this.cachedRuleIdList = []
  }

  /**
   * @description 表单提交
   * @param values 策略表单域对象
   * @returns
   */
  static submit(values: StrategyFormStore) {
    const { submitMode } = values

    switch (submitMode) {
      // 创建模式
      case StrategySchemaSubmitMode.CREATE:
        return StrategyApiSchemaACL.create(values)

      // 更新模式
      case StrategySchemaSubmitMode.UPDATE:
        return StrategyApiSchemaACL.update(values)

      default:
        throw new Error(`前端 [StrategySchemaSubmitMode] 传值错误: ${submitMode}，请检查代码~`)
    }
  }
}

/**
 * @description 策略模型服务
 */
export function useStrategySchemaService() {
  const [strategySchemaLoading, setStrategySchemaLoading] = useState(false)
  const [strategySchemaVisible, setStrategySchemaVisible] = useState(false)
  const [strategySchema, strategySchemaRef, setStrategySchema] = useMergeState(StrategyFormStore)

  // 策略保存
  const handleStrategySave = useCb(async (onSuccess: (content?: StrategyApiResult) => void) => {
    try {
      setStrategySchemaLoading(true)

      const { strategyName, selectedRules } = strategySchemaRef.current

      // 未选择规则
      if (selectedRules.length === 0) return message.info('请至少选择一个规则模板')

      // 长度不合理
      const length = recalculateStrLength(strategyName)
      if (length <= minLength || length > maxLength) return

      const { content, succeeded } = await StrategyFormStore.submit(strategySchemaRef.current)

      if (succeeded) {
        message.success('保存成功 ~')

        // 重置状态 & 调用成功回调（同时将返回的实体回传） & 关闭抽屉
        onSuccess(content)
        setStrategySchema(new StrategyFormStore())
        setStrategySchemaVisible(false)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setStrategySchemaLoading(false)
    }
  })

  // 创建之前
  const handleCreateBefore = useCb(() => {
    setStrategySchema({ submitMode: StrategySchemaSubmitMode.CREATE })
    setStrategySchemaVisible(true)
  })

  // 更新之前
  const handleUpdateBefore = useCb(async (strategyId: number) => {
    try {
      // 查询指定策略详情
      const strategy = await StrategyApiSchemaACL.search(strategyId)

      setStrategySchema({ submitMode: StrategySchemaSubmitMode.UPDATE, ...strategy })
      setStrategySchemaVisible(true)
    } catch (error) {
      console.error(error)
    }
  })

  return useMemo(() => {
    return {
      strategySchema,
      strategySchemaLoading,
      strategySchemaVisible,

      setStrategySchema,
      handleStrategySave,
      handleCreateBefore,
      handleUpdateBefore,
      setStrategySchemaVisible,
    }
  }, [
    handleCreateBefore,
    handleStrategySave,
    handleUpdateBefore,
    setStrategySchema,
    strategySchema,
    strategySchemaLoading,
    strategySchemaVisible,
  ])
}

export const StrategySchemaService = createService(useStrategySchemaService)
