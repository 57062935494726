import styled from 'styled-components'
import { Space, Tabs } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { PropsWithChildren, ReactNode } from 'react'
import { AIcon, AIconType, ANavTabsStyle } from '@/components'

interface HolderTabsItem<T> {
  value: T
  label: ReactNode
  children: ReactNode
  forceRender?: boolean
}

interface HolderTabsProps<T> {
  extra: ReactNode
  items: HolderTabsItem<T>[]
  value: T
  onChange: (value: T) => void
}

interface HolderTabsItemProps {
  text: string
  icon: AIconType
  loading: boolean
}

const HolderTabsWrapper = styled(Tabs)`
  ${ANavTabsStyle}
`

function Item({ text, icon, loading, children }: PropsWithChildren<HolderTabsItemProps>) {
  return (
    <Space>
      <AIcon type={icon} />
      <span>{text}</span>
      {loading ? <LoadingOutlined /> : children}
    </Space>
  )
}

export function HolderTabs<T extends string>({ extra, items, value, onChange }: HolderTabsProps<T>) {
  return (
    <HolderTabsWrapper
      size={'large'}
      activeKey={value}
      tabBarGutter={64}
      tabBarExtraContent={extra}
      onChange={(activeKey) => onChange(activeKey as T)}
    >
      {items.map((item) => {
        // 需要强制渲染，因为需要初始化事件
        return (
          <Tabs.TabPane key={item.value} tab={item.label} forceRender={item.forceRender}>
            {item.children}
          </Tabs.TabPane>
        )
      })}
    </HolderTabsWrapper>
  )
}

HolderTabs.Item = Item
