import styled, { css } from 'styled-components'

const border = '1px solid var(--ai-color-border)'

// 投放账户
export const AFreeListWrapperMa = styled.div`
  .afl {
    width: 180px;
    border-top: ${border};
    border-left: ${border};
    border-bottom: ${border};
    border-top-left-radius: var(--ai-base-border-radius);
    border-bottom-left-radius: var(--ai-base-border-radius);

    &-header {
      border-right: ${border};
    }

    &-content {
      background-color: var(--ai-bgc-fill);
      border-bottom-left-radius: var(--ai-base-border-radius);

      &-item {
        cursor: pointer;
        padding: 0;

        .item {
          padding: 0 16px;
          transition: all 0.3s ease-in-out;

          &:hover,
          &.active {
            color: var(--ai-color-primary);
            background-color: var(--ai-base-color);
          }

          &-icon-dot {
            color: var(--ai-color-error);
            margin-right: 4px;

            &.hidden {
              color: transparent;
            }
          }
        }
      }
    }
  }
`

// 控制是否显示圆角
type Styled = { hiddenRadius?: boolean }
const borderRadius = css<Styled>`
  ${(props) => (props.hiddenRadius ? 'unset' : 'var(--ai-base-border-radius)')}
`

// 广告（组）
export const AFreeListWrapperAd = styled.div<Styled>`
  .afl {
    width: 310px;
    border-top: ${border};
    border-right: ${border};
    border-bottom: ${border};
    border-top-right-radius: ${borderRadius};
    border-bottom-right-radius: ${borderRadius};

    &-content {
      border-bottom-right-radius: ${borderRadius};

      &-item {
        padding: 0;

        .item {
          cursor: pointer;
          padding: 0 16px;

          &-selected {
            background-color: var(--ai-bgc-selected);
          }
        }
      }
    }
  }
`

// 选择结果（Rs = Result）
export const AFreeListWrapperRs = styled.div`
  .afl {
    width: 310px;
    margin-left: 16px;

    .extra-clear {
      font-weight: var(--ai-font-weight-bold);
    }

    &-content {
      &-item {
        height: unset;
      }

      .ad-group-item {
        height: unset !important;
      }

      .item-title {
        font-weight: var(--ai-font-weight-medium);
      }

      .item-label {
        color: var(--ai-color-text);
      }

      .item-icon-remove,
      .item-icon-trigger {
        cursor: pointer;
        opacity: 0.75;
        transition: all 0.3s ease-in-out;

        &:hover {
          opacity: 1;
        }
      }

      .item-icon-remove {
        color: var(--ai-color-compare);
        margin-right: 8px;
      }

      .item-icon-trigger {
        color: var(--ai-color-icon);
        margin-left: 8px;
      }
    }
  }
`
