import { useMemo } from 'react'
import { AResultList } from '@/components'
import {
  // types
  type StrategyIOProps,
  type StrategyApiResult,

  // services
  strategyConfigs,
} from '@/app/Modules'

const [, maxLimit] = strategyConfigs.strategySelectLimitRange
const defaultValue: StrategyApiResult[] = []

/**
 * @description 策略列表 - 已选择
 */
export function StrategyListSelected({ value = defaultValue, onChange }: StrategyIOProps) {
  return useMemo(() => {
    const normalized = value.map((item) => ({ value: item.strategyId, label: item.strategyName ?? '---' }))

    return (
      <AResultList
        title={'已选择策略'}
        interval={[value.length, maxLimit]}
        dataSource={normalized}
        onClear={() => onChange?.([])}
        onRemove={(target) => {
          const filteredStrategyList = value.filter((item) => item.strategyId !== target)

          onChange?.(filteredStrategyList)
        }}
      />
    )
  }, [onChange, value])
}
