import styled from 'styled-components'

export const ATableWrapper = styled.div`
  .striped {
    background-color: var(--ai-bgc-fill);

    td {
      background-color: var(--ai-bgc-fill);
    }
  }

  .ant-table-small {
    .ant-table-thead {
      height: 48px;

      th {
        border: none;
        font-weight: var(--ai-font-weight-bold);

        &::before {
          content: none !important;
        }
      }
    }
  }

  .ant-table-thead {
    .ant-table-cell {
      background-color: var(--ai-bgc-fill);
    }
  }

  .ant-table-tbody {
    .ant-table-cell {
      height: 60px;
      border: none;
      font-weight: var(--ai-font-weight-medium);
    }
  }

  .ant-pagination-options-size-changer {
    text-align: right;
  }
`
