const VIDEO_FILE_TYPES = ['mp4', 'rmvb', 'mov', 'avi', 'ts'] as const
const IMAGE_FILE_TYPES = ['png', 'gif', 'jpg', 'jpeg', 'bmp'] as const

export type VideoFileType = typeof VIDEO_FILE_TYPES[number]
export type ImageFileType = typeof IMAGE_FILE_TYPES[number]

/**
 * @description 判断文件类型是否为视频
 * @param fileType 文件类型
 * @returns
 */
export const isVideo = (fileType: VideoFileType) => VIDEO_FILE_TYPES.includes(fileType)

/**
 * @description 判断文件类型是否为图片
 * @param fileType 文件类型
 * @returns
 */
export const isImage = (fileType: ImageFileType) => IMAGE_FILE_TYPES.includes(fileType)

/**
 * @description 是否为数字类型
 * @param val 要判断的值
 * @returns
 */
export const isNumber = (val: unknown): val is number => typeof val === 'number'

/**
 * @description 是否为字符串类型
 * @param val 要判断的值
 * @returns
 */
export const isString = (val: unknown): val is string => typeof val === 'string'

/**
 * @description 是否为布尔类型
 * @param val 要判断的值
 * @returns
 */
export const isBoolean = (val: unknown): val is boolean => typeof val === 'boolean'

/**
 * @description 是否为函数类型
 * @param val 要判断的值
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const isFunction = (val: unknown): val is Function => typeof val === 'function'
