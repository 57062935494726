import { fetcher } from '@/utils'
import { createService } from '@/hooks'
import { useCallback, useEffect, useMemo, useState } from 'react'

/**
 * @description Ai 规则指标枚举映射
 */
export interface AiRuleIndexEnum {
  desc: string // 指标描述
  minKey: string // 指标最小值的 key
  maxKey: string // 指标最大值的 key
}

/**
 * @api /io/ai-rule/rule/index.do
 * @description Ai 规则指标 Api 实例类
 */
export class AiRuleIndexApiInstance {
  budgetEnum: AiRuleIndexEnum[] = [] // 提升预算规则指标
  switchEnum: AiRuleIndexEnum[] = [] // 关闭广告规则指标

  /**
   * @description Ai 规则指标数据请求器
   */
  static request() {
    return fetcher(`/io/ai-rule/rule/index.do`)
  }
}

/**
 * @description Ai 规则指标服务
 */
function useAiRuleIndexService() {
  // 惰性初始化
  const [aiRuleIndex, setAiRuleIndex] = useState(() => new AiRuleIndexApiInstance())

  // 请求规则指标
  const aiRuleIndexRequest = useCallback(async () => {
    try {
      const { content } = await AiRuleIndexApiInstance.request()

      setAiRuleIndex(content ?? {})
    } catch (error) {
      console.error(error)
    }
  }, [])

  // 只需初始化请求一次
  useEffect(() => {
    aiRuleIndexRequest()
  }, [aiRuleIndexRequest])

  return useMemo(() => {
    return {
      aiRuleIndex,
    }
  }, [aiRuleIndex])
}

export const AiRuleIndexService = createService(useAiRuleIndexService)
