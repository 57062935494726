import { useMemo } from 'react'
import { Checkbox } from 'antd'

import {
  // services
  AiRuleSchemaService,
} from '@/app/Modules/AiRule'

/**
 * @description Ai 规则模型 - 额外内容
 */
export function AiRuleSchemaExtra() {
  const { aiRuleSchemaState, setAiRuleSchemaState } = AiRuleSchemaService.useInject()

  return useMemo(() => {
    return (
      <Checkbox
        checked={aiRuleSchemaState.isSaveTemplate}
        onChange={(e) => setAiRuleSchemaState({ isSaveTemplate: e.target.checked })}
      >
        保存为模板
      </Checkbox>
    )
  }, [aiRuleSchemaState.isSaveTemplate, setAiRuleSchemaState])
}
