import { oaDeDuplication } from '@/utils'
import { message as AntdMessage } from 'antd'
import { Key, useMemo, useEffect } from 'react'
import { useBind, useCb, useSelection } from '@/hooks'

import { ATableRowSelection, AntdTableRowSelection } from '../interface'

/**
 * @description 主要解决数据在动态变化下的选取问题
 * @description 1. 扩展表格的选取
 * @description 2. 支持行点击选取
 * @description 3. 支持选择数量限制与提示
 * @param rowKey 数据主键
 * @param rowSelection 选择配置
 * @returns
 */
export function useRowSelection<T extends RecordType<SafeAny>>(rowKey: string, rowSelection?: ATableRowSelection<T>) {
  const rowSelectionRef = useBind(rowSelection)
  const selectionInstance = useSelection<Key>()

  // 统一选择事件
  const handleSelect = useCb((isSelected: boolean, record: T) => {
    if (!rowSelectionRef.current) return

    const { selected, append, remove } = selectionInstance
    const { value, onChange, selectionRange } = rowSelectionRef.current
    const { limit = Infinity, message = '已达到最大选择限制 ~' } = selectionRange ?? {}

    if (isSelected) {
      // 再选择一个就超出限制了
      if (selected.length + 1 > limit) {
        return AntdMessage.info(message)
      }

      append(record[rowKey])
      onChange([...value, record])
    } else {
      remove(record[rowKey])
      onChange(value.filter((item) => item[rowKey] !== record[rowKey]))
    }
  })

  // 行点击事件 - 与 rowSelection.onSelect 相反的逻辑
  const handleRowClick = useCb((record: T) => {
    const isSelected = selectionInstance.hasSelected(record[rowKey])
    handleSelect(!isSelected, record)
  })

  // 传给表格的 rowSelection 属性
  const rowSelectionProps = useMemo((): AntdTableRowSelection<T> | undefined => {
    if (!rowSelectionRef.current) return

    const { value, onChange, selectionRange } = rowSelectionRef.current
    const { selected, appendMultiple, removeMultiple } = selectionInstance

    // set default value
    const { limit = Infinity, message = '已达到最大选择限制 ~' } = selectionRange ?? {}

    return {
      fixed: true,
      columnWidth: 60,
      selectedRowKeys: selected,
      onSelect: (record, isSelected) => handleSelect(isSelected, record),
      onSelectAll: (isSelected, _, records) => {
        if (isSelected) {
          // 刚好达到选择限制 - 提前结束
          if (selected.length === limit) return AntdMessage.info(message)

          // 合并去重
          let normalized = oaDeDuplication(value, records, rowKey)

          // 超出部分需要截去
          if (normalized.length > limit) {
            normalized = normalized.slice(0, limit)
            AntdMessage.info(`${message}（超出部分已被自动截去）`)
          }

          const keyList = normalized.map((item) => item[rowKey])

          appendMultiple(keyList)
          onChange(normalized)
        } else {
          const keyList = records.map((item) => item[rowKey])

          removeMultiple(keyList)
          onChange(value.filter((item) => !keyList.includes(item[rowKey])))
        }
      },
    }
  }, [handleSelect, rowKey, rowSelectionRef, selectionInstance])

  // 当外部值变化后，set 内部值
  const { setSelected } = selectionInstance
  useEffect(() => {
    if (!rowSelection?.value) return
    setSelected(rowSelection.value.map((item) => item[rowKey]))
  }, [rowKey, rowSelection?.value, setSelected])

  return { handleRowClick, rowSelectionProps }
}
