import { Table } from 'antd'
import { useMemo } from 'react'
import { mergeClassNames } from '@/utils'

import { ATableWrapper } from './styled'
import { useRowSelection } from './hooks'
import { AntdTablePaginationConfig, ATableProps } from './interface'

export * from './interface'

/**
 * @description 强制设置 rowKey
 * @description 重写 rowSelection 的类型，内置选择逻辑
 * @description 覆写 Columns 中 dataIndex 的类型
 */
export function ATable<T extends RecordType<SafeAny>>(props: ATableProps<T>) {
  const { rowKey, pagination, rowSelection, ...baseProps } = props

  // 选择器
  const { rowSelectionProps } = useRowSelection(rowKey, rowSelection)

  // 分页器
  const paginationProps = useMemo((): AntdTablePaginationConfig => {
    return {
      size: 'small',
      showTotal: (total) => `共 ${total} 条数据`,
      showQuickJumper: true,
      showSizeChanger: true,
      pageSizeOptions: [10, 20, 50, 100, 200],
      ...pagination,
    }
  }, [pagination])

  return (
    <ATableWrapper>
      <Table<T>
        {...baseProps}
        rowKey={rowKey}
        pagination={paginationProps}
        rowSelection={rowSelectionProps}
        rowClassName={(_, index) => mergeClassNames({ striped: index % 2 !== 0 })!}
      />
    </ATableWrapper>
  )
}
