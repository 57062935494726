import { useCb } from '@/hooks'
import { useMemo } from 'react'
import { Space, Button } from 'antd'
import { HolderEventScope, HolderService } from '@/app/Holder'

import { HolderSetupSchemaService } from './holder-setup-schema.service'

export function HolderSetupSchemaExtraStep2() {
  const holderDispatch = HolderEventScope.useDispatch()
  const { holderCounterRequest } = HolderService.useInject()
  const { holderSetupLoading, handleSubmit, handlePrevStep } = HolderSetupSchemaService.useInject()

  const handleSuccess = useCb(() => {
    holderDispatch() // 更新外部数据（任意一个）
    holderCounterRequest() // 更新 Tab 数据总量
  })

  return useMemo(() => {
    return (
      <Space>
        <Button onClick={handlePrevStep}>上一步</Button>
        <Button type={'primary'} loading={holderSetupLoading} onClick={() => handleSubmit(handleSuccess)}>
          确定
        </Button>
      </Space>
    )
  }, [handlePrevStep, handleSubmit, handleSuccess, holderSetupLoading])
}
