import styled from 'styled-components'
import { Modal } from 'antd'

export const AModalWrapper = styled(Modal)`
  .ant-modal-content {
    border-radius: var(--ai-base-border-radius);
  }

  .ant-modal-header {
    border-bottom: none;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;

    .am-title {
      color: var(--ai-color-title);
      font-weight: var(--ai-font-weight-bold);
    }
  }

  .ant-modal-footer {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;

    /* @todo: 统一设置 large button 的样式 | 次要按钮 */
    button {
      width: 90px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`
