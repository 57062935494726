import { InputNumber, InputNumberProps } from 'antd'

/**
 * @description 劫持 onChange 事件，处理 value 为 null/undefined 的情况
 */
export function AInputNumber<T extends string | number = number>({ onChange, ...baseProps }: InputNumberProps<T>) {
  return (
    <InputNumber
      {...baseProps}
      onChange={(value) => {
        // bad code
        // const newValue = value == null && baseProps.min != null ? baseProps.min : value

        // 如果 value 为 null/undefined 且设置了 min 值，则将 min 值作为新值传回
        const newValue = value ?? baseProps.min ?? value

        onChange?.(newValue)
      }}
    />
  )
}
