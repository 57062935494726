import styled from 'styled-components'
import { Tag } from 'antd'

export const ATagWrapper = styled(Tag)`
  width: 40px;
  border: none;
  padding: 1px 7px;
  text-align: center;
  margin-right: 0 !important;

  .content {
    font-size: var(--ai-font-size-small);
    font-weight: var(--ai-font-weight-medium);
  }
`
