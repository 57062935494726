import { Space } from 'antd'
import { useMemo } from 'react'
import { AIcon, ADrawer } from '@/components'
import { mergeClassNames } from '@/utils'
import { SmallDashOutlined } from '@ant-design/icons'

import { HolderSetupSchemaBody } from './HolderSetupSchemaBody'
import { HolderSetupSchemaExtra } from './HolderSetupSchemaExtra'

import { HolderSetupStep } from './holder-setup.shared'
import { HolderSetupSchemaService } from './holder-setup-schema.service'
import { HolderSetupHeaderExtraWrapper } from './holder-setup.styled'

export function HolderSetupSchema() {
  const { holderSetupStep, holderSetupVisible, handleClose, handleAfterClose } = HolderSetupSchemaService.useInject()

  // 标题文本
  const renderTitle = useMemo(() => {
    switch (holderSetupStep) {
      case HolderSetupStep.ONE:
        return '设置托管'
      case HolderSetupStep.TWO:
        return '设置策略'
      default:
        return 'Unknown HolderSetupStep'
    }
  }, [holderSetupStep])

  // 顶部额外提示
  const renderHeaderExtra = useMemo(() => {
    const isTwo = holderSetupStep === HolderSetupStep.TWO

    const activeClassNames = mergeClassNames({ active: isTwo })
    const finishClassNames = mergeClassNames('active', { finish: isTwo })

    // 进入第二步，变为图标
    const triggerRender = isTwo ? <AIcon type={'icon-check'} /> : HolderSetupStep.ONE

    return (
      <HolderSetupHeaderExtraWrapper justify={'center'}>
        <Space size={12}>
          <Space className={finishClassNames}>
            <div className={'step-number'}>{triggerRender}</div>
            <div className={'step-text'}>设置托管</div>
          </Space>

          <SmallDashOutlined />

          <Space className={activeClassNames}>
            <div className={'step-number'}>{HolderSetupStep.TWO}</div>
            <div className={'step-text'}>设置策略</div>
          </Space>
        </Space>
      </HolderSetupHeaderExtraWrapper>
    )
  }, [holderSetupStep])

  return useMemo(() => {
    return (
      <ADrawer
        destroyOnClose
        width={1200}
        footer={<HolderSetupSchemaExtra />}
        visible={holderSetupVisible}
        renderTitle={renderTitle}
        renderHeaderExtra={renderHeaderExtra}
        onClose={handleClose}
        afterVisibleChange={(visible) => !visible && handleAfterClose()}
      >
        <HolderSetupSchemaBody />
      </ADrawer>
    )
  }, [holderSetupVisible, renderTitle, renderHeaderExtra, handleClose, handleAfterClose])
}
