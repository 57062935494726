import { Space } from 'antd'
import { useMemo } from 'react'
import { HolderEventScope } from '@/app/Holder'
import { ADrawer, AQuestion } from '@/components'
import {
  // services
  StrategyFormStore,
  StrategySchemaService,
  StrategyListEventScope,
  StrategySchemaSubmitMode,

  // components
  StrategySchemaBody,
  StrategySchemaExtra,
} from '@/app/Modules'

const renderHeader = (title: string) => {
  return (
    <Space>
      <span>{title}</span>
      <AQuestion title={'策略等于一个或多个规则的组合'} />
    </Space>
  )
}

/**
 * @description 策略模型
 */
export function StrategySchema() {
  const {
    // states
    strategySchema,
    strategySchemaLoading,
    strategySchemaVisible,

    // setters or handlers
    setStrategySchema,
    handleStrategySave,
    setStrategySchemaVisible,
  } = StrategySchemaService.useInject()
  const holderDispatch = HolderEventScope.useDispatch()
  const strategyListDispatch = StrategyListEventScope.useDispatch()

  const renderModeMap = useMemo(() => {
    switch (strategySchema.submitMode) {
      // 新增策略，需更新策略列表的数据
      case StrategySchemaSubmitMode.CREATE:
        return {
          header: renderHeader('新增策略'),
          onSuccess: strategyListDispatch,
        }

      // 编辑策略，需更新外部的数据
      case StrategySchemaSubmitMode.UPDATE:
        return {
          header: renderHeader('编辑策略'),
          onSuccess: holderDispatch,
        }

      default:
        return {
          header: renderHeader('Unknown'),
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onSuccess: () => {},
        }
    }
  }, [holderDispatch, strategyListDispatch, strategySchema.submitMode])

  return useMemo(() => {
    return (
      <ADrawer
        destroyOnClose
        width={1200}
        visible={strategySchemaVisible}
        okButtonText={'保存'}
        confirmLoading={strategySchemaLoading}
        onOk={() => handleStrategySave(renderModeMap.onSuccess)}
        onClose={() => setStrategySchemaVisible(false)}
        afterVisibleChange={(visible) => !visible && setStrategySchema(new StrategyFormStore())}
        renderTitle={renderModeMap.header}
        renderFooterExtra={<StrategySchemaExtra />}
      >
        <StrategySchemaBody />
      </ADrawer>
    )
  }, [
    handleStrategySave,
    renderModeMap.header,
    renderModeMap.onSuccess,
    setStrategySchema,
    setStrategySchemaVisible,
    strategySchemaLoading,
    strategySchemaVisible,
  ])
}
