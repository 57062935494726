import Logo from '@/images/logo.png'
import { Layout } from 'antd'
import { AppService } from '@/app/app.service'
import { BrowserRouter } from 'react-router-dom'

import { LayoutWrapper } from './layout.styled'
import { LayoutContent } from './LayoutContent'
import { LayoutHeaderExtra } from './LayoutHeaderExtra'

const info = 'ADIO | I 投 | 新数时代'

const basename = import.meta.env.VITE_BASE_NAME as string

export function ALayout() {
  return (
    <AppService.Provider>
      <LayoutWrapper>
        <BrowserRouter basename={basename}>
          <Layout.Header>
            <div className="logo">
              <img src={Logo} alt={info} title={info} />
            </div>

            <div className="header-main">
              <LayoutHeaderExtra />
            </div>
          </Layout.Header>

          <Layout.Content>
            <LayoutContent />
          </Layout.Content>
        </BrowserRouter>
      </LayoutWrapper>
    </AppService.Provider>
  )
}
