import { useMemo } from 'react'
import { Form, Radio, Select, Space } from 'antd'
import { SCENE_OPTIONS, OPERATE_OPTIONS, SCENE_CASCADE_OPTIONS, SWITCH_CONDITION_OPTIONS } from '@/constants'

import {
  // types
  type CostRangeValueProp,
  type SceneCascadeOptions,

  // services
  aiRuleConfigs,
  AiRuleSchemaService,
  AiRuleSwitchCondition,
  AiRuleSwitchFormStore,

  // components
  FormRuleName,
  FormCostRange,
  FormConditionList,
} from '@/app/Modules/AiRule'

/**
 * @description 便捷刷新函数
 * @param field 比对字段
 * @returns
 */
export function shouldUpdateFn(field: keyof AiRuleSwitchFormStore) {
  return function (prev: AiRuleSwitchFormStore, curr: AiRuleSwitchFormStore) {
    return prev[field] !== curr[field]
  }
}

/**
 * @description Ai 规则模型 - 主体内容 - 关闭广告
 */
export function AiRuleSchemaBodySwitch() {
  const { aiRuleSchemaSwitchForm } = AiRuleSchemaService.useInject()

  // 监听投放场景的变化，判断是否处于观察期
  const scene = Form.useWatch('scene', aiRuleSchemaSwitchForm)
  const isObservationPeriod = useMemo(() => scene === 3, [scene])

  // 监听条件数组的变化，用于获取条件的选项/单位
  const conditionList = Form.useWatch('conditionList', aiRuleSchemaSwitchForm)
  const conditionOptions = useMemo(() => {
    // 处于观察期，过滤选项
    return isObservationPeriod
      ? SWITCH_CONDITION_OPTIONS.filter((item) => !Reflect.has(item, 'required'))
      : SWITCH_CONDITION_OPTIONS
  }, [isObservationPeriod])
  const conditionUnitIndex = useMemo(() => {
    if (!conditionList) return []

    // 条件对应的单位索引映射，无单位则渲染为空字符串
    return conditionList.map((item) => {
      const conditionOption = conditionOptions.find((eachItem) => eachItem.value === item.field)
      return conditionOption?.unit ?? ''
    })
  }, [conditionList, conditionOptions])

  return useMemo(() => {
    return (
      <Form {...aiRuleConfigs.formProps} form={aiRuleSchemaSwitchForm} initialValues={new AiRuleSwitchFormStore()}>
        <Form.Item label={'投放阶段'}>
          <Space size={10}>
            <Form.Item noStyle name={'scene'}>
              <Select
                style={{ width: '200px' }}
                options={SCENE_OPTIONS}
                onChange={(value) => {
                  // 每次切换选项都需要重新查询选项及设置第一个值
                  const options = SCENE_CASCADE_OPTIONS[value] ?? []

                  aiRuleSchemaSwitchForm.setFieldsValue({
                    sceneCascadeOptions: options,
                    sceneCascadeOptionsValue: options.at(0)?.value,
                  })
                }}
              />
            </Form.Item>

            <Form.Item noStyle shouldUpdate={shouldUpdateFn('scene')}>
              {() => {
                // [计划衰退期效果欠佳] 无级联选项
                const sceneCascadeOptions = aiRuleSchemaSwitchForm.getFieldValue(
                  'sceneCascadeOptions',
                ) as SceneCascadeOptions

                const isRender = Boolean(sceneCascadeOptions?.length)

                return (
                  isRender && (
                    <Form.Item noStyle name={'sceneCascadeOptionsValue'}>
                      <Select style={{ width: '170px' }} options={sceneCascadeOptions} />
                    </Form.Item>
                  )
                )
              }}
            </Form.Item>
          </Space>
        </Form.Item>

        <Form.Item noStyle shouldUpdate={shouldUpdateFn('scene')}>
          {() => {
            return (
              isObservationPeriod && (
                <Form.Item
                  name={'costRangeValueProp'}
                  label={'历史消耗'}
                  rules={[
                    {
                      validator: (_, value: CostRangeValueProp) => {
                        const { min, max } = value

                        // 校验 [最小/最大值] 区间是否合理
                        const isReasonable = min <= max

                        return isReasonable ? Promise.resolve() : Promise.reject('最小值不能超过最大值，请重新设置')
                      },
                    },
                  ]}
                >
                  <FormCostRange />
                </Form.Item>
              )
            )
          }}
        </Form.Item>

        <FormConditionList
          conditionEntity={AiRuleSwitchCondition}
          conditionOptions={conditionOptions}
          conditionUnitIndex={conditionUnitIndex}
        />

        <Form.Item name={'operate'} label={'执行操作'} className={'operate-value'}>
          <Radio.Group>
            <Space size={12} className={'w-100'} direction={'vertical'}>
              {OPERATE_OPTIONS.map((item) => {
                return (
                  <Radio key={item.value} value={item.value}>
                    {item.label}
                  </Radio>
                )
              })}
            </Space>
          </Radio.Group>
        </Form.Item>

        <FormRuleName />
      </Form>
    )
  }, [aiRuleSchemaSwitchForm, conditionOptions, conditionUnitIndex, isObservationPeriod])
}
