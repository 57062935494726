import { fetcher } from '@/utils'
import { Form, message } from 'antd'
import { useMemo, useState } from 'react'
import { ChangedFieldValues } from '@/constants'
import { createService, useCb, useMergeState } from '@/hooks'

import { AiRuleApiSchema } from './ai-rule.api'
import { createErrorInstance } from '../shared'
import { AiRuleSwitchFormStore } from './ai-rule-schema-switch.service'
import { AiRuleBudgetFormStore } from './ai-rule-schema-budget.service'
import { AiRuleSchemaTabKey, AiRuleSchemaSubmitMode, aiRuleConfigs } from './ai-rule.shared'
import { AiRuleApiResult, AiRuleListEventScope, AiRuleListSelectedEventScope } from './ai-rule-list.service'

/**
 * @description Ai 规则模型额外辅助状态
 */
export class AiRuleSchemaState {
  activeKey: AiRuleSchemaTabKey // 当前激活的 Tab 页
  submitMode: AiRuleSchemaSubmitMode // 提交模式
  isSaveTemplate: boolean // 是否保存为模板

  constructor() {
    this.activeKey = AiRuleSchemaTabKey.BUDGET
    this.submitMode = AiRuleSchemaSubmitMode.CREATE
    this.isSaveTemplate = false
  }

  static id = -10

  /**
   * @description 提交数据
   * @param submitMode 提交模式
   * @param schema 数据模型
   */
  static submit(submitMode: AiRuleSchemaSubmitMode, schema: AiRuleApiSchema) {
    switch (submitMode) {
      case AiRuleSchemaSubmitMode.CREATE:
        return this.create(schema)

      case AiRuleSchemaSubmitMode.UPDATE:
        return this.update(schema)

      default:
        throw createErrorInstance('AiRuleSchemaSubmitMode', submitMode)
    }
  }

  /**
   * @description 创建一个规则
   * @param schema 数据模型
   */
  static create(schema: AiRuleApiSchema) {
    return fetcher(`/io/ai-rule/template/add.do`, { method: 'post', payload: schema })
  }

  /**
   * @description 更新一个规则
   * @param schema 数据模型
   */
  static update(schema: AiRuleApiSchema) {
    return fetcher(`/io/ai-rule/template/edit.do`, { method: 'post', payload: schema })
  }

  /**
   * @description 将数据模型转换成数据记录
   * @param schema 数据模型
   */
  static toRecord(schema: AiRuleApiSchema): AiRuleApiResult {
    const { type, aiRuleSwitch, aiRuleBudget } = schema

    const id = --AiRuleSchemaState.id

    switch (type) {
      // 关闭广告
      case 2:
        return {
          id: aiRuleSwitch?.id ?? id,
          type,
          ruleName: aiRuleSwitch?.ruleName,
          costType: aiRuleSwitch?.costType,
          execution: String(aiRuleSwitch?.operate),
          sceneSwitch: aiRuleSwitch?.scene,
          templateType: 2,
          customRuleJson: JSON.stringify(aiRuleSwitch),
        }

      // 提升预算
      case 3:
        return {
          id: aiRuleBudget?.id ?? id,
          type,
          ruleName: aiRuleBudget?.ruleName,
          budgetMin: aiRuleBudget?.budgetMin,
          budgetMax: aiRuleBudget?.budgetMax,
          execution: String(aiRuleBudget?.multiples ?? 0),
          templateType: 2,
          executionType: aiRuleBudget?.multiplesType,
          customRuleJson: JSON.stringify(aiRuleBudget),
        }

      default:
        throw createErrorInstance('ChangedFieldValues', type)
    }
  }

  /**
   * @description ruleType to activeKey
   * @param option ruleType
   */
  static normalActiveKey(option: ChangedFieldValues): AiRuleSchemaTabKey {
    switch (option) {
      case 2:
        return AiRuleSchemaTabKey.SWITCH

      case 3:
        return AiRuleSchemaTabKey.BUDGET

      default:
        throw createErrorInstance('ChangedFieldValues', option)
    }
  }
}

/**
 * @description Ai 规则模型服务
 */
export function useAiRuleSchemaService() {
  const aiRuleListDispatch = AiRuleListEventScope.useDispatch()
  const aiRuleListSelectedDispatch = AiRuleListSelectedEventScope.useDispatch()

  const [aiRuleSchemaSwitchForm] = Form.useForm<AiRuleSwitchFormStore>()
  const [aiRuleSchemaBudgetForm] = Form.useForm<AiRuleBudgetFormStore>()
  const [aiRuleSchemaState, aiRuleSchemaStateRef, setAiRuleSchemaState] = useMergeState(AiRuleSchemaState)

  const [aiRuleSchemaVisible, setAiRuleSchemaVisible] = useState(false)
  const [aiRuleSchemaLoading, setAiRuleSchemaLoading] = useState(false)

  // 创建数据模型
  const createApiSchema = useCb((): AiRuleApiSchema => {
    const { activeKey } = aiRuleSchemaStateRef.current

    switch (activeKey) {
      case AiRuleSchemaTabKey.SWITCH:
        return { type: 2, aiRuleSwitch: AiRuleSwitchFormStore.toSchema(aiRuleSchemaSwitchForm.getFieldsValue(true)) }

      case AiRuleSchemaTabKey.BUDGET:
        return { type: 3, aiRuleBudget: AiRuleBudgetFormStore.toSchema(aiRuleSchemaBudgetForm.getFieldsValue(true)) }

      default:
        throw createErrorInstance('AiRuleSchemaTabKey', activeKey)
    }
  })

  // 确定保存
  const handleOk = useCb(async (onUpdateLocalCb: (record: AiRuleApiResult) => void) => {
    const { activeKey, submitMode, isSaveTemplate } = aiRuleSchemaStateRef.current

    // 校验未通过，阻止提交
    try {
      switch (activeKey) {
        case AiRuleSchemaTabKey.SWITCH:
          await aiRuleSchemaSwitchForm.validateFields()
          break

        case AiRuleSchemaTabKey.BUDGET:
          await aiRuleSchemaBudgetForm.validateFields()
          break

        default:
          throw createErrorInstance('AiRuleSchemaTabKey', activeKey)
      }
    } catch (error) {
      return
    }

    try {
      setAiRuleSchemaLoading(true)

      const schema = createApiSchema()

      // 编辑模式直接调用接口，且需要刷新数据 & 触发更新回调
      if (submitMode === AiRuleSchemaSubmitMode.UPDATE) {
        const { content, succeeded } = await AiRuleSchemaState.submit(submitMode, schema)

        // 保存成功
        if (succeeded) {
          message.success('保存成功 ~')
          onUpdateLocalCb(content)
          aiRuleListDispatch()
          setAiRuleSchemaVisible(false)
        }
      }
      // 本地编辑只修改已选中的规则，并且触发更新回调
      else if (submitMode === AiRuleSchemaSubmitMode.UPDATE_LOCAL) {
        const record = AiRuleSchemaState.toRecord(schema)
        onUpdateLocalCb(record)
        setAiRuleSchemaVisible(false)
      }
      // 新增模式需确定是否需要保存为模板再调用接口
      else {
        let record = AiRuleSchemaState.toRecord(schema)
        let isSaveSuccess = true

        if (isSaveTemplate) {
          const { content, succeeded } = await AiRuleSchemaState.submit(submitMode, schema)

          // 保存成功
          if (succeeded) {
            message.success('保存成功 ~')

            // 后端将返回处理后的规则模板，将其回传
            record = content

            // 刷新数据
            aiRuleListDispatch()
          } else {
            // 保存失败
            isSaveSuccess = false
          }
        }

        // 保存成功
        if (isSaveSuccess) {
          setAiRuleSchemaVisible(false)
          aiRuleListSelectedDispatch(record) // 派发事件，将其传回外部
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      setAiRuleSchemaLoading(false)
    }
  })

  // 关闭之后
  const handleCloseAfter = useCb(() => {
    setAiRuleSchemaState(new AiRuleSchemaState())
    aiRuleSchemaSwitchForm.resetFields()
    aiRuleSchemaBudgetForm.resetFields()
  })

  // 创建之前
  const handleCreateBefore = useCb((selectedSize) => {
    // 判断是否超出选择限制
    const [, maxLimit] = aiRuleConfigs.ruleSelectedLimitRange
    if (selectedSize >= maxLimit) return message.info('规则已达到最大选择限制 ~')

    setAiRuleSchemaState({ submitMode: AiRuleSchemaSubmitMode.CREATE })
    setAiRuleSchemaVisible(true)
  })

  // 更新之前
  const handleUpdateBefore = useCb((record: AiRuleApiResult, submitMode: AiRuleSchemaSubmitMode) => {
    // type <-> activeKey
    const { type } = record
    const activeKey = AiRuleSchemaState.normalActiveKey(type)

    // set tab key & open modal
    setAiRuleSchemaState({ activeKey, submitMode })
    setAiRuleSchemaVisible(true)

    // set form fields value
    switch (activeKey) {
      case AiRuleSchemaTabKey.SWITCH: {
        const values = AiRuleSwitchFormStore.toValues(record)
        aiRuleSchemaSwitchForm.setFieldsValue(values)
        break
      }

      case AiRuleSchemaTabKey.BUDGET: {
        const values = AiRuleBudgetFormStore.toValues(record)
        aiRuleSchemaBudgetForm.setFieldsValue(values)
        break
      }

      default:
        throw createErrorInstance('AiRuleSchemaTabKey', activeKey)
    }
  })

  return useMemo(() => {
    return {
      aiRuleSchemaState,
      aiRuleSchemaLoading,
      aiRuleSchemaVisible,
      aiRuleSchemaSwitchForm,
      aiRuleSchemaBudgetForm,

      handleOk,
      handleCloseAfter,
      handleCreateBefore,
      handleUpdateBefore,
      setAiRuleSchemaState,
      setAiRuleSchemaVisible,
    }
  }, [
    aiRuleSchemaBudgetForm,
    aiRuleSchemaLoading,
    aiRuleSchemaState,
    aiRuleSchemaSwitchForm,
    aiRuleSchemaVisible,
    handleCloseAfter,
    handleCreateBefore,
    handleOk,
    handleUpdateBefore,
    setAiRuleSchemaState,
  ])
}

export const AiRuleSchemaService = createService(useAiRuleSchemaService)
