// 投放时段 - 时间数组
export const timeList = [
  { key: 1, name: '00:00~00:30' },
  { key: 2, name: '00:30~01:00' },
  { key: 3, name: '01:00~01:30' },
  { key: 4, name: '01:30~02:00' },
  { key: 5, name: '02:00~02:30' },
  { key: 6, name: '02:30~03:00' },
  { key: 7, name: '03:00~03:30' },
  { key: 8, name: '03:30~04:00' },
  { key: 9, name: '04:00~04:30' },
  { key: 10, name: '04:30~05:00' },
  { key: 11, name: '05:00~05:30' },
  { key: 12, name: '05:30~06:00' },
  { key: 13, name: '06:00~06:30' },
  { key: 14, name: '06:30~07:00' },
  { key: 15, name: '07:00~07:30' },
  { key: 16, name: '07:30~08:00' },
  { key: 17, name: '08:00~08:30' },
  { key: 18, name: '08:30~09:00' },
  { key: 19, name: '09:00~09:30' },
  { key: 20, name: '09:30~10:00' },
  { key: 21, name: '10:00~10:30' },
  { key: 22, name: '10:30~11:00' },
  { key: 23, name: '11:00~11:30' },
  { key: 24, name: '11:30~12:00' },
  { key: 25, name: '12:00~12:30' },
  { key: 26, name: '12:30~13:00' },
  { key: 27, name: '13:00~13:30' },
  { key: 28, name: '13:30~14:00' },
  { key: 29, name: '14:00~14:30' },
  { key: 30, name: '14:30~15:00' },
  { key: 31, name: '15:00~15:30' },
  { key: 32, name: '15:30~16:00' },
  { key: 33, name: '16:00~16:30' },
  { key: 34, name: '16:30~17:00' },
  { key: 35, name: '17:00~17:30' },
  { key: 36, name: '17:30~18:00' },
  { key: 37, name: '18:00~18:30' },
  { key: 38, name: '18:30~19:00' },
  { key: 39, name: '19:00~19:30' },
  { key: 40, name: '19:30~20:00' },
  { key: 41, name: '20:00~20:30' },
  { key: 42, name: '20:30~21:00' },
  { key: 43, name: '21:00~21:30' },
  { key: 44, name: '21:30~22:00' },
  { key: 45, name: '22:00~22:30' },
  { key: 46, name: '22:30~23:00' },
  { key: 47, name: '23:00~23:30' },
  { key: 48, name: '23:30~24:00' },
]

// 投放时段 - 表格数组：星期 + 时间 schedule
export const SCHEDULE_SHEET = [
  { key: 1, name: '星期一', rowArray: timeList },
  { key: 2, name: '星期二', rowArray: timeList },
  { key: 3, name: '星期三', rowArray: timeList },
  { key: 4, name: '星期四', rowArray: timeList },
  { key: 5, name: '星期五', rowArray: timeList },
  { key: 6, name: '星期六', rowArray: timeList },
  { key: 7, name: '星期日', rowArray: timeList },
]

export const SCHEDULE_COLUMNS = [
  {
    key: 1,
    thArray: [
      { key: 1, name: '星期/时间', rowSpan: 2 },
      { key: 2, name: '00:00 - 12:00', colSpan: 24 },
      { key: 3, name: '12:00 - 24:00', colSpan: 24 },
    ],
  },
  {
    key: 2,
    thArray: [
      { key: 0, name: '', colSpan: 2 },
      { key: 1, name: '', colSpan: 2 },
      { key: 2, name: '', colSpan: 2 },
      { key: 3, name: '', colSpan: 2 },
      { key: 4, name: '', colSpan: 2 },
      { key: 5, name: '', colSpan: 2 },
      { key: 6, name: '', colSpan: 2 },
      { key: 7, name: '', colSpan: 2 },
      { key: 8, name: '', colSpan: 2 },
      { key: 9, name: '', colSpan: 2 },
      { key: 10, name: '', colSpan: 2 },
      { key: 11, name: '', colSpan: 2 },
      { key: 12, name: '', colSpan: 2 },
      { key: 13, name: '', colSpan: 2 },
      { key: 14, name: '', colSpan: 2 },
      { key: 15, name: '', colSpan: 2 },
      { key: 16, name: '', colSpan: 2 },
      { key: 17, name: '', colSpan: 2 },
      { key: 18, name: '', colSpan: 2 },
      { key: 19, name: '', colSpan: 2 },
      { key: 20, name: '', colSpan: 2 },
      { key: 21, name: '', colSpan: 2 },
      { key: 22, name: '', colSpan: 2 },
      { key: 23, name: '', colSpan: 2 },
    ],
  },
]
