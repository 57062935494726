import dayjs, { Dayjs } from 'dayjs'

type RangeValueType = [Dayjs, Dayjs]

interface Range {
  label: string
  value: RangeValueType
  isShow: boolean // 直接控制是否展示该日期范围
}

export enum DATE_FORMATS {
  // 纯时间
  TIME = 'HH:mm:ss',

  // 纯日期
  DATE = 'YYYY-MM-DD',

  // 日期 + 时间
  DATE_TIME = 'YYYY-MM-DD HH:mm:ss',
}

// 日期列表
export const DATE_TIME_RANGES: Range[] = [
  {
    label: '今天',
    value: [nowMoment(), nowMoment()],
    isShow: true,
  },
  {
    label: '昨天',
    value: [addDays(-1), addDays(-1)],
    isShow: true,
  },
  {
    label: '近 3 天',
    value: [addDays(-2), nowMoment()],
    isShow: true,
  },
  {
    label: '近 7 天',
    value: [addDays(-6), nowMoment()],
    isShow: true,
  },
  {
    label: '近 30 天',
    value: [addDays(-29), nowMoment()],
    isShow: true,
  },
  {
    label: '近 60 天',
    value: [addDays(-59), nowMoment()],
    isShow: true,
  },
  {
    label: '近 180 天',
    value: [addDays(-179), nowMoment()],
    isShow: true,
  },
]

export function addDays(num: number) {
  return dayjs().add(num, 'days')
}

export function nowMoment() {
  return dayjs()
}

/**
 * @description 规范化日期范围 - 只取需要显示的项 | array to object
 */
export function normalRanges() {
  const ranges = DATE_TIME_RANGES.filter((item) => item.isShow)

  const rangesToObject: { [key: string]: RangeValueType } = {}

  ranges.forEach((item) => {
    rangesToObject[item.label] = item.value
  })

  return rangesToObject
}
