import { fetcher } from '@/utils'
import { BasePayload } from '@/app/Holder'
import { useState, useCallback, useEffect, useMemo } from 'react'
import { createEventScope, createService, useMergeState } from '@/hooks'
import { AutoExecutionValues, StrategyTypeOptionsValues } from '@/constants'

/**
 * @description 策略结果
 */
export class StrategyApiResult {
  daysTimes?: string // 调整频率
  strategyId!: number // 策略 ID
  createTime?: string // 创建时间
  ruleContent?: string // 规则内容
  strategyName?: string // 策略名称
  strategyType!: StrategyTypeOptionsValues // 策略类型
  autoExecution!: AutoExecutionValues // 是否自动执行

  /**
   * @description 主键
   */
  static PRIMARY_KEY: keyof StrategyApiResult = 'strategyId'

  /**
   * @todo 优化 AppId
   * @description 策略数据请求器
   * @param payload 请求参数
   */
  static request(payload: BasePayload) {
    const appId = Number(localStorage.getItem('SELECTED_APP_ID')!)

    return fetcher(`/io/ai-rule/get-strategys.do`, { payload: { ...payload, appId } })
  }
}

/**
 * @description 策略列表服务
 */
export function useStrategyListService() {
  const [strategyList, setStrategyList] = useState<StrategyApiResult[]>([])
  const [strategyTotal, setStrategyTotal] = useState(0)
  const [strategyLoading, setStrategyLoading] = useState(false)
  const [strategyPayload, strategyPayloadRef, handleStrategyPayloadChange] = useMergeState(BasePayload)

  const strategyRequest = useCallback(async () => {
    try {
      setStrategyLoading(true)

      const { content } = await StrategyApiResult.request(strategyPayloadRef.current)

      setStrategyList(content?.content ?? [])
      setStrategyTotal(content?.totalRecord ?? 0)
    } catch (error) {
      console.error(error)
    } finally {
      setStrategyLoading(false)
    }
  }, [strategyPayloadRef])

  useEffect(() => {
    strategyRequest()
  }, [strategyPayload, strategyRequest])

  return useMemo(() => {
    return {
      strategyList,
      strategyTotal,
      strategyLoading,
      strategyPayload,
      strategyRequest,

      handleStrategyPayloadChange,
    }
  }, [handleStrategyPayloadChange, strategyList, strategyLoading, strategyPayload, strategyRequest, strategyTotal])
}

export const StrategyListService = createService(useStrategyListService)
export const StrategyListEventScope = createEventScope<StrategyApiResult>()
