import { message } from 'antd'
import { useMemo } from 'react'
import { fetcher, oaDeDuplication } from '@/utils'
import { createEventScope, createService } from '@/hooks'

import { useHolderAppService } from './holder-app.service'
import { useHolderCounterService } from './holder-counter.service'
import { useHolderLocationService } from './holder-location.service'
import { useHolderMediumAccountService } from './holder-medium-account.service'
import { HolderOperPayload, HolderOperMultiplePayload, HolderOperExecutionPayload } from './holder.shared'

interface Origin {
  mediumAccountIds: Nullable<string>
  mediumAccountList: { mediumAccountId: number; mediumAccountName?: string }[]
}

// 从返回的数据里筛选出投放账户，仅供 Select 筛选使用
export function normalizeOptions({ mediumAccountIds, mediumAccountList }: Origin) {
  const ids = mediumAccountIds
    ?.split(',')
    .filter((item) => Boolean(item.trim())) // 过滤空字符串
    .map(Number) // string to number

  const options = mediumAccountList.map((item) => ({
    value: item.mediumAccountId,
    label: item.mediumAccountName ?? '---',
  }))

  return {
    normalizedIds: ids,
    normalizedOptions: oaDeDuplication(options, [], 'value'),
  }
}

export async function handleOper(payload: HolderOperPayload, onSuccess: () => void) {
  try {
    const { succeeded } = await fetcher(HolderOperPayload.API, { method: 'post', payload })

    if (succeeded) {
      onSuccess()
      message.success('操作成功')
    }
  } catch (error) {
    console.error(error)
  }
}

export async function handleOperMultiple(payload: HolderOperMultiplePayload, onSuccess: () => void) {
  try {
    const { succeeded } = await fetcher(HolderOperMultiplePayload.API, { method: 'post', payload })

    if (succeeded) {
      onSuccess()
      message.success('操作成功')
    }
  } catch (error) {
    console.error(error)
  }
}

export async function handleOperExecution(
  payload: HolderOperExecutionPayload,
  onSuccess: () => void,
  onFinally: () => void,
) {
  try {
    const { succeeded } = await fetcher(HolderOperExecutionPayload.API, { method: 'post', payload })

    if (succeeded) {
      onSuccess()
      message.success('操作成功')
    }
  } catch (error) {
    console.error(error)
  } finally {
    onFinally()
  }
}

export function useHolderService() {
  const holderAppServiceInstance = useHolderAppService()
  const holderCounterServiceInstance = useHolderCounterService()
  const holderLocationServiceInstance = useHolderLocationService()
  const holderMediumAccountServiceInstance = useHolderMediumAccountService()

  return useMemo(() => {
    return {
      ...holderAppServiceInstance,
      ...holderCounterServiceInstance,
      ...holderLocationServiceInstance,
      ...holderMediumAccountServiceInstance,
    }
  }, [
    holderAppServiceInstance,
    holderCounterServiceInstance,
    holderLocationServiceInstance,
    holderMediumAccountServiceInstance,
  ])
}

export const HolderService = createService(useHolderService)
export const HolderEventScope = createEventScope()
