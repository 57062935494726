import { Row } from 'antd'
import { isNil } from 'lodash-es'

import { AFlattenTreeBox } from './AFlattenTreeBox'
import { AFlattenTreeProps } from './interface'
import { AFlattenTreeWrapper } from './styled'

export function AFlattenTree({ header, footer }: AFlattenTreeProps) {
  return (
    <AFlattenTreeWrapper className={'aft'}>
      {!isNil(header) && <AFlattenTreeBox box={header} className={'aft-header'} />}

      <Row className={'aft-content'}>content</Row>

      {!isNil(footer) && <AFlattenTreeBox box={footer} className={'aft-footer'} />}
    </AFlattenTreeWrapper>
  )
}
