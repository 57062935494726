import { InputProps } from 'antd'
import { recalculateStrLength } from '@/utils'

import { ACalcInputWrapper } from './styled'

interface ACalcInputProps extends InputProps {
  range: readonly [number, number]
}

/**
 * @description 重新计算值长度的输入框
 * @returns
 */
export function ACalcInput({ range, value, onChange, ...baseProps }: ACalcInputProps) {
  const [, max] = range

  const valueLength = recalculateStrLength(value as string)

  return (
    <ACalcInputWrapper
      {...baseProps}
      value={value}
      suffix={
        <span className={'aci-length'}>
          <span>{valueLength}</span>
          <span>/</span>
          <span>{max}</span>
        </span>
      }
      onChange={(e) => {
        const newValueLength = e.target.value.length
        const oldValueLength = ((value as string) ?? '').length

        // 通过长度判断当前是否正在删除
        const isDelete = newValueLength < oldValueLength

        // 新内容需要计算并判断其长度是否超出限值
        const isReasonable = valueLength < max

        if (isDelete || isReasonable) onChange?.(e)
      }}
    />
  )
}

/**
 * @description 内置校验器
 * @param value 值
 * @param rules 规则 - 只针对最大最小值范围判断 - 左闭右开
 * @returns
 */
export function ACalcInputValidator(
  value: string,
  rules: [{ min: number; message: string }, { max: number; message: string }],
) {
  const [minRule, maxRule] = rules
  const valueLength = recalculateStrLength(value)

  // 长度已超出最大限制
  if (valueLength > maxRule.max) return Promise.reject(maxRule.message)

  // 长度不满足最小限制
  if (valueLength <= minRule.min) return Promise.reject(minRule.message)

  return Promise.resolve()
}
