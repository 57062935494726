/**
 * @description 托管相关常量
 */

// 选择广告类型
export const SELECT_AD_TYPE_ORIGIN = [
  { value: 1, label: '所有广告组' },
  { value: 2, label: '指定广告组' },
  { value: 3, label: '指定广告' },
] as const // 不变项
export const SELECT_AD_TYPE = [...SELECT_AD_TYPE_ORIGIN] // 值使用
export type SelectAdTypeValues = typeof SELECT_AD_TYPE_ORIGIN[number]['value'] // 类型使用

// 运行日期方式（托管日期）
export const RUNNING_DATE_WAY_ORIGIN = [
  { value: 0, label: '从今天起长期投放' },
  { value: 1, label: '设置开始和结束日期' },
] as const
export const RUNNING_DATE_WAY = [...RUNNING_DATE_WAY_ORIGIN]
export type RunningDateWayValues = typeof RUNNING_DATE_WAY_ORIGIN[number]['value']

// 托管时段方式选项
export const TIME_PERIOD_OPTIONS_ORIGIN = [
  { value: 'NONE', label: '不限' },
  { value: 'CUSTOM', label: '指定时间段' },
] as const
export const TIME_PERIOD_OPTIONS = [...TIME_PERIOD_OPTIONS_ORIGIN]
export type TimePeriodOptionsValues = typeof TIME_PERIOD_OPTIONS_ORIGIN[number]['value']

// 操作规则类型
export const CHANGED_FIELD_ORIGIN = [
  // { value: 1, label: '调整出价' },
  { value: 2, label: '关闭广告' },
  { value: 3, label: '调整预算' },
] as const
export const CHANGED_FIELD = [...CHANGED_FIELD_ORIGIN]
export type ChangedFieldValues = typeof CHANGED_FIELD_ORIGIN[number]['value']

// 规则模板类型 - 0 和 1 一样
export const TEMPLATE_TYPE_ORIGIN = [
  { value: 0, label: '自定义模板', text: 'DIY', color: '#FBA600' },
  { value: 1, label: '系统模板', text: 'M', color: '#327BFC' },
  { value: 2, label: '自定义模板', text: 'DIY', color: '#FBA600' },
] as const
export const TEMPLATE_TYPE = [...TEMPLATE_TYPE_ORIGIN]
export type TemplateTypeValues = typeof TEMPLATE_TYPE_ORIGIN[number]['value']

/**
 * @description 操作符选项 - value 的设定是合理的
 * 1. 大于 100 即：最小值 (Min) 100
 * 2. 小于 1000 即：最大值 (Max) 1000
 * 3. 介于 100 ~ 1000 即：最小值 (Min) 100 ~ 最大值 (Max) 1000
 */
export const OPERATOR_OPTIONS_ORIGIN = [
  { value: 'Min', label: '大于' },
  { value: 'Max', label: '小于' },
  { value: 'Range', label: '介于', required: false }, // 该选项是可隐藏的
] as const
export const OPERATOR_OPTIONS = [...OPERATOR_OPTIONS_ORIGIN]
export type OperatorOptionsValues = typeof OPERATOR_OPTIONS_ORIGIN[number]['value']

// 预算调整值类型
export const MULTIPLES_TYPE_ORIGIN = [
  { value: 1, label: '元', desc: '自然数值' },
  { value: 2, label: '%', desc: '百分比值' },
] as const
export const MULTIPLES_TYPE = [...MULTIPLES_TYPE_ORIGIN]
export type MultiplesTypeValues = typeof MULTIPLES_TYPE_ORIGIN[number]['value']

// 是否自动执行
export const AUTO_EXECUTION_ORIGIN = [
  { value: 0, label: '否' },
  { value: 1, label: '是' },
] as const
export const AUTO_EXECUTION = [...AUTO_EXECUTION_ORIGIN]
export type AutoExecutionValues = typeof AUTO_EXECUTION_ORIGIN[number]['value']

// 提升预算规则条件选项
export const BUDGET_CONDITION_OPTIONS_ORIGIN = [
  { value: 'activeNumMedium', label: '媒体激活数' },
  { value: 'activeNumPkg', label: '激活数（包）' },
  { value: 'cost', label: '消耗', unit: '元' },
  { value: 'costTodayRate', label: '日预算消耗', unit: '%' },
  { value: 'cpNewPayNum', label: '新增付费成本（包）', unit: '元' },
  { value: 'cpaMedium', label: '媒体激活成本', unit: '元' },
  { value: 'cpaPkg', label: '激活成本（包）', unit: '元' },
  { value: 'cppMedium', label: '媒体付费成本', unit: '元' },
  { value: 'cprMedium', label: '媒体注册成本', unit: '元' },
  { value: 'cprPkg', label: '注册成本（包）', unit: '元' },
  { value: 'ctr', label: '点击率', unit: '%' },
  { value: 'newPayNum', label: '新增付费人数（包）' },
  { value: 'newPayRate', label: '新增付费率（包）', unit: '%' },
  { value: 'payNumMedium', label: '媒体付费数' },
  { value: 'payNumRateMedium', label: '媒体付费率', unit: '%' },
  { value: 'registerNumMedium', label: '媒体注册数' },
  { value: 'registerNumPkg', label: '注册数（包）' },
  { value: 'roiPkg', label: '新增 ROI', unit: '%' },
] as const
export const BUDGET_CONDITION_OPTIONS = [...BUDGET_CONDITION_OPTIONS_ORIGIN] as (OptionType<string, string> & {
  unit?: '%' | '元'
})[]
export type BudgetConditionOptionsValues = typeof BUDGET_CONDITION_OPTIONS_ORIGIN[number]['value']

// 投放阶段场景
export const SCENE_OPTIONS_ORIGIN = [
  { value: 1, label: '冷启动期跑量能力欠佳' },
  { value: 2, label: '冷启动期跑量效果欠佳' },
  { value: 3, label: '计划观察期效果欠佳' },
  { value: 4, label: '计划衰退期效果欠佳' },
] as const
export const SCENE_OPTIONS = [...SCENE_OPTIONS_ORIGIN]
export type SceneOptionsValues = typeof SCENE_OPTIONS_ORIGIN[number]['value']

// 消耗广告类型
export const COST_TYPE_OPTIONS_ORIGIN = [
  { value: 1, label: '小消耗类广告' },
  { value: 2, label: '中消耗类广告' },
  { value: 3, label: '大消耗类广告' },
] as const
export const COST_TYPE_OPTIONS = [...COST_TYPE_OPTIONS_ORIGIN]
export type CostTypeOptionsValues = typeof COST_TYPE_OPTIONS_ORIGIN[number]['value']

// 曝光时长
export const ROLLING_DURATION_OPTIONS_ORIGIN = [
  { value: 24, label: '曝光 24h 消耗低' },
  { value: 48, label: '曝光 48h 消耗高' },
] as const
export const ROLLING_DURATION_OPTIONS = [...ROLLING_DURATION_OPTIONS_ORIGIN]
export type RollingDurationOptionsValues = typeof ROLLING_DURATION_OPTIONS_ORIGIN[number]['value']

// 投放时长
export const DELIVERY_DURATION_OPTIONS_ORIGIN = [
  { value: 24, label: '投放 24h' },
  { value: 48, label: '投放 48h' },
] as const
export const DELIVERY_DURATION_OPTIONS = [...DELIVERY_DURATION_OPTIONS_ORIGIN]
export type DeliveryDurationOptionsValues = typeof DELIVERY_DURATION_OPTIONS_ORIGIN[number]['value']

// 投放阶段场景级联选项 = 按照 SceneOptionsValues 进行索引排序 - 1 投放时长 | 2 曝光时长 | 3 消耗广告类型
export const SCENE_CASCADE_OPTIONS = [
  ,
  DELIVERY_DURATION_OPTIONS,
  ROLLING_DURATION_OPTIONS,
  COST_TYPE_OPTIONS_ORIGIN,
] as (OptionType<SceneCascadeOptionsValues>[] | undefined)[]
export type SceneCascadeOptionsValues =
  | CostTypeOptionsValues
  | RollingDurationOptionsValues
  | DeliveryDurationOptionsValues

// 开关操作
export const OPERATE_OPTIONS_ORIGIN = [
  // { value: 1, label: '开启广告' },
  { value: 2, label: '关闭广告，不重启' },
  { value: 3, label: '关闭广告，不“满足条件”（数据变好）重启' },
] as const
export const OPERATE_OPTIONS = [...OPERATE_OPTIONS_ORIGIN]
export type OperateOptionsValues = typeof OPERATE_OPTIONS_ORIGIN[number]['value']

// 关闭广告规则条件选项
export const SWITCH_CONDITION_OPTIONS_ORIGIN = [
  { value: 'totalActiveNumMedium', label: '总媒体激活数' },
  { value: 'totalActiveNumPkg', label: '总激活数（包）' },
  { value: 'totalCost', label: '总消耗', unit: '元', required: false }, // 当 [投放阶段 = 计划观察期效果欠佳] 时，该选项由 [历史消耗] 控制
  { value: 'totalCpNewPayNum', label: '总新增付费成本（包）', unit: '元' },
  { value: 'totalCpaMedium', label: '总媒体激活成本', unit: '元' },
  { value: 'totalCpaPkg', label: '总激活成本（包）', unit: '元' },
  { value: 'totalCppMediumDaysThree', label: '三天付费成本', unit: '元' },
  { value: 'totalCppMedium', label: '总媒体付费成本', unit: '元' },
  { value: 'totalCppPkgDaysThree', label: '三天付费成本（包）', unit: '元' },
  { value: 'totalCprMedium', label: '总媒体注册成本', unit: '元' },
  { value: 'totalCprPkg', label: '总注册成本（包）', unit: '元' },
  { value: 'totalCtr', label: '总点击率', unit: '%' },
  { value: 'totalFirstDayRoi', label: '总当日付费 ROI', unit: '%' },
  { value: 'totalNewPayNum', label: '总新增付费人数（包）' },
  { value: 'totalNewPayRate', label: '总新增付费率（包）', unit: '%' },
  { value: 'totalPayNumMedium', label: '总媒体付费数' },
  { value: 'totalPayNumRateMedium', label: '总媒体付费率', unit: '%' },
  { value: 'totalRegisterNumMedium', label: '总媒体注册数' },
  { value: 'totalRegisterNumPkg', label: '总注册数（包）' },
  { value: 'totalRoiPkg', label: '新增 ROI（包）', unit: '%' },
] as const
export const SWITCH_CONDITION_OPTIONS = [...SWITCH_CONDITION_OPTIONS_ORIGIN] as (OptionType<string, string> & {
  unit?: '%' | '元'
})[]
export type SwitchConditionOptionsValues = typeof SWITCH_CONDITION_OPTIONS_ORIGIN[number]['value']

// 策略类型
export const STRATEGY_TYPE_OPTIONS_ORIGIN = [
  { value: 0, label: '经验策略' },
  { value: 1, label: 'AI 调整出价策略' },
  { value: 2, label: 'AI 关闭广告策略' },
  { value: 3, label: 'AI 调整预算策略' },
] as const
export const STRATEGY_TYPE_OPTIONS = [...STRATEGY_TYPE_OPTIONS_ORIGIN]
export type StrategyTypeOptionsValues = typeof STRATEGY_TYPE_OPTIONS_ORIGIN[number]['value']

// 操作结果
export const OPER_RESULT_OPTIONS_ORIGIN = [
  { value: 0, label: '成功', status: 'success' },
  { value: 1, label: '失败', status: 'error' },
] as const
export const OPER_RESULT_OPTIONS = [...OPER_RESULT_OPTIONS_ORIGIN]
export type OperResultOptionsValues = typeof OPER_RESULT_OPTIONS_ORIGIN[number]['value']
