import styled, { css } from 'styled-components'
import { ATextEllipsis } from '@/components'

import { AFreeListProps } from '.'

const TextStyle = css`
  ${ATextEllipsis}
  color: var(--ai-color-title);
`

export const AFreeListWrapper = styled.div<Pick<AFreeListProps, 'bordered'>>`
  border: ${(props) => (props.bordered ? '1px solid var(--ai-color-border)' : 'unset')};
  min-height: 500px;
  border-radius: ${(props) => (props.bordered ? 'var(--ai-base-border-radius)' : 'unset')};

  .afl-header {
    height: 50px;
    padding: 0 16px;
    border-bottom: inherit;
    background-color: var(--ai-bgc-fill);
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;

    &-title {
      ${TextStyle}
      font-weight: var(--ai-font-weight-bold);

      /* 处理 checkbox 包裹的文本溢出 */
      .ant-checkbox-wrapper {
        width: 100%;

        .ant-checkbox {
          & + span {
            ${TextStyle}
          }
        }
      }
    }
  }

  .afl-content {
    height: calc(500px - 50px); // min-height - header-height
    overflow-y: auto;
    overflow-y: overlay;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;

    &-item {
      height: 40px;
      padding: 0 16px;
      line-height: 40px;

      /* 处理 inline-block 元素直接包裹的（或纯）文本溢出 */
      ${TextStyle}

      /* 处理 block 元素直接包裹的文本溢出（ Flex 布局的元素无效 | 可补充块级元素） */
      p,
      div,
      main,
      aside,
      header,
      footer,
      article,
      section {
        ${TextStyle}
      }

      /* 处理 checkbox 包裹的文本溢出 */
      .ant-checkbox-wrapper {
        width: 100%;

        .ant-checkbox {
          & + span {
            ${TextStyle}
          }
        }
      }
    }

    &-empty {
      top: 50%;
      left: 50%;
      margin: 0;
      position: relative;
      transform: translate(-50%, -50%);
    }
  }
`
