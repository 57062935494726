import { HolderPopover } from './HolderPopover'

/**
 * @description 渲染规则内容
 * @param value ruleContent 字段值
 */
export function HolderRenderRuleContent(value?: string | null) {
  const ensureValue = value ?? ''

  // 以换行符分割，过滤空字符串，默认展示前两项
  const dataSource = ensureValue.split('\n').filter((item) => Boolean(item.trim()))

  return (
    <HolderPopover maxRows={2} dataSource={dataSource} renderItem={(item) => item} renderItemTitle={(item) => item} />
  )
}
