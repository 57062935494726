import { Form } from 'antd'
import { useMemo } from 'react'

import { SelectStrategy } from './components'
import { arrayValidator } from './holder-setup.shared'

export function HolderSetupSchemaBodyStep2() {
  return useMemo(() => {
    return (
      <Form.Item
        name={'selectedStrategyList'}
        rules={[{ validator: (_, value) => arrayValidator(value, '请至少选择一个策略') }]}
        validateStatus={'success'} // 避免内部的 Input/Select/... 边框变红
      >
        <SelectStrategy />
      </Form.Item>
    )
  }, [])
}
