import styled, { css } from 'styled-components'

import { AHoverBarProps } from '.'

const baseHeight = css<Pick<AHoverBarProps, 'contentHeight'>>`
  height: ${(props) => props.contentHeight ?? 'var(--ai-base-height)'};
`

export const AHoverBarWrapper = styled.div`
  ${baseHeight}
  display: flex;
  align-items: center;

  .ant-alert {
    ${baseHeight}
    width: 100%;
    padding: 14px 20px !important;
    background-color: #5692fc !important;

    .ant-alert-message {
      color: var(--ai-base-color);

      .hover-bar-divider {
        border-color: var(--ai-color-border);
      }
    }

    .ant-alert-close-icon {
      span {
        color: var(--ai-base-color);

        &:hover {
          color: var(--ai-base-color);
          opacity: 0.75;
        }
      }
    }
  }
`
