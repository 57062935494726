import { cloneDeep } from 'lodash-es'
import dayjs, { Dayjs } from 'dayjs'
import { Form, message } from 'antd'
import { useMemo, useState } from 'react'
import { StrategyApiResult } from '@/app/Modules'
import { createService, useCb } from '@/hooks'
import { DATE_FORMATS, fetcher } from '@/utils'
import { SelectAdTypeValues, RunningDateWayValues, AutoExecutionValues, TimePeriodOptionsValues } from '@/constants'

import { HolderSetupStep, MaWithAd } from './holder-setup.shared'

type Strategy = Pick<StrategyApiResult, 'strategyId' | 'autoExecution'>

/**
 * @api /io/ai-rule/add.do
 * @description 设置托管
 */
export class ApiPayload<T> {
  appId?: number // 应用 ID
  adIds?: T // 广告 ID 列表 - selectAdType = 3 时指定
  mediumId?: number // 媒体 ID - 目前仅支持今日头条 (2)
  strategys?: Strategy[] // 策略列表
  adGroupIds?: T // 广告组 ID 列表 - selectAdType = 2 时指定
  timePeriod?: string | object[] // 托管时段 - timePeriodOption = "NONE" 时值为 7*48 = 336 个 1，timePeriodOption = "CUSTOM" 时值为已选的时间段对象
  selectAdType?: SelectAdTypeValues = 1 // 选择的广告类型
  runningDateWay?: RunningDateWayValues = 0 // 运行日期的方式
  runningEndDate?: string // 指定结束日期 - runningDateWay = 1 时指定
  runningStartDate?: string // 指定开始日期 - runningDateWay = 1 时指定
  mediumAccountIds?: T // 投放账户 ID 列表
  autoExecutionBudget?: AutoExecutionValues
  autoExecutionSwitch?: AutoExecutionValues

  static API = '/io/ai-rule/trusteeship/add.do'
}

/**
 * @description 设置托管表单域
 */
export class FormStore<T> extends ApiPayload<T> {
  // 以下字段为辅助字段 - 【非接口参数，提交前会移除】
  runningDateRange?: Dayjs[] // 指定日期范围 - runningDateWay = 1 时指定
  timePeriodOption?: TimePeriodOptionsValues // 托管时段选项
  mergeMaWithAdList?: MaWithAd[] // 维护着投放账户与已选择的广告（组）之间的对应关系，用于表单校验，数据提取等功能需求
  selectedStrategyList?: StrategyApiResult[] // 第二步中已选择的策略实体

  constructor() {
    super()
    const that = this as unknown as FormStore<number[]>

    /**
     * 1. 其中一些成员属性必须显式赋值 undefined，否则 TS 编译器会抹除未显式赋值的成员属性
     * 2. 这将会导致 form store 中没有设置该成员属性，以至于 setField、getField、resetField 等方法作用不到该成员属性
     */
    that.appId = undefined
    that.adIds = []
    that.mediumId = 2
    that.strategys = []
    that.adGroupIds = []
    that.timePeriod = undefined
    that.selectAdType = 1
    that.runningDateWay = 0
    that.runningEndDate = undefined
    that.runningStartDate = undefined
    that.mediumAccountIds = []

    that.runningDateRange = []
    that.timePeriodOption = 'NONE'
    that.mergeMaWithAdList = []
    that.selectedStrategyList = []
  }

  /**
   * @description 表单转换给接口
   */
  static toSchema(values: FormStore<number[]>) {
    const cloned = cloneDeep(values)
    const schema: ApiPayload<string> = {}

    const normalizedAd = FormStore.normalAd(cloned.selectAdType, cloned.mergeMaWithAdList)
    const normalizedStrategy = FormStore.normalStrategy(cloned.selectedStrategyList)
    const normalizedTimePeriod = FormStore.normalTimePeriod(cloned.timePeriodOption, cloned.timePeriod as SafeAny)
    const normalizedRunningDate = FormStore.normalRunningDate(cloned.runningDateWay, cloned.runningDateRange)

    // 参数处置
    schema.appId = cloned.appId
    schema.adIds = normalizedAd.adIds?.join()
    schema.mediumId = cloned.mediumId
    schema.strategys = normalizedStrategy.strategyList
    schema.adGroupIds = normalizedAd.adGroupIds?.join()
    schema.timePeriod = normalizedTimePeriod
    schema.selectAdType = cloned.selectAdType
    schema.runningDateWay = cloned.runningDateWay
    schema.runningEndDate = normalizedRunningDate.E
    schema.runningStartDate = normalizedRunningDate.S
    schema.mediumAccountIds = cloned.mediumAccountIds?.join()
    schema.autoExecutionSwitch = Number(normalizedStrategy.hasAiSwitchStrategy) as AutoExecutionValues
    schema.autoExecutionBudget = Number(normalizedStrategy.hasAiBudgetStrategy) as AutoExecutionValues

    return schema
  }

  static normalAd(option?: SelectAdTypeValues, value: MaWithAd[] = []): { adIds?: number[]; adGroupIds?: number[] } {
    switch (option) {
      case 1: {
        return {}
      }

      case 2: {
        const selectedList = value.map((item) => item.selectedList).flat()
        const adGroupIds = selectedList.map((item) => item.value)

        return { adGroupIds }
      }

      case 3: {
        const selectedList = value.map((item) => item.selectedList).flat()
        const adIds = selectedList.map((item) => item.value)

        return { adIds }
      }

      default:
        throw new Error('前端选择广告类型选项传值错误，请检查代码~')
    }
  }

  static normalStrategy(value: StrategyApiResult[] = []) {
    const strategyList = value.map(
      (item): Strategy => ({ strategyId: item.strategyId, autoExecution: item.autoExecution }),
    )

    const hasAiSwitchStrategy = value.some((item) => item.strategyType === 2 && Boolean(item.autoExecution))
    const hasAiBudgetStrategy = value.some((item) => item.strategyType === 3 && Boolean(item.autoExecution))

    return { strategyList, hasAiSwitchStrategy, hasAiBudgetStrategy }
  }

  /**
   * @description 处理托管时段
   * @param option TimePeriodOption
   * @param value TimePeriod - 当 TimePeriodOption = "CUSTOM" 时有值
   * @returns
   */
  static normalTimePeriod(option?: TimePeriodOptionsValues, value: SafeAny[] = []) {
    const initTimePeriod = new Array(7 * 48).fill(1)

    switch (option) {
      case 'NONE': {
        return initTimePeriod.join('')
      }

      case 'CUSTOM': {
        // @todo: 待优化
        const result = initTimePeriod.map((_, index) => {
          const thisKey = index
          const trKey = Math.floor(thisKey / 48) + 1
          const tdKey = (thisKey % 48) + 1
          const hasSchedule = value.filter((schedule) => {
            return schedule.trKey === trKey && schedule.tdKey === tdKey
          })
          return hasSchedule.length ? 1 : 0
        })

        return result.join('')
      }

      default:
        throw new Error('前端托管时段选项传值错误，请检查代码~')
    }
  }

  /**
   * @description 处理托管日期
   * @param option RunningDateWay
   * @param value RunningDateRange - 当 RunningDateWay = 1 时有值
   * @returns
   */
  static normalRunningDate(option?: RunningDateWayValues, value: Dayjs[] = []): { S?: string; E?: string } {
    switch (option) {
      case 0: {
        return {}
      }

      case 1: {
        const [S, E] = value
        const formattedS = dayjs(S).format(DATE_FORMATS.DATE)
        const formattedE = dayjs(E).format(DATE_FORMATS.DATE)

        return {
          S: formattedS,
          E: formattedE,
        }
      }

      default:
        throw new Error('前端托管日期选项传值错误，请检查代码~')
    }
  }
}

function useHolderSetupSchemaService() {
  const [holderSetupForm] = Form.useForm<FormStore<number[]>>()
  const [holderSetupStep, setHolderSetupStep] = useState(HolderSetupStep.ONE)
  const [holderSetupLoading, setHolderSetupLoading] = useState(false)
  const [holderSetupVisible, setHolderSetupVisible] = useState(false)

  // 提交
  const handleSubmit = useCb(async (onSuccess: () => void) => {
    // 校验未通过则阻止提交
    try {
      await holderSetupForm.validateFields()
    } catch (error) {
      return console.error(error)
    }

    try {
      setHolderSetupLoading(true)

      // 获取所有字段和值
      const store = holderSetupForm.getFieldsValue(true)

      // 处理成接口所需要的数据结构
      const schema = FormStore.toSchema(store)

      const { succeeded } = await fetcher(ApiPayload.API, { method: 'post', payload: schema })

      if (succeeded) {
        message.success('托管成功 ~')

        onSuccess()
        setHolderSetupVisible(false)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setHolderSetupLoading(false)
    }
  })

  // 上一步
  const handlePrevStep = useCb(() => setHolderSetupStep(HolderSetupStep.ONE))

  // 下一步（校验未通过则阻止进入下一步）
  const handleNextStep = useCb(async () => {
    try {
      await holderSetupForm.validateFields()
    } catch (error) {
      return console.error(error)
    }

    setHolderSetupStep(HolderSetupStep.TWO)
  })

  // 打开抽屉
  const handleOpen = useCb(() => setHolderSetupVisible(true))

  // 关闭抽屉
  const handleClose = useCb(() => setHolderSetupVisible(false))

  // 关闭后重置
  const handleAfterClose = useCb(() => {
    setHolderSetupStep(HolderSetupStep.ONE)
    holderSetupForm.resetFields()
  })

  return useMemo(() => {
    return {
      holderSetupForm,
      holderSetupStep,
      holderSetupLoading,
      holderSetupVisible,

      handleOpen,
      handleClose,
      handleSubmit,
      handlePrevStep,
      handleNextStep,
      handleAfterClose,
    }
  }, [
    holderSetupForm,
    holderSetupStep,
    holderSetupLoading,
    holderSetupVisible,

    handleOpen,
    handleClose,
    handleSubmit,
    handlePrevStep,
    handleNextStep,
    handleAfterClose,
  ])
}

export const HolderSetupSchemaService = createService(useHolderSetupSchemaService)
