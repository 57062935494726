import { Space } from 'antd'
import { useMemo } from 'react'
import { AInputNumber } from '@/components'

export interface CostRangeValueProp {
  min: number // 历史消耗最小值
  max: number // 历史消耗最大值
}

export interface CostRangeProps {
  value?: CostRangeValueProp
  onChange?: (value: CostRangeValueProp) => void
}

const defaultValue: CostRangeValueProp = { min: 0, max: 0 }

/**
 * @description 历史消耗范围
 */
export function FormCostRange({ value = defaultValue, onChange }: CostRangeProps) {
  return useMemo(() => {
    return (
      <Space>
        <AInputNumber
          min={0}
          style={{ width: '140px' }}
          value={value.min}
          onChange={(min) => onChange?.({ ...value, min })}
        />

        <span>~</span>

        <AInputNumber
          min={0}
          style={{ width: '140px' }}
          value={value.max}
          onChange={(max) => onChange?.({ ...value, max })}
        />

        <span style={{ color: 'var(--ai-color-label)' }}>元</span>
      </Space>
    )
  }, [onChange, value])
}
