import styled from 'styled-components'
import { HolderOperType } from '@/app/Holder'
import { AHoverBar, AIcon } from '@/components'
import { PropsWithChildren } from 'react'
import { Row, Space, Typography } from 'antd'

interface HolderHoverBarProps {
  size: number
  onClose: () => void
  onAction?: (action: HolderOperType) => void
}

const TypographyLinkWrapper = styled(Typography.Link)`
  color: var(--ai-base-color) !important;

  &:hover {
    opacity: 0.75;
  }

  &:active {
    opacity: 0.5;
  }
`

export function HolderHoverBar({ size, onClose, onAction, children }: PropsWithChildren<HolderHoverBarProps>) {
  return (
    <AHoverBar
      visible={Boolean(size)}
      message={`已选择 ${size} 条数据`}
      onClose={onClose}
      actions={[
        <Space key={'switch'}>
          <TypographyLinkWrapper onClick={() => onAction?.(HolderOperType.ENABLE)}>
            <Space>
              <AIcon type={'icon-play'} />
              <span>启用</span>
            </Space>
          </TypographyLinkWrapper>
          <TypographyLinkWrapper onClick={() => onAction?.(HolderOperType.PAUSE)}>
            <Space>
              <AIcon type={'icon-pause'} />
              <span>暂停</span>
            </Space>
          </TypographyLinkWrapper>
        </Space>,
        <TypographyLinkWrapper key={'delete'} onClick={() => onAction?.(HolderOperType.DELETE)}>
          <Space>
            <AIcon type={'icon-delete'} />
            <span>删除</span>
          </Space>
        </TypographyLinkWrapper>,
      ]}
    >
      <Row className={'w-100'} align={'middle'} justify={'space-between'}>
        {children}
      </Row>
    </AHoverBar>
  )
}
