import { useMemo } from 'react'
import { ACalcInput } from '@/components'
import { recalculateStrLength, mergeClassNames } from '@/utils'
import {
  // services
  strategyConfigs,
  StrategySchemaService,

  // components
  StrategyNameWrapper,
} from '@/app/Modules'

const [minLength, maxLength] = strategyConfigs.strategyNameLengthRange

/**
 * @description 策略模型 - 额外内容
 */
export function StrategySchemaExtra() {
  const { strategySchema, setStrategySchema } = StrategySchemaService.useInject()

  return useMemo(() => {
    const length = recalculateStrLength(strategySchema.strategyName)

    const isLessThan = length <= minLength
    const isGreaterThan = length > maxLength

    const messageClassNames = mergeClassNames('message', { visible: isLessThan || isGreaterThan })

    return (
      <StrategyNameWrapper size={16}>
        <span className={'label'}>策略名称</span>

        <ACalcInput
          allowClear
          style={{ width: '540px' }}
          range={[minLength, maxLength]}
          value={strategySchema.strategyName}
          bordered={false}
          className={'input'}
          placeholder={'请输入策略名称'}
          onChange={(e) => setStrategySchema({ strategyName: e.target.value })}
        />

        <span className={messageClassNames}>
          {isLessThan && '请输入策略名称'}
          {isGreaterThan && `策略名称不能超过 ${maxLength} 个字`}
        </span>
      </StrategyNameWrapper>
    )
  }, [setStrategySchema, strategySchema.strategyName])
}
