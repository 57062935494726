export const url = '//at.alicdn.com/t/font_3295394_7crz207o9pm.js'

/**
 * @description 图标不显示请检查图标的命名或底色
 * @description 图标类型（备注用途） - 类型缺失 build 项目时会导致 tsc 校验失败，从而避免出现他人图标被意外改掉的情况
 */
export type AIconType =
  /* Layout Header */
  | 'icon-back-home' // 返回首页

  /* 托管广告 Tabs */
  | 'icon-account' // 投放账户
  | 'icon-ad-group' // 广告组
  | 'icon-ad' // 广告

  /* 托管策略 Tabs */
  | 'icon-strategy-all' // 全部策略
  | 'icon-strategy-executed' // 已执行策略
  | 'icon-strategy-implemented' // 待执行策略

  /* 通用图标 */
  | 'icon-add' // 添加
  | 'icon-add-bordered' // 添加 - 有边框的
  | 'icon-dot' // 圆点
  | 'icon-log' // 日志
  | 'icon-up' // 上一个
  | 'icon-down' // 下一个
  | 'icon-edit' // 编辑
  | 'icon-copy' // 复制
  | 'icon-play' // 启用/播放
  | 'icon-pause' // 禁用/暂停
  | 'icon-check' // √
  | 'icon-filter' // 过滤
  | 'icon-remove' // 移除/清空
  | 'icon-delete' // 删除
  | 'icon-search' // 搜索
  | 'icon-question' // 问号
