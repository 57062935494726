/**
 * @description 从数组中移除项
 * @param ary 数组
 * @param predicate 数组执行 findIndex 时所执行的回调
 */
export const removeItem = <T>(ary: T[], predicate: (item: T) => boolean) => {
  const i = ary.findIndex(predicate)

  if (i > -1) {
    ary.splice(i, 1)
  }
}

/**
 * @description 拆分数据列表
 * @param {*} list 数据列表
 * @param {*} splitLength 拆分为几个一组
 * @returns
 */
export const splitList = <T>(list: T[], splitLength: number) => {
  const groupList = []
  for (let i = 0; i < list.length; i += splitLength) {
    groupList.push(list.slice(i, i + splitLength))
  }
  return groupList
}

/**
 * @description 对象数组去重
 * @param {*} originAry 原始对象数组
 * @param {*} targetAry 目标对象数组
 * @param {*} field 比对字段
 * @returns 去重后的对象数组
 */
export const oaDeDuplication = <T>(originAry: T[], targetAry: T[], field: keyof T): T[] => {
  const map = new Map()

  // 合并
  const merged = [...originAry, ...targetAry]

  // 去重
  merged.forEach((item) => {
    // 只保留第一次出现的数据
    if (!map.has(item[field])) {
      map.set(item[field], item)
    }
  })

  return [...map.values()]
}
