import { hexToRgba } from '@/utils'
import { PropsWithChildren } from 'react'

import { ATagWrapper } from './styled'

interface ATagProps {
  color?: string
}

// bgc = color * 15%
export function ATag(props: PropsWithChildren<ATagProps>) {
  const rgba = hexToRgba(props.color ?? '#FFFFFF', '15%')

  return (
    <ATagWrapper color={rgba}>
      <span className="content" style={{ color: props.color }}>
        {props.children}
      </span>
    </ATagWrapper>
  )
}
