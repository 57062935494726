// 手机号码
export const REG_EXP_PHONE = /^[1]([3-9])[0-9]{9}$/

// 邮箱
export const REG_EXP_EMAIL = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
export const REG_EXP_EMAIL_1 = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/

// url
export const REG_EXP_URL = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-.,@?^=%&amp;:/~+#]*[\w\-@?^=%&amp;/~+#])?/

// https
export const REG_EXP_HTTPS = /^https?:\/\/(([a-zA-Z0-9_-])+(\.)?)*(:\d+)?(\/((\.)?(\?)?=?&?[a-zAZ0-9_-](\?)?)*)*$/i

// 英文字符
export const REG_EXP_EN_CHAR = /[a-z0-9,_·`;.!@#\-+/\\$%^*()<>?:"'{}~\s]/gi
