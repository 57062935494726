import { Form } from 'antd'
import { ACalcInputValidator, ACalcInput } from '@/components'

import { aiRuleConfigs } from '../ai-rule.shared'

export function FormRuleName() {
  return (
    <Form.Item
      name={'ruleName'}
      label={'规则名称'}
      rules={[
        {
          validator: (_, value) => {
            const [min, max] = aiRuleConfigs.ruleNameLengthRange

            return ACalcInputValidator(value, [
              { min, message: '请输入规则名称' },
              { max, message: `规则名称不能超出 ${max} 个字` },
            ])
          },
        },
      ]}
    >
      <ACalcInput
        allowClear
        range={aiRuleConfigs.ruleNameLengthRange}
        placeholder={'请输入规则名称'}
        autoComplete={'off'}
      />
    </Form.Item>
  )
}
