import styled from 'styled-components'

export const ATimePeriodPickerWrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;

  table {
    width: 100%;
  }

  th,
  td {
    width: 20px;
    color: #727272;
    height: 28px;
    cursor: default;
    font-size: 12px;
    text-align: center;
    user-select: none;
    border-color: #e5e8ec;
  }

  tr:first-child th {
    width: 80px;
  }

  .sheetItem {
    cursor: pointer;
  }

  .sheetItem:hover {
    background: #e7f1ff;
  }

  .selected {
    background: #72a5ff !important;
  }

  .actioning {
    background: #a6ceff !important;
  }

  .sheetResult {
    position: relative;
    border: 1px solid #e5e8ec;
    border-top: 0;
  }

  .resultTitle {
    color: #727272;
    padding: 8px 20px;
  }

  .resultItem {
    display: flex;
    padding: 8px 20px;
    color: #727272;
  }

  .resultItem span:first-child {
    width: 80px;
  }

  .resultItem span:last-child {
    width: calc(100% - 80px);
  }
`
