import { AIcon } from '@/components'
import { Row, Col } from 'antd'
import { mergeClassNames } from '@/utils'
import { OPERATE_OPTIONS } from '@/constants'
import { Fragment, useMemo } from 'react'

import {
  // types
  type ListItemProps,
  type AiRuleItemProps,

  // services
  toYuan,
  AiRuleSchemaService,
  AiRuleIndexService,

  // components
  ListItem,
  AiRuleListSelectedItemExtra,
  AiRuleListSelectedItemWrapper,
} from '@/app/Modules/AiRule'

/**
 * @description Ai 规则列表项
 */
export function AiRuleListSelectedItem({ item, value, onChange }: AiRuleItemProps) {
  const { aiRuleIndex } = AiRuleIndexService.useInject()
  const { handleCreateBefore } = AiRuleSchemaService.useInject()

  // 创建元配置
  const createMeta = useMemo((): Omit<ListItemProps, 'item' | 'ruleIndexEnums'> => {
    switch (item.type) {
      // 关闭类型
      case 2: {
        const operate = OPERATE_OPTIONS.find((eachItem) => eachItem.value === Number(item.execution))

        return {
          renderContentFooter: (
            <Row>
              <Col span={8}>执行：</Col>
              <Col span={16}>{operate?.label ?? '-'}</Col>
            </Row>
          ),
        }
      }

      // 预算类型
      case 3: {
        let renderBudget
        const { budgetMin, budgetMax } = item

        // 介于
        if (budgetMin != null && budgetMax != null) {
          renderBudget = `${budgetMin / 100} - ${budgetMax / 100} 元`
        }
        // 最小值 - 大于
        else if (budgetMin != null) {
          renderBudget = `大于 ${budgetMin / 100} 元`
        }
        // 最大值 - 小于
        else if (budgetMax != null) {
          renderBudget = `小于 ${budgetMax / 100} 元`
        }
        // 都是 null
        else {
          renderBudget = '-'
        }

        return {
          renderContentHeader: (
            <Row>
              <Col span={8}>日预算：</Col>
              <Col span={16}>{renderBudget}</Col>
            </Row>
          ),
          renderContentFooter: (
            <Row>
              <Col span={8}>执行：</Col>
              预算提升 {item.executionType === 1 ? toYuan(Number(item.execution)) : item.execution}
              {item.executionType === 1 ? '元' : '%'}
            </Row>
          ),
        }
      }
      default:
        return {}
    }
  }, [item])

  return useMemo(() => {
    // [自定义规则卡片] - 没有 id | 自定义添加的规则的 id 是负数
    const isCustom = item.id == null
    const customClassNames = mergeClassNames({ custom: isCustom })

    return (
      <AiRuleListSelectedItemWrapper
        className={customClassNames}
        onClick={() => isCustom && handleCreateBefore(value.length)}
      >
        {isCustom ? (
          <div className={'custom-item'}>
            <AIcon type={'icon-add'} className={'custom-item-icon'} />
            <span className={'custom-item-text'}>自定义规则</span>
          </div>
        ) : (
          <Fragment>
            <ListItem
              item={item}
              ruleIndexEnums={[...aiRuleIndex.switchEnum, ...aiRuleIndex.budgetEnum]}
              renderItemExtra={<AiRuleListSelectedItemExtra item={item} />}
              renderContentHeader={createMeta.renderContentHeader}
              renderContentFooter={createMeta.renderContentFooter}
            />
            <AIcon
              type={'icon-remove'}
              title={'移除'}
              className={'icon-remove'}
              onClick={() => onChange?.(value.filter((eachItem) => eachItem.id !== item.id))}
            />
          </Fragment>
        )}
      </AiRuleListSelectedItemWrapper>
    )
  }, [
    aiRuleIndex.budgetEnum,
    aiRuleIndex.switchEnum,
    createMeta.renderContentFooter,
    createMeta.renderContentHeader,
    handleCreateBefore,
    item,
    onChange,
    value,
  ])
}
