import { Tabs } from 'antd'
import { useMemo } from 'react'

import {
  // services
  AiRuleSchemaTabKey,
  AiRuleSchemaService,
  AiRuleSchemaSubmitMode,

  // components
  AiRuleSchemaBodySwitch,
  AiRuleSchemaBodyBudget,
  AiRuleSchemaBodyWrapper,
} from '@/app/Modules/AiRule'

/**
 * @description Ai 规则模型 - 主体内容
 */
export function AiRuleSchemaBody() {
  const { aiRuleSchemaState, setAiRuleSchemaState } = AiRuleSchemaService.useInject()

  // 编辑状态下禁止切换 Tab 页
  const disabled = useMemo(
    () => [AiRuleSchemaSubmitMode.UPDATE, AiRuleSchemaSubmitMode.UPDATE_LOCAL].includes(aiRuleSchemaState.submitMode),
    [aiRuleSchemaState.submitMode],
  )

  return useMemo(() => {
    return (
      <AiRuleSchemaBodyWrapper>
        <Tabs
          activeKey={aiRuleSchemaState.activeKey}
          onChange={(value) => setAiRuleSchemaState({ activeKey: value as AiRuleSchemaTabKey })}
        >
          <Tabs.TabPane forceRender tab={'提升预算规则'} key={AiRuleSchemaTabKey.BUDGET} disabled={disabled}>
            <AiRuleSchemaBodyBudget />
          </Tabs.TabPane>
          <Tabs.TabPane forceRender tab={'关闭广告规则'} key={AiRuleSchemaTabKey.SWITCH} disabled={disabled}>
            <AiRuleSchemaBodySwitch />
          </Tabs.TabPane>
        </Tabs>
      </AiRuleSchemaBodyWrapper>
    )
  }, [aiRuleSchemaState.activeKey, disabled, setAiRuleSchemaState])
}
