import styled from 'styled-components'
import { ATextEllipsis } from '../AStyled'

export const AResultListWrapper = styled.div`
  .arl-header {
    &-title {
      color: var(--ai-color-title);
      font-size: var(--ai-font-size-normal);
      font-weight: var(--ai-font-weight-bold);
    }

    &-clear {
      font-weight: var(--ai-font-weight-bold);
    }
  }

  .arl-content {
    margin-top: 8px;

    .ant-list {
      height: 100px;
      overflow-y: auto;
      overflow-y: overlay;
      padding-right: 16px;
    }

    &-empty {
      margin: 0;
    }

    &-item {
      height: 30px;
      margin: 8px 0 !important;
      border: 1px solid var(--ai-color-border) !important;
      padding: 0 16px;
      position: relative;
      text-align: center;
      line-height: 30px;
      border-radius: var(--ai-base-border-radius);
      background-color: #f2f2f2;

      &:hover {
        .arl-content-item-remove {
          display: block;
        }
      }

      &-text {
        ${ATextEllipsis}
        width: 100%;
        font-size: var(--ai-font-size-small);
      }

      &-remove {
        top: -5px;
        right: -5px;
        color: var(--ai-color-icon);
        cursor: pointer;
        display: none;
        opacity: 0.75;
        position: absolute;
        transition: all 0.3s ease-in-out;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
`
